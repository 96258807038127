<script setup>
import Panel from "@/Components/Panel.vue"
import { Link } from '@inertiajs/vue3'
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import ArrowNext from "@/Components/SVG/ArrowNext.vue";

defineProps(['projects']);
const emit = defineEmits(['updateProject']);

const selected_pr_id = localStorage.getItem('pr_id');
const selected_pr_name = localStorage.getItem('pr_name');
</script>
<template>
    <div class="flex flex-wrap w-full px-[14px] mt-[32px]">

        <Heading_H1 class="mb-[32px]">
            Which Project would you like to add this trap to?
        </Heading_H1>
        <tamplate  v-if="selected_pr_name !== ''" class="w-full">
            <div @click='emit("updateProject", selected_pr_id)' class="mb-[16px] h-[48px] w-full border-b-[4px] border-t-[1px] border-r-[1px] border-l-[1px] border-solid  border-t_green-800 dark:border-t_green-700 overflow-hidden items-center dark:bg-t_black-800  bg-white px-[16px] py-[16px]  flex justify-between rounded-[4px]">
                <div class="w-9/12 ml-[5px]">
                    <p class="font-fira font-medium dark:text-t_white-200 tracking-[.02em]">
                        {{ selected_pr_name }}
                    </p>
                </div>
                <div class="py-3">
                    <arrow-next/>
                </div>
            </div>
            <p class="montserrat font-semibold text-[12px] tracking-[0.105em] leading-[120%] uppercase text-t_green-800 dark:text-t_green-700 mb-[38px] ml-[8px]">Suggested</p>
        </tamplate>





        <panel v-for="(pr, index) in projects" v-show="pr.id != selected_pr_id" :key="index" @click='emit("updateProject", pr.id)' :text="pr?.name ?? ''" />
        <Link :href="route('user.projects.index')" class="w-full">
            <panel @click='emit("update-type", 0)' text="Select a Project to join">
                <template #svg>
                    <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.59814 2.00148C1.59814 2.00148 10.0555 1.27778 12.9037 4.46545C13.4117 5.03404 13.7385 5.63499 13.9326 6.23867C11.6994 4.98524 7.98848 3.85656 2.28991 3.69303C3.31067 4.40866 4.58144 5.16857 5.88019 5.9452C9.3949 8.04696 13.1144 10.2712 12.6377 12.0717C11.7564 12.7391 8.85121 14.6115 6.44338 12.4072C3.56788 9.77492 1.59814 2.00148 1.59814 2.00148Z"
                            fill="#00BF8C" />
                        <path
                            d="M18.8302 11.5096C18.8302 11.5096 18.1676 8.26027 20.4537 6.53677C22.7398 4.81326 26.741 6.53611 26.741 6.53611C26.741 6.53611 25.5978 10.5571 23.3115 11.7059C21.0251 12.8546 18.8302 11.5096 18.8302 11.5096Z"
                            fill="#00BF8C" />
                        <path
                            d="M9.59814 8.33039C15.2942 12.4654 15.6796 16.3379 15.1297 23.6891M18.741 12.1701C16.7818 13.1546 15.3124 15.6159 15.3124 18.5695M12.9037 4.46545C10.0555 1.27778 1.59814 2.00148 1.59814 2.00148C1.59814 2.00148 3.56788 9.77492 6.44338 12.4072C8.85121 14.6115 11.7564 12.7391 12.6377 12.0717C13.1144 10.2712 9.3949 8.04696 5.88019 5.9452C4.58144 5.16857 3.31067 4.40866 2.28991 3.69303C7.98848 3.85656 11.6994 4.98524 13.9326 6.23867C13.7385 5.63499 13.4117 5.03404 12.9037 4.46545ZM20.4537 6.53677C18.1676 8.26027 18.8302 11.5096 18.8302 11.5096C18.8302 11.5096 21.0251 12.8546 23.3115 11.7059C25.5978 10.5571 26.741 6.53611 26.741 6.53611C26.741 6.53611 22.7398 4.81326 20.4537 6.53677Z"
                            stroke="#00BF8C" stroke-width="2.82051" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </template>
            </panel>
        </Link>
        <panel @click='emit("updateProject", 30)' text="None for now">
            <template #svg>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.9673 2.43188L2.03271 18.3665M2.03271 2.43188L17.9673 18.3665" stroke="#D44944"
                    stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </template>
    </panel>
</div>
</template>
