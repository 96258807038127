<template>
    <svg width="136" height="35" viewBox="0 0 136 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.96156 31.6376C4.78159 32.2555 4.56562 33.2754 4.56562 33.2754H4.52963C4.52963 33.2754 4.34965 32.2555 4.16968 31.6376L3.46178 29.34H1.56006V29.7239L2.14197 29.8499V33.9233L1.56006 34.0553V34.4392H3.25781V34.0553L2.75388 33.9233V31.4876C2.75388 30.8217 2.71189 29.7839 2.71189 29.7839H2.74789C2.74789 29.7839 2.98185 30.7738 3.17382 31.3917L4.09769 34.2532H4.7276L5.62147 31.3797C5.80744 30.7618 6.11939 29.7779 6.11939 29.7779H6.15539C6.15539 29.7779 6.1134 30.8157 6.1134 31.4757V33.9233L5.61547 34.0553V34.4392H7.70316V34.0553L7.07926 33.9233V29.8499L7.70316 29.7239V29.34H5.68746L4.96156 31.6376Z" fill="#60859F"/>
    <path d="M9.91679 30.5338C8.89094 30.5338 8.12305 31.2657 8.12305 32.4895C8.12305 33.7254 8.71696 34.5172 10.0248 34.5172C10.6847 34.5172 11.0866 34.3253 11.3446 34.1693V33.7673C11.0686 33.8573 10.7627 33.9593 10.2467 33.9593C9.53284 33.9593 9.1009 33.5574 9.07691 32.5675H11.5246C11.5306 31.2117 11.0446 30.5338 9.91679 30.5338ZM9.06491 32.1416C9.1129 31.3377 9.41286 31.0077 9.88079 31.0077C10.3247 31.0077 10.5707 31.2897 10.5707 32.1416H9.06491Z" fill="#60859F"/>
    <path d="M18.2439 29.8499L18.8198 29.7179V29.34H16.6841V29.7239L17.248 29.8499V31.5956H15.3103V29.8559L15.8743 29.7239V29.34H13.7266V29.7239L14.3145 29.8559V33.9233L13.7266 34.0553V34.4392H15.8743V34.0553L15.3103 33.9233V32.0636L17.248 32.0576V33.9233L16.6841 34.0553V34.4392L18.8318 34.4332V34.0553L18.2439 33.9173V29.8499Z" fill="#60859F"/>
    <path d="M21.0456 30.5338C20.0197 30.5338 19.2578 31.2597 19.2578 32.4895C19.2578 33.7194 19.8517 34.5172 21.1595 34.5172C21.8134 34.5172 22.2274 34.3193 22.4733 34.1633V33.7613C22.2034 33.8513 21.8974 33.9593 21.3815 33.9593C20.6676 33.9593 20.2357 33.5574 20.2117 32.5675H22.6593C22.6533 31.2117 22.1734 30.5338 21.0456 30.5338ZM20.1937 32.1356C20.2417 31.3377 20.5356 31.0017 21.0096 31.0017C21.4535 31.0017 21.7055 31.2837 21.7055 32.1356H20.1937Z" fill="#60859F"/>
    <path d="M25.4608 32.2555L26.3547 31.0616L26.8466 30.9477V30.6057H25.2749V30.9477L25.7608 31.0556L24.9029 32.2975L24.507 32.3335V28.836L24.411 28.788L23.0972 28.9619V29.3159L23.6011 29.4359V33.9892L23.1032 34.1032V34.4331H24.9809V34.1032L24.507 33.9952V32.6934L24.7949 32.7594L25.8988 34.4331H26.9846V34.1032L26.6367 33.9832L25.4608 32.2555Z" fill="#60859F"/>
    <path d="M28.964 30.5338C27.9382 30.5338 27.1763 31.2597 27.1763 32.4895C27.1763 33.7194 27.7702 34.5172 29.078 34.5172C29.7319 34.5172 30.1398 34.3193 30.3918 34.1633V33.7613C30.1158 33.8513 29.8099 33.9593 29.294 33.9593C28.5861 33.9593 28.1481 33.5574 28.1181 32.5675H30.5718C30.5718 31.2117 30.0858 30.5338 28.964 30.5338ZM28.1061 32.1356C28.1601 31.3377 28.4541 31.0017 28.928 31.0017C29.3659 31.0017 29.6179 31.2837 29.6179 32.1356H28.1061Z" fill="#60859F"/>
    <path d="M35.6234 31.4157L36.9373 29.8499L37.4052 29.7179V29.34H35.6654V29.7179L36.2414 29.8499L34.3816 32.1596V29.8379L34.9455 29.7179V29.34H32.7979V29.7179L33.3798 29.8499V33.9173L32.7979 34.0553V34.4332H34.9455V34.0553L34.3816 33.9233V32.6455L34.9455 32.1656L36.2773 34.4332H37.5552V34.0493L37.1112 33.9173L35.6234 31.4157Z" fill="#60859F"/>
    <path d="M39.247 29.46C39.247 29.172 39.025 28.9561 38.7251 28.9561C38.4251 28.9561 38.2031 29.172 38.2031 29.46C38.2031 29.7479 38.4251 29.9699 38.7251 29.9699C39.025 29.9759 39.247 29.7479 39.247 29.46Z" fill="#60859F"/>
    <path d="M39.2228 30.5878L39.1328 30.5398L37.771 30.7138V31.0737L38.3169 31.1997V33.9833L37.825 34.0973V34.4332H39.7087V34.0973L39.2228 33.9833V30.5878Z" fill="#60859F"/>
    <path d="M43.8657 29.34H41.832V29.7239L42.4139 29.8499V33.9233L41.832 34.0493V34.4332H44.1117V34.0493L43.4158 33.9233V32.2855C43.5838 32.3215 43.7518 32.3515 44.0997 32.3515C45.1975 32.3515 45.8215 31.8716 45.8215 30.8218C45.8155 29.8859 45.2935 29.34 43.8657 29.34ZM43.9557 31.8956C43.6978 31.8956 43.5718 31.8776 43.4158 31.8536V29.8679H43.9317C44.5256 29.8679 44.8256 30.1438 44.8256 30.8517C44.8256 31.5776 44.5556 31.8956 43.9557 31.8956Z" fill="#60859F"/>
    <path d="M49.0251 29.304H46.8774V29.8319H49.0251V29.304Z" fill="#60859F"/>
    <path d="M47.9395 30.5338C46.9137 30.5338 46.0918 31.3017 46.0918 32.5495C46.0918 33.7493 46.8117 34.5352 47.9635 34.5352C48.9834 34.5352 49.8113 33.7553 49.8113 32.5135C49.8113 31.2777 49.0974 30.5338 47.9395 30.5338ZM47.9695 34.0313C47.4836 34.0313 47.0457 33.6714 47.0457 32.4835C47.0457 31.3317 47.4536 31.0077 47.9335 31.0077C48.4075 31.0077 48.8454 31.3677 48.8454 32.5555C48.8514 33.7014 48.4315 34.0313 47.9695 34.0313Z" fill="#60859F"/>
    <path d="M53.9744 31.5237C53.9744 30.8278 53.5905 30.5338 52.9486 30.5338C52.4686 30.5338 52.0547 30.7558 51.6887 30.9897H51.6467V30.5878L51.5568 30.5398L50.2729 30.7138V31.0737L50.7769 31.1937V33.9773L50.2849 34.0913V34.4272H52.1327V34.0913L51.6887 33.9893V31.3317C51.9167 31.2597 52.1507 31.1937 52.4086 31.1937C52.8406 31.1937 53.0625 31.3437 53.0625 31.8056V33.9893L52.6126 34.0913V34.4272H54.4603V34.0913L53.9804 33.9773V31.5237H53.9744Z" fill="#60859F"/>
    <path d="M56.6682 30.5278C55.6424 30.5278 54.8745 31.2597 54.8745 32.4895C54.8745 33.7194 55.4684 34.5173 56.7822 34.5173C57.4361 34.5173 57.8441 34.3193 58.096 34.1633V33.7554C57.8261 33.8394 57.5201 33.9473 57.0042 33.9473C56.2903 33.9473 55.8584 33.5514 55.8344 32.5555H58.282C58.276 31.2057 57.7961 30.5278 56.6682 30.5278ZM55.8164 32.1356C55.8644 31.3377 56.1643 31.0018 56.6323 31.0018C57.0762 31.0018 57.3222 31.2897 57.3222 32.1356H55.8164Z" fill="#60859F"/>
    <path d="M61.0834 32.2555L61.9773 31.0616L62.4692 30.9477V30.5997H60.8914V30.9477L61.3833 31.0556L60.5195 32.2855L60.1235 32.3335V28.83L60.0275 28.788L58.7197 28.9559V29.3159L59.2177 29.4359V33.9832L58.7257 34.0972V34.4271H60.5975V34.0972L60.1235 33.9952V32.6874L60.4175 32.7594L61.5213 34.4271H62.6012V34.0972L62.2592 33.9772L61.0834 32.2555Z" fill="#60859F"/>
    <path d="M64.5812 30.5278C63.5553 30.5278 62.7935 31.2597 62.7935 32.4835C62.7935 33.7194 63.3874 34.5113 64.6952 34.5113C65.3491 34.5113 65.757 34.3193 66.009 34.1633V33.7554C65.739 33.8454 65.4331 33.9473 64.9171 33.9473C64.2032 33.9473 63.7713 33.5514 63.7413 32.5555H66.195C66.195 31.2057 65.709 30.5278 64.5812 30.5278ZM63.7293 32.1296C63.7773 31.3317 64.0773 31.0018 64.5512 31.0018C64.9891 31.0018 65.2411 31.2837 65.2411 32.1296H63.7293Z" fill="#60859F"/>
    <path d="M13.0063 13.1724H9.82675V14.3722L10.6186 14.5822L9.71276 18.7156H9.65277L8.21898 13.1724H6.43724L5.00345 18.8895H4.93746L4.00159 14.5822L4.72749 14.3722V13.1724H0.810059V14.3722L1.42797 14.5822L3.22171 21.7511H5.63336L6.90517 16.6519H6.95916L8.18898 21.7511H10.6066L12.3644 14.5822L13.0063 14.3722V13.1724Z" fill="#60859F"/>
    <path d="M16.0119 15.0081C14.0082 15.0081 12.6704 16.2679 12.6704 18.4336C12.6704 20.6652 13.8822 21.9131 16.1919 21.9131C17.4517 21.9131 18.2316 21.5771 18.6995 21.2591V20.0653C18.2316 20.2153 17.6737 20.3713 16.8098 20.3713C15.76 20.3713 15.1421 19.9633 15.0161 18.7995H19.0115C19.0175 16.0219 18.0516 15.0081 16.0119 15.0081ZM15.0221 17.7437C15.1061 16.6398 15.478 16.3279 15.9879 16.3279C16.4679 16.3279 16.8158 16.6398 16.8158 17.6657L15.0221 17.7437Z" fill="#60859F"/>
    <path d="M19.6055 13.9102L20.3974 14.1142V20.4493L19.6715 20.6232V21.7511H23.3669V20.6232L22.665 20.4493V12.5784L22.4671 12.4824L19.6055 12.7644V13.9102Z" fill="#60859F"/>
    <path d="M23.9492 20.6232V21.7511H27.6506V20.6232L26.9427 20.4493V12.5784L26.7447 12.4824L23.8892 12.7644V13.9102L24.681 14.1142V20.4493L23.9492 20.6232Z" fill="#60859F"/>
    <path d="M31.2081 15.11L30.9981 15.02L28.1665 15.302V16.4538L28.9344 16.6518V20.4492L28.2265 20.6232V21.751H31.874V20.6292L31.2081 20.4492V15.11Z" fill="#60859F"/>
    <path d="M28.7603 13.4963C28.7603 14.1922 29.3302 14.6061 30.0081 14.6061C30.704 14.6061 31.2559 14.1862 31.2559 13.4963C31.2559 12.8124 30.698 12.3925 30.0081 12.3925C29.3302 12.3925 28.7603 12.8184 28.7603 13.4963Z" fill="#60859F"/>
    <path d="M39.3064 16.6398C39.3064 15.464 38.6045 15.0081 37.5907 15.0081C36.6788 15.0081 36.0429 15.332 35.413 15.8119H35.335V15.11L35.143 15.0201L32.4194 15.308V16.4598L33.1633 16.6578V20.4553L32.4554 20.6352V21.7571H35.9589V20.6352L35.443 20.4973V16.8858C35.695 16.8018 35.9469 16.7298 36.2709 16.7298C36.7928 16.7298 37.0388 16.9038 37.0388 17.4317V20.4853L36.5228 20.6232V21.7451H39.9723V20.6232L39.3184 20.4433V16.6398H39.3064Z" fill="#60859F"/>
    <path d="M54.9106 15.0081C53.0028 15.0081 51.4971 16.2199 51.4971 18.4995C51.4971 20.5812 52.7089 21.9131 54.9226 21.9131C56.8183 21.9131 58.3361 20.6712 58.3361 18.4096C58.3361 16.2439 57.1123 15.0081 54.9106 15.0081ZM54.9346 20.5513C54.3047 20.5513 53.8787 20.1193 53.8787 18.4156C53.8787 16.7478 54.2867 16.3579 54.8926 16.3579C55.5105 16.3579 55.9304 16.7898 55.9304 18.4995C55.9364 20.1193 55.5405 20.5513 54.9346 20.5513Z" fill="#60859F"/>
    <path d="M51.2689 21.5231V20.3473C51.101 20.3953 50.873 20.4373 50.663 20.4373C50.1171 20.4373 49.8591 20.2693 49.8591 19.6814V16.4898H51.1969V15.14H49.8591V13.5983H48.0834L47.7954 15.134L47.0815 15.398V16.4958H47.6035V20.0653C47.6035 21.4571 48.3414 21.8891 49.6792 21.8951C50.3931 21.8891 50.975 21.7151 51.2689 21.5231Z" fill="#60859F"/>
    <path d="M46.9918 21.7511C46.9918 20.4373 46.0859 19.9454 44.9521 19.9454H42.8104C42.4624 19.9454 42.3544 19.7714 42.3544 19.6094C42.3544 19.4955 42.4024 19.3515 42.4984 19.2375C42.7864 19.2975 43.0803 19.3215 43.4163 19.3215C45.174 19.3215 46.3259 18.5356 46.3259 17.0958C46.3259 16.7479 46.2119 16.4779 46.0379 16.2199L46.6918 16.2499V14.9481L44.8081 15.2181C44.4301 15.0861 43.9562 15.0081 43.4103 15.0081C41.6945 15.0081 40.5067 15.8 40.5067 17.2338C40.5067 18.0017 40.9147 18.5656 41.5206 18.9015C40.9926 19.2495 40.5727 19.7714 40.5727 20.3053C40.5727 20.8393 40.8547 21.1992 41.4366 21.3672V21.4332C40.6927 21.6251 40.0508 22.0391 40.0508 22.771C40.0508 23.6469 41.0766 24.2828 43.1823 24.2828C45.8759 24.2768 46.9918 23.1249 46.9918 21.7511ZM43.4163 16.1239C43.8602 16.1239 44.2082 16.3219 44.2082 17.1858C44.2082 17.9657 43.8602 18.2236 43.4163 18.2236C42.9844 18.2236 42.6484 17.9657 42.6484 17.1498C42.6484 16.3699 42.9724 16.1239 43.4163 16.1239ZM42.1445 22.393C42.1445 22.0811 42.3185 21.8951 42.5764 21.7871H44.1362C44.7001 21.7871 44.9161 21.8951 44.9161 22.2551C44.9161 22.663 44.5081 23.0109 43.3563 23.0109C42.4624 23.0109 42.1445 22.753 42.1445 22.393Z" fill="#60859F"/>
    <path d="M65.8051 16.6458C65.8051 15.47 65.0972 15.014 64.0834 15.014C63.1655 15.014 62.5356 15.332 61.9117 15.8179H61.8337V15.116L61.6357 15.026L58.9121 15.308V16.4598L59.656 16.6578V20.4552L58.9481 20.6292V21.7571H62.4516V20.6292L61.9297 20.4912V16.8918C62.1876 16.8078 62.4396 16.7358 62.7576 16.7358C63.2795 16.7358 63.5254 16.9038 63.5254 17.4377V20.4912L63.0035 20.6292V21.7571H66.453V20.6292L65.7991 20.4552V16.6458H65.8051Z" fill="#60859F"/>
    <path d="M75.4333 20.5694C75.1273 20.7433 74.7074 20.8753 74.1734 20.8753C72.3977 20.8753 71.6838 19.7715 71.6838 17.3118C71.6838 15.2601 72.4277 14.0423 74.0715 14.0423C74.6054 14.0423 75.0733 14.1923 75.3613 14.3183L75.8352 15.6381H76.7471L76.6751 13.3704C76.1412 13.1844 75.2473 13.0225 74.1914 13.0225C72.0018 13.0225 69.8721 14.1563 69.8721 17.5518C69.8721 20.6713 71.5818 21.9012 73.9755 21.9012C75.2653 21.9012 76.1172 21.7092 76.7351 21.4392L76.8251 19.1476H75.8952L75.4333 20.5694Z" fill="#60859F"/>
    <path d="M78.145 13.5503C78.145 14.0302 78.553 14.3901 79.0449 14.3901C79.5548 14.3901 79.9628 14.0302 79.9628 13.5503C79.9628 13.0523 79.5548 12.6924 79.0449 12.6924C78.553 12.6924 78.145 13.0523 78.145 13.5503Z" fill="#60859F"/>
    <path d="M79.8907 15.2841L79.7287 15.1941L77.4131 15.488V16.2199L78.319 16.4179V20.8753L77.4851 21.0732V21.7511H80.7186V21.0732L79.8907 20.8753V15.2841Z" fill="#60859F"/>
    <path d="M83.3523 19.9453V16.2678H84.4322V15.3019H83.3523V13.7902H82.1585L81.9366 15.3019L80.9707 15.6139V16.2678H81.7986V20.2272C81.7986 21.463 82.4165 21.871 83.5923 21.871C84.1982 21.871 84.7201 21.685 85.0021 21.505V20.7731C84.7801 20.8211 84.5342 20.8571 84.2882 20.8571C83.5863 20.8631 83.3523 20.6052 83.3523 19.9453Z" fill="#60859F"/>
    <path d="M99.0518 20.5694C98.7458 20.7433 98.3259 20.8753 97.792 20.8753C96.0222 20.8753 95.3083 19.7715 95.3083 17.3118C95.3083 15.2541 96.0462 14.0423 97.696 14.0423C98.2299 14.0423 98.6978 14.1923 98.9858 14.3183L99.4537 15.6381H100.372L100.3 13.3704C99.7657 13.1844 98.8778 13.0225 97.816 13.0225C95.6263 13.0225 93.4966 14.1623 93.4966 17.5518C93.4966 20.6713 95.2063 21.8952 97.606 21.8952C98.8898 21.8952 99.7477 21.7092 100.366 21.4392L100.456 19.1476H99.5257L99.0518 20.5694Z" fill="#60859F"/>
    <path d="M104.271 15.182C102.513 15.182 101.104 16.4778 101.104 18.5715C101.104 20.5992 102.315 21.913 104.295 21.913C106.041 21.913 107.463 20.5992 107.463 18.4995C107.463 16.4478 106.227 15.182 104.271 15.182ZM104.307 20.9772C103.479 20.9772 102.735 20.3832 102.735 18.4875C102.735 16.6458 103.437 16.1119 104.259 16.1119C105.075 16.1119 105.819 16.6938 105.819 18.6015C105.819 20.4312 105.111 20.9772 104.307 20.9772Z" fill="#60859F"/>
    <path d="M113.996 15.2961L113.834 15.2061L111.62 15.428V16.1419L112.436 16.3459V20.4373C112.112 20.5753 111.764 20.7373 111.134 20.7373C110.288 20.7373 110.084 20.3773 110.084 19.6094V15.2901L109.922 15.2001L107.679 15.422V16.1359L108.537 16.3339V20.0234C108.537 21.1752 109.071 21.8771 110.378 21.8771C111.272 21.8771 111.95 21.5591 112.436 21.0852H112.508V21.7931L112.67 21.8711L114.836 21.5831V20.9052L113.996 20.7073V15.2961Z" fill="#60859F"/>
    <path d="M121.609 16.8558C121.609 15.6319 120.967 15.182 119.851 15.182C119.035 15.182 118.327 15.482 117.709 15.8899H117.637V15.284L117.475 15.194L115.286 15.488V16.2199L116.138 16.4178V20.8752L115.298 21.0731V21.7511H118.465V21.0731L117.709 20.8872V16.6038C118.099 16.4778 118.489 16.3698 118.933 16.3698C119.677 16.3698 120.049 16.5918 120.049 17.3357V20.8872L119.281 21.0731V21.7511H122.443V21.0731L121.615 20.8752V16.8558H121.609Z" fill="#60859F"/>
    <path d="M124.344 18.4275C124.344 16.8198 124.998 16.1119 126.042 16.1119C126.474 16.1119 126.624 16.1598 126.792 16.2138L127.008 17.2217H127.962V15.434C127.512 15.29 126.846 15.188 126.168 15.188C124.212 15.188 122.712 16.1778 122.712 18.5895C122.712 20.6172 123.816 21.907 125.934 21.907C126.972 21.907 127.656 21.6071 128.052 21.3251V20.5092C127.746 20.6652 127.176 20.8152 126.408 20.8152C125.208 20.8152 124.344 20.2333 124.344 18.4275Z" fill="#60859F"/>
    <path d="M130.091 14.3901C130.601 14.3901 131.009 14.0362 131.009 13.5503C131.009 13.0583 130.601 12.6924 130.091 12.6924C129.599 12.6924 129.191 13.0523 129.191 13.5503C129.191 14.0362 129.599 14.3901 130.091 14.3901Z" fill="#60859F"/>
    <path d="M130.938 15.2841L130.776 15.2001L128.46 15.494V16.2259L129.366 16.4239V20.8812L128.538 21.0792V21.7571L131.765 21.7631V21.0792L130.938 20.8812V15.2841Z" fill="#60859F"/>
    <path d="M88.7935 16.0099L89.5194 16.1839L88.2716 19.8614H88.2236L86.8078 16.1719L87.5637 16.0099V15.302H84.6421V16.0459L85.14 16.1779L87.4017 21.6011L87.1077 22.327C86.8378 22.9509 86.4718 23.1669 85.8359 23.1669C85.6079 23.1669 85.452 23.1309 85.182 23.0709V24.0128C85.404 24.1267 85.8599 24.2347 86.2319 24.2347C87.3417 24.2347 87.8636 23.6888 88.2716 22.603L90.7012 16.1779L91.3311 16.0039V15.296H88.7935V16.0099Z" fill="#60859F"/>
    <path d="M134.483 20.8812V12.6024L134.321 12.5125L131.993 12.7944V13.5263L132.923 13.7243V20.8812L132.113 21.0672V21.7631H135.323V21.0792L134.483 20.8812Z" fill="#60859F"/>
    <path d="M61.8035 4.50358L62.4394 4.3296V3.6217H59.8838V4.3296L60.6217 4.50358L59.3619 8.18104H59.3079L57.8921 4.49158L58.648 4.3296V3.6217H55.5645V4.3296L56.2064 4.50358L58.486 9.92079L58.1861 10.6527C57.9161 11.2646 57.5562 11.4926 56.9023 11.4926C56.6803 11.4926 56.5123 11.4506 56.2424 11.3906V12.3324C56.4643 12.4404 56.9263 12.5544 57.3042 12.5544C58.426 12.5544 58.948 12.0085 59.3559 10.9226L61.8035 4.50358Z" fill="#60859F"/>
    <path d="M3.74991 9.30282L2.79605 9.07486L3.37197 7.37711H6.05957L6.60549 9.07486L5.68163 9.30282V10.0647H9.26311V9.28483L8.46522 9.06886L5.82561 1.48596H4.29583L1.59022 9.06886L0.79834 9.27883V10.0587H3.73791V9.30282H3.74991ZM4.72177 3.36969H4.78176L5.80161 6.53723H3.65392L4.72177 3.36969Z" fill="#60859F"/>
    <path d="M10.1751 9.71088C10.733 9.96884 11.6329 10.2208 12.7307 10.2208C14.7704 10.2208 16.1562 9.08097 16.1562 6.59733C16.1562 4.14369 14.9024 3.48978 13.7026 3.48978C12.8207 3.48978 12.2748 3.80774 11.8129 4.15569H11.7409V0.904161L11.5789 0.826172L9.32324 1.10813V1.85202L10.1871 2.04999V9.71088H10.1751ZM11.7349 4.91158C11.9929 4.79759 12.4308 4.64762 12.9407 4.64762C13.9366 4.64762 14.5065 5.20553 14.5065 6.8373C14.5065 8.74502 13.8346 9.30294 12.8387 9.30294C12.2148 9.30294 11.9689 9.12896 11.7349 8.97899V4.91158Z" fill="#60859F"/>
    <path d="M19.4013 9.3269C18.9034 9.3269 18.5135 9.18892 18.3035 9.07493L18.0095 8.10907H17.0977L17.2056 9.85482C17.8056 10.0768 18.6034 10.2268 19.3113 10.2268C21.1771 10.2268 22.0409 9.3209 22.0409 8.12107C22.0409 7.19121 21.579 6.61529 20.3552 6.26734L19.6233 6.05737C18.8014 5.82341 18.5135 5.61344 18.5135 5.1395C18.5135 4.58159 18.9874 4.38362 19.5993 4.38362C20.0492 4.38362 20.3252 4.4676 20.5172 4.56959L20.7571 5.42146H21.627V3.7777C21.1531 3.61573 20.4452 3.48975 19.7013 3.48975C17.9675 3.48975 17.1457 4.36562 17.1457 5.46946C17.1457 6.50131 17.7456 7.02923 18.8254 7.34119L19.5213 7.53916C20.2952 7.77312 20.5832 7.9471 20.5832 8.49302C20.5832 9.05094 20.0972 9.3269 19.4013 9.3269Z" fill="#60859F"/>
    <path d="M26.1623 10.2267C27.9201 10.2267 29.3478 8.91294 29.3478 6.81324C29.3478 4.76154 28.106 3.49573 26.1323 3.49573C24.3626 3.49573 22.9468 4.79754 22.9468 6.89123C22.9528 8.91294 24.1706 10.2267 26.1623 10.2267ZM26.1263 4.42559C26.9482 4.42559 27.6921 5.00751 27.6921 6.90923C27.6921 8.73896 26.9842 9.28489 26.1743 9.28489C25.3404 9.28489 24.5905 8.69097 24.5905 6.80125C24.5965 4.95351 25.3044 4.42559 26.1263 4.42559Z" fill="#60859F"/>
    <path d="M30.8841 9.18897L30.0202 9.38694V10.0708H33.3018V9.38694L32.4499 9.18897V0.910178L32.2879 0.82019L29.9482 1.10815V1.83404L30.8841 2.03801V9.18897Z" fill="#60859F"/>
    <path d="M34.4233 8.34907C34.4233 9.50091 34.9572 10.2088 36.277 10.2088C37.1709 10.2088 37.8608 9.88485 38.3407 9.41692H38.4187V10.1188L38.5807 10.1908L40.7584 9.90285V9.20695L39.9125 9.01498V3.60376L39.7505 3.51978L37.5249 3.74774V4.46764L38.3467 4.66561V8.76301C38.0228 8.90099 37.6748 9.06297 37.0389 9.06297C36.193 9.06297 35.9771 8.70302 35.9771 7.93513V3.62176L35.8211 3.53777L33.5654 3.76574V4.48564L34.4233 4.68361V8.34907Z" fill="#60859F"/>
    <path d="M42.0064 8.54093C42.0064 9.77675 42.6243 10.1847 43.8121 10.1907C44.424 10.1907 44.946 10.0047 45.2339 9.81874V9.08685C45.0059 9.13484 44.754 9.17684 44.508 9.17684C43.8121 9.17684 43.5722 8.91887 43.5722 8.25897V4.5875H45.1259V3.61564H43.5722V2.10986H42.3663L42.1384 3.61564L41.1665 3.9276V4.5875H42.0004V8.54093H42.0064Z" fill="#60859F"/>
    <path d="M48.8932 10.2207C50.027 10.2207 50.7229 9.88478 51.1609 9.61482V8.82294C50.6869 8.96691 50.159 9.14089 49.2651 9.14089C48.0473 9.14089 47.3214 8.46299 47.2374 6.9632H51.4668C51.4668 4.67754 50.6329 3.50171 48.7012 3.50171C46.9315 3.50171 45.6177 4.72553 45.6177 6.80723C45.6177 8.87093 46.6495 10.2207 48.8932 10.2207ZM48.6472 4.41358C49.4091 4.41358 49.8291 4.88151 49.8291 6.09933L47.2374 6.18332C47.3214 4.9115 47.8314 4.41358 48.6472 4.41358Z" fill="#60859F"/>
    <path d="M53.0026 9.18895L52.1447 9.38693V10.0708H55.4143V9.38693L54.5744 9.18895V0.916159L54.4124 0.826172L52.0728 1.10813V1.84002L53.0026 2.038V9.18895Z" fill="#60859F"/>
    <path d="M68.8766 5.64351C68.5947 5.64351 68.3427 5.61952 68.3307 5.61952V6.3934C68.3427 6.3934 68.8286 6.47139 69.2006 6.47139C70.7663 6.47139 72.0082 5.7155 72.0082 3.89777C72.0082 2.39199 71.1323 1.47412 68.7086 1.47412H65.1812V2.24201L66.081 2.46398V9.05702L65.1812 9.27899V10.0469H68.8766V9.27899L67.7908 9.05702V2.47598H68.8046C69.8185 2.47598 70.2744 2.92591 70.2744 4.02375C70.2684 5.0736 69.7885 5.64351 68.8766 5.64351Z" fill="#60859F"/>
    <path d="M75.4277 10.2148C77.1735 10.2148 78.6013 8.90098 78.6013 6.80128C78.6013 4.74358 77.3654 3.48376 75.4097 3.48376C73.652 3.48376 72.2422 4.78558 72.2422 6.87327C72.2422 8.89498 73.454 10.2148 75.4277 10.2148ZM75.3977 4.40763C76.2136 4.40763 76.9575 4.98954 76.9575 6.89727C76.9575 8.727 76.2496 9.26692 75.4457 9.26692C74.6178 9.26692 73.874 8.67301 73.874 6.78328C73.874 4.94155 74.5818 4.40763 75.3977 4.40763Z" fill="#60859F"/>
    <path d="M81.6964 9.30881C81.2045 9.30881 80.8206 9.17083 80.6046 9.06285L80.3107 8.09699H79.4048L79.5188 9.84273C80.1127 10.0647 80.9046 10.2147 81.6065 10.2147C83.4662 10.2147 84.3181 9.30881 84.3181 8.10899C84.3181 7.18512 83.8561 6.59721 82.6443 6.24926L81.9124 6.03929C81.0965 5.80532 80.8146 5.59535 80.8146 5.12142C80.8146 4.5635 81.2885 4.36553 81.8884 4.36553C82.3384 4.36553 82.6083 4.44952 82.8063 4.5515L83.0463 5.40938H83.9101V3.75962C83.4422 3.59764 82.7343 3.47766 81.9904 3.47766C80.2747 3.47766 79.4588 4.35353 79.4588 5.45737C79.4588 6.48322 80.0527 7.01715 81.1265 7.3231L81.8164 7.52107C82.5843 7.75504 82.8663 7.92901 82.8663 8.47493C82.8663 9.03885 82.3863 9.30881 81.6964 9.30881Z" fill="#60859F"/>
    <path d="M85.9859 9.17092L85.1581 9.36889V10.0528H88.3856V9.36889L87.5577 9.17092V3.57973L87.3957 3.48975L85.0801 3.7897V4.5156L85.9859 4.71957V9.17092Z" fill="#60859F"/>
    <path d="M86.7178 2.68589C87.2277 2.68589 87.6357 2.32595 87.6357 1.84602C87.6357 1.35409 87.2277 0.994141 86.7178 0.994141C86.2259 0.994141 85.8179 1.35409 85.8179 1.84602C85.8119 2.32595 86.2199 2.68589 86.7178 2.68589Z" fill="#60859F"/>
    <path d="M91.0791 8.24702V4.56956H92.6268V3.6037H91.0791V2.09192H89.8792L89.6573 3.6037L88.6914 3.91565V4.56956H89.5193V8.52898C89.5193 9.7648 90.1372 10.1727 91.319 10.1727C91.9249 10.1727 92.4409 9.98677 92.7288 9.8008V9.0689C92.5068 9.1229 92.2609 9.15889 92.0089 9.15889C91.319 9.15889 91.0791 8.90093 91.0791 8.24702Z" fill="#60859F"/>
    <path d="M93.4187 9.36887V10.0528H96.6462V9.36887L95.8183 9.1769V3.57971L95.6563 3.49573L93.3467 3.78968V4.52158L94.2526 4.71955V9.1709L93.4187 9.36887Z" fill="#60859F"/>
    <path d="M94.9785 2.68591C95.4824 2.68591 95.8963 2.32596 95.8963 1.84603C95.8963 1.34811 95.4884 0.988159 94.9785 0.988159C94.4866 0.988159 94.0786 1.34811 94.0786 1.84603C94.0786 2.33196 94.4866 2.68591 94.9785 2.68591Z" fill="#60859F"/>
    <path d="M103.341 4.31153V3.60364H100.918V4.31153L101.619 4.44951L100.24 8.43293H100.186L98.8118 4.44951L99.5197 4.31153V3.60364H96.5142V4.31153L97.1381 4.47951L99.2498 10.0527H100.618L102.711 4.47951L103.341 4.31153Z" fill="#60859F"/>
    <path d="M106.395 10.1967C107.523 10.1967 108.219 9.86075 108.645 9.59079V8.7929C108.177 8.94888 107.655 9.11686 106.767 9.11686C105.555 9.11686 104.835 8.43896 104.751 6.93917H108.963C108.963 4.64751 108.135 3.47168 106.215 3.47168C104.457 3.47168 103.149 4.6955 103.149 6.7772C103.143 8.8529 104.169 10.1967 106.395 10.1967ZM106.149 4.39555C106.899 4.39555 107.325 4.86348 107.325 6.0813L104.751 6.16529C104.841 4.89347 105.345 4.39555 106.149 4.39555Z" fill="#60859F"/>
    <path d="M110.007 9.17689L109.155 9.36886V10.0528H112.412V9.36886L111.566 9.17689V0.898092L111.41 0.808105L109.077 1.09006V1.82196L110.007 2.01993V9.17689Z" fill="#60859F"/>
    <path d="M115.369 9.90283L115.069 10.6347C114.799 11.2586 114.44 11.4746 113.798 11.4746C113.576 11.4746 113.414 11.4386 113.144 11.3786V12.5064C113.366 12.6204 113.822 12.5424 114.194 12.5424C115.303 12.5424 115.825 11.9965 116.233 10.9107L118.657 4.48561L119.287 4.31164V3.60974H116.749V4.31164L117.481 4.48561L116.233 8.16308H116.185L114.775 4.47362L115.531 4.31164V3.60974H112.466V4.31164L113.108 4.48561L115.369 9.90283Z" fill="#60859F"/>
    </svg>

</template>

