<template>
    <div class="flex flex-wrap overflow-hidden mx-[24px]">
        <panel  @click='selected(0)' text="Needs repair or replacing">
            <template #svg>
                <repair/>
            </template>
        </panel>
        <panel @click='selected(3)' text="Trap interfered with">
            <template #svg>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.9673 2.0332L2.03271 17.9678M2.03271 2.0332L17.9673 17.9678" stroke="#D44944" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </template>
        </panel>
        <panel  @click='selected(1)' text="Trap is missing">
            <template #svg>
                <svg width="29" height="19" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.80965 17.1455L1.71094 15.8818" stroke="#8A8A8A" stroke-opacity="0.75" stroke-width="2" stroke-linecap="round"/>
                    <path d="M1.89585 10.5679C1.9148 10.0464 1.93473 9.52398 1.92317 9.05731" stroke="#8B8B8B" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round"/>
                    <path d="M2.00041 1.99989C1.99898 2.68393 1.99934 3.34224 2.00043 4.0002" stroke="#8A8A8A" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"/>
                    <path d="M7.00028 1.99997C8.00037 2 8.25208 2.04952 8.90551 2.07537" stroke="#8A8A8A" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"/>
                    <path d="M14.0074 2.02254C15.4094 2.06268 15.2841 2.05487 15.82 2.07168" stroke="#8A8A8A" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"/>
                    <path d="M20.6538 1.81738C21.5102 1.84776 22.2553 1.87746 23.0194 1.81443" stroke="#8A8A8A" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"/>
                    <path d="M27.3345 2.02832C27.3588 2.62729 27.3588 2.62729 27.3831 3.48187" stroke="#8A8A8A" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"/>
                    <path d="M27.7979 8.57129C27.8099 9.16322 27.8101 9.73992 27.8073 10.3353" stroke="#8B8B8B" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round"/>
                    <path d="M5.78027 17.0215L7.15798 17.076" stroke="#8A8A8A" stroke-opacity="0.85" stroke-width="2" stroke-linecap="round"/>
                    <path d="M11.3071 17.1989C11.9134 17.1639 12.471 17.1465 13.0824 17.1707" stroke="#8A8A8A" stroke-opacity="0.85" stroke-width="2" stroke-linecap="round"/>
                    <path d="M16.5044 17.3555C16.8979 17.3841 17.9965 17.4539 18.3924 17.4792" stroke="#8A8A8A" stroke-opacity="0.85" stroke-width="2" stroke-linecap="round"/>
                    <path d="M21.9507 17.582L24.5093 17.6833" stroke="#8A8A8A" stroke-opacity="0.85" stroke-width="2" stroke-linecap="round"/>
                    <path d="M27.9173 17.2893C27.9138 16.6226 27.9173 17.2893 27.8909 15.6771" stroke="#8A8A8A" stroke-opacity="0.75" stroke-width="2" stroke-linecap="round"/>
                </svg>
            </template>
        </panel>
        <panel  @click='selected(2)' text="QR card needs repair or replacing">
            <template #svg>
                <wrong-qr/>
            </template>
        </panel>
    </div>
</template>

<script>
import Panel from "@/Components/Panel.vue"
import Repair from "@/Components/SVG/Repair.vue";
import EmptySvg from "@/Components/SVG/EmptySvg.vue";
import WrongQr from "@/Components/SVG/WrongQr.vue";

export default {
    name: "StepFour",
    components:{
        WrongQr,
        Repair,
        Panel,
        EmptySvg,
    },
    data() {
        return {
            values:[
                {
                    trap_condition: 'Needs maintenance',
                    status: 'Removed for Repair',
                    words: 'The trap or trap box needs repair or replacing.',
                    step:7
                },
                {
                    trap_condition: 'Needs maintenance',
                    status: 'Trap gone',
                    words: 'The trap is missing.',
                    step:7
                },
                {
                    trap_condition: 'Needs maintenance',
                    status: 'Removed for Repair',
                    words: 'The QR code is wrong, missing or not working.',
                    step:7
                },
                {
                    trap_condition: 'OK',
                    status: 'Trap interfered with',
                    words: 'Trap interfered with',
                    step:7
                }
            ]
        }
    },
    methods:{
        selected (val){
            this.$emit('selected',this.values[val])
        }
    }
}
</script>
