<template>
    <svg width="32" height="32" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2440_1962)">
            <rect class="fill-[#232F80] dark:fill-[#2F45C5]" x="40.1875" y="40.6123" width="40" height="40" rx="20" transform="rotate(180 40.1875 40.6123)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0362 26.2608C23.5049 25.7922 23.5049 25.0324 23.0362 24.5638L19.0848 20.6123L23.0362 16.6608C23.5049 16.1922 23.5049 15.4324 23.0362 14.9638C22.5676 14.4951 21.8078 14.4951 21.3392 14.9638L16.5392 19.7638C16.0705 20.2324 16.0705 20.9922 16.5392 21.4608L21.3392 26.2608C21.8078 26.7295 22.5676 26.7295 23.0362 26.2608Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_2440_1962">
                <rect width="40" height="40" fill="white" transform="translate(0.1875 0.612305)"/>
            </clipPath>
        </defs>
    </svg>
</template>

