<template>
    <label :for="toggleId">
        <div class="relative inline-flex items-center cursor-pointer" v-if="type === 'big'">
            <input type="checkbox" @click="toggle" :checked="theme" :id="toggleId" class="sr-only peer">
            <div class="w-11 h-6 bg-gray-300 rounded-full  dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </div>
        <div class="relative inline-flex items-center cursor-pointer mt-[7px]" v-if="type === 'small'">
            <input type="checkbox" @click="toggle" :checked="theme" :id="toggleId" class="sr-only peer">
            <div class="w-[27.03px] h-[12.6px] bg-t_blue_gray-200 rounded-full 
             dark:bg-t_black-800 peer-checked:after:translate-x-full 
            dark:peer-checked:after:border-t_purple-300 
            dark:peer-checked:after:bg-t_purple-300
             peer-checked:after:bg-t_purple-500 peer-checked:after:border-t_purple-500 
             after:content-[''] after:absolute after:top-[-1px] after:left-[0.5px] 
              after:bg-t_white-100 dark:after:bg-t_blue_gray-500 dark:after:border-t_blue_gray-500 
              after:border-t_white-100 after:border after:rounded-full after:h-[15.2px] after:w-[15.2px] 
              after:transition-all dark:border-t_purple-700 peer-checked:bg-t_purple-200 dark:peer-checked:bg-t_purple-700
              "></div>
        </div>
    </label>
</template>

<script>
import { ref, watch } from 'vue';

export default {
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        id: {
            type: String,
            default: 'toggle'
        },
        type:{
            type: String,
            default: 'big'
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const theme = ref(props.modelValue);
        const toggleId = `toggle-${Math.random().toString(36).substr(2, 9)}`;

        const toggle = () => {
            theme.value = !theme.value;
        };

        watch(theme, (newValue) => {
            emit('update:modelValue', newValue);
        });

        return {
            theme,
            toggle,
            toggleId
        };
    }
};
</script>
