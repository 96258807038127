<script setup>
import Primary from "@/UI/Buttons/Primary.vue";
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import {  Map, MapStyle, Marker, config } from '@maptiler/sdk';
import {ref, shallowRef, onMounted, onUnmounted, markRaw, computed } from 'vue';
import '@maptiler/sdk/dist/maptiler-sdk.css';

const emit = defineEmits(['update-location']);
const props = defineProps({
    lon:{
        type: String,
    },
    lat:{
        type: String,
    }
})

const mapContainer = shallowRef(null);
const map = shallowRef(null);
const markerData = shallowRef(null);

const userLocation =  computed(() => {
    return { lng: (props.lon != null ? props.lon : 171.691162), lat: (props.lat != null ? props.lat : -42.406418) , zoom:  4.6  }
});


onMounted(() => {
    config.apiKey = 'j8XguUeSTfgQe3qtQzjE';

    map.value = markRaw(new Map({
        container: mapContainer.value ?? 'test',
        style: MapStyle.OUTDOOR,
        center: [userLocation.value.lng, userLocation.value.lat],
        zoom: userLocation.value.zoom,
        pitch: 40,
    }));


    markerData.value = new Marker({color: "#FF0000"})
        .setLngLat([userLocation.value.lng, userLocation.value.lat])
        .addTo(map.value);

    map.value.on('move', function(e) {
        markerData.value.setLngLat(map.value.getCenter())
    });
})

onUnmounted(() => {
    map.value?.remove();
    markerData.value?.remove();
})

function setLocation(){
    const cords = markerData.value.getLngLat();
    localStorage.setItem("lng", cords.lng );
    localStorage.setItem("lat", cords.lat );
    localStorage.setItem("zoom", map.value.getZoom());

    emit('update-location', {
        lat: cords.lat,
        lon: cords.lng,
        altitude: 0,
    })
}

</script>
<template>
        <div class="w-full flex flex-wrap" >
            <div class="map-wrap">
                <div class="map" ref="mapContainer"></div>
                <div class="w-full z-50 flex absolute bottom-14 px-10">
                    <primary  @click="setLocation">
                        Save location
                    </primary>
                </div>
            </div>
        </div>
</template>


<style scoped>
.map-wrap {
    position: relative;
    width: 100%;
    height: calc(100vh - 25px); /* calculate height of the screen minus the heading */
}

.map {
    position: absolute;
    width: 100%;
    height: 100%;
}
</style>
