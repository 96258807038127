<script setup>
import Panel from "@/Components/Panel.vue"
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import {onMounted, ref} from "vue";

const emit = defineEmits(['onUpdateTrapType']);
const loading = ref(true)
const traps_type = ref([]);
const getTrapsType = () => {
    axios.get('/api/get-traps-type').then(response => {
        traps_type.value = response.data;
        loading.value = false
    })
        .catch(error => {
            console.log(error)
        })
}
onMounted(() => {
    getTrapsType()
})

</script>
<template>
    <div class="flex flex-wrap w-full px-[14px] mt-[32px]">

        <Heading_H1 class="mb-[32px]">
            What model is this trap?
        </Heading_H1>
        <div v-if="loading" class="ml-3 w-12 h-12 rounded-full animate-spin border-2 border-dashed border-indigo-500 border-t-transparent"></div>
        <panel v-for="(trap, index) in traps_type" :key="index" @click='emit("updateTrapType", trap)' :text="trap.name"/>
    </div>
</template>
