<template>
    <BreezeAuthenticatedLayout>
        <div class="flex flex-wrap justify-end ">
            <img src="../../assets/pr_bg.png" class="h-[177px]  object-right absolute top-[100px]">
        </div>
        <div class="flex flex-wrap mt-[15px] mb-[8px] px-[24px] z-10">
            <Link :href="route('user.projects.view',project.id)" class="w-full flex flex-wrap justify-start items-center ">
                <BlueCircle/>
                <span class="font-bold montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">Back - </span>
                <span  class=" montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white ">
                {{project.name ?? ''}}
              </span>
            </Link>
            <div class="w-full mt-[24px] flex flex-wrap">
                <TitleText class="w-full">Request to join as a Manager</TitleText>

                <p class="text-t_blue_gray-700 font-fira text-[18px] mt-[24px] dark:text-t_white-200">
                    Ask the current Project Manager(s) of  Predator Free Island Bay if you can join as a manager.
                </p>
                <p class="text-t_blue_gray-700 font-fira text-[18px] mt-[24px] dark:text-t_white-200">
                    The will be notified of your request and will have to approve it.
                </p>
                <Link class="mt-14 w-full" target="_blank" :href="route('user.projects.ask_manager', [project.id, {'confirm':'approve'}])">
                    <primary >
                        send request to join
                    </primary>
                </Link>
            </div>
        </div>

    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import {Link} from '@inertiajs/vue3'
import BlueCircle from "@/Components/SVG/BlueCircle.vue";
import TitleText from "@/UI/Text/TitleText.vue";
import Primary from "@/UI/Buttons/Primary.vue";

export default {
    components:{
        Link,
        BreezeAuthenticatedLayout,
        BlueCircle,
        TitleText,
        Primary
    },
    name: "Export",
    props:{
        project:Object,
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>
<style scoped>

</style>
