<template>
    <svg width="112" height="60" viewBox="0 0 112 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 46.0728C0 41.3497 3.4639 37.7007 8.15485 37.7007C11.6187 37.7007 14.8694 39.8168 15.6982 43.333H13.7832C13.1395 40.9433 10.7779 39.3783 8.20312 39.3783C4.43347 39.3783 1.88684 42.3554 1.88684 46.0647C1.89891 49.8746 4.53002 52.7713 8.24738 52.7713C10.8222 52.7713 13.2119 51.2103 13.8274 48.8165H15.7263C14.9217 52.3448 11.6509 54.4489 8.18301 54.4489C3.39953 54.4409 0 50.7959 0 46.0728Z" fill="#7A9EB8"/>
    <path d="M28.5718 52.4172V54.0264H23.1768C21.4871 54.0264 20.4773 52.9562 20.4773 51.0533V32.6114H14.4668V31.0464H22.3159V51.0694C22.3159 51.8982 22.7182 52.4172 23.5228 52.4172H28.5718Z" fill="#7A9EB8"/>
    <path d="M29.3364 46.1009C29.3364 41.1646 32.4946 37.7007 37.0326 37.7007C40.5287 37.7007 42.4598 39.9094 43.0432 41.3215H43.2282V38.103H45.0668V54.0466H43.2886V50.8885H43.1035C42.5622 51.9865 41.7164 52.9057 40.6671 53.5363C39.6178 54.1669 38.4092 54.4823 37.1855 54.4449C32.4946 54.4409 29.3364 50.977 29.3364 46.1009ZM43.2886 46.1009C43.2886 42.0255 40.8023 39.3864 37.2539 39.3864C33.7055 39.3864 31.2474 42.1462 31.2474 46.1009C31.2474 50.1241 33.6613 52.7874 37.2821 52.7874C41.0477 52.7713 43.2886 49.7499 43.2886 46.1009Z" fill="#7A9EB8"/>
    <path d="M55.1972 45.4571V52.4815H62.5232V54.0424H48.3257V52.4815H53.3546V39.6638H48.6636V38.0988H55.165V41.2892H55.35C55.6827 40.3472 56.3019 39.5329 57.1209 38.9608C57.9398 38.3886 58.9173 38.0872 59.9163 38.0988H62.5232V39.9414H59.9485C56.8507 39.9414 55.1972 42.4237 55.1972 45.4571Z" fill="#7A9EB8"/>
    <path d="M64.9771 46.0728C64.9771 40.9835 68.4409 37.7007 72.7336 37.7007C77.5613 37.7007 80.462 41.1968 80.462 45.7952V46.7366H66.9081C67.0007 50.2126 69.3019 52.7713 72.9227 52.7713C75.4653 52.7713 77.674 51.4235 78.378 49.3677H80.3091C79.42 52.3448 76.632 54.4569 72.8905 54.4569C68.095 54.4409 64.9771 50.8563 64.9771 46.0728ZM78.4987 45.0911C78.3458 41.4422 75.9843 39.3864 72.7336 39.3864C69.7283 39.3864 67.1536 41.6554 66.9403 45.0911H78.4987Z" fill="#7A9EB8"/>
    <path d="M73.7049 16.4216C73.7049 16.4216 67.7438 20.4684 64.5995 24.2623C61.4552 28.0562 57.8969 34.3794 57.8969 34.3794C57.8969 34.3794 68.6463 36.4028 74.2107 27.0445C79.7751 17.6862 75.9812 2.00476 75.9812 2.00476C75.9812 2.00476 62.3232 4.40757 55.6206 15.5364C48.918 26.6651 51.3208 38.8056 51.3208 38.8056C51.3208 38.8056 69.4694 41.4614 77.8782 28.6886C86.2869 15.9157 85.9086 6.55745 82.4308 4.02818C78.9531 1.49891 67.2143 7.56915 60.5527 18.3186C53.8911 29.068 57.5176 44.37 57.5176 44.37M68.3934 42.4731C68.3934 42.4731 80.9133 45.7611 90.2716 40.9555C99.6299 36.1499 100.642 26.4122 100.642 26.4122C100.642 26.4122 87.6159 29.068 79.0163 37.6675C70.4168 46.267 69.658 55.1194 71.1756 56.5105C72.6932 57.9016 80.281 61.5691 90.2716 53.9813C100.262 46.3935 108.609 21.986 108.609 21.986C108.609 21.986 92.4215 17.9392 77.8782 26.4122C63.3349 34.8853 58.9086 47.7845 59.288 50.6932C59.6674 53.6019 66.1171 56.3841 83.4426 53.3489C100.768 50.3138 110 29.7003 110 29.7003" stroke="#7A9EB8" stroke-opacity="0.33" stroke-width="2.52927" stroke-linecap="round"/>
    </svg>

</template>

