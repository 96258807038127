<template>
    <Show>
        <template #header>
            <div @click="back" class="flex items-center w-2/12 h-full ">
                <svg  width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.39316 12.0653C7.86179 11.5966 7.86179 10.8368 7.39316 10.3682L3.44168 6.41675L7.39316 2.46528C7.86179 1.99665 7.86179 1.23685 7.39316 0.76822C6.92453 0.299591 6.16473 0.299591 5.6961 0.76822L0.896099 5.56822C0.427469 6.03685 0.427469 6.79665 0.896099 7.26528L5.6961 12.0653C6.16473 12.5339 6.92453 12.5339 7.39316 12.0653Z" fill="white"/>
                </svg>
            </div>
            <div class="flex items-center justify-center w-8/12 h-full">
                <h1 class="text-white font-bold ml-1 text-[16px] montserrat capitalize tracking-wide tracking-[.05em]">{{trap_id}}</h1>
            </div>
            <div @click="$inertia.visit(this.route('scan'))" class="flex items-center justify-end w-2/12 h-full">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.837327 0.709641C1.22785 0.319117 1.86102 0.319117 2.25154 0.709641L6.54443 5.00253L10.8373 0.709639C11.2279 0.319115 11.861 0.319115 12.2515 0.709639C12.6421 1.10016 12.6421 1.73333 12.2515 2.12385L7.95865 6.41675L12.2515 10.7096C12.6421 11.1002 12.6421 11.7333 12.2515 12.1239C11.861 12.5144 11.2279 12.5144 10.8373 12.1239L6.54443 7.83096L2.25154 12.1239C1.86102 12.5144 1.22785 12.5144 0.837329 12.1239C0.446804 11.7333 0.446804 11.1002 0.837329 10.7096L5.13022 6.41675L0.837327 2.12385C0.446803 1.73333 0.446803 1.10017 0.837327 0.709641Z" fill="white"/>
                </svg>
            </div>
        </template>
        <div class="px-[14px] w-full flex flex-wrap">
            <div class="w-full" >
                <h1 class="text-[18px] px-3 text-t_black-800 font-[400] dark:text-t_white-200 font-fira mb-[32px]" >
                    This QR card needs to be linked to a  Trap to activate it
                </h1>
                <span  class="w-full px-3 text-[24px]  font-bold text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                    Select a Trap
                </span>
            </div>
            <div class="w-full mt-[32px]" v-if="type === 1">
                <select-component  type="true" v-model="search_text" :list="collection" :key="search_text" @selected="select"/>
            </div>
            <div class="w-full mt-[32px]" v-else>
                <panel @click='type=1' text="Link to an existing trap" small_text="Link to an existing trap within TrapScan">
                    <template #svg>
                        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7535 37.3084C16.1041 37.5109 16.5362 37.5109 16.8868 37.3084L24.2144 33.0778C24.565 32.8754 24.7811 32.5012 24.7811 32.0963V23.6352C24.7811 23.2303 24.565 22.8562 24.2144 22.6537L11.8103 15.4922C11.4596 15.2898 11.0276 15.2898 10.6769 15.4922L3.34939 19.7228C2.99873 19.9252 2.78271 20.2994 2.78271 20.7043V29.1654C2.78271 29.5703 2.99873 29.9445 3.34939 30.1469L15.7535 37.3084ZM22.2428 24.4463L11.2436 18.096L5.32098 21.5154V28.3543L15.8971 34.4604V27.6216L21.8197 24.2021L22.2428 24.4463V25.4233L17.1662 28.3543L22.2428 31.2852V24.4463Z" fill="#7A9EB8"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.9817 4.7999C23.3308 4.7999 21.1817 6.94894 21.1817 9.5999C21.1817 12.2509 23.3308 14.3999 25.9817 14.3999C28.6327 14.3999 30.7817 12.2509 30.7817 9.5999C30.7817 6.94894 28.6327 4.7999 25.9817 4.7999ZM18.7817 9.5999C18.7817 5.62345 22.0053 2.3999 25.9817 2.3999C29.9582 2.3999 33.1817 5.62345 33.1817 9.5999C33.1817 11.1549 32.6887 12.5948 31.8506 13.7717L37.6302 19.5514C38.0988 20.02 38.0988 20.7798 37.6302 21.2484C37.1616 21.7171 36.4018 21.7171 35.9332 21.2484L30.1535 15.4688C28.9766 16.307 27.5367 16.7999 25.9817 16.7999C22.0053 16.7999 18.7817 13.5764 18.7817 9.5999Z" fill="#7A9EB8"/>
                        </svg>
                    </template>
                </panel>
                <Link :href="route('create_trap')">
                    <panel text="Create a new trap">
                        <template #svg>
                            <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7535 38.3084C16.1041 38.5109 16.5362 38.5109 16.8868 38.3084L24.2144 34.0778C24.565 33.8754 24.7811 33.5012 24.7811 33.0963V24.6352C24.7811 24.2303 24.565 23.8562 24.2144 23.6537L11.8103 16.4922C11.4596 16.2898 11.0276 16.2898 10.6769 16.4922L3.34939 20.7228C2.99873 20.9252 2.78271 21.2994 2.78271 21.7043V30.1654C2.78271 30.5703 2.99873 30.9445 3.34939 31.1469L15.7535 38.3084ZM22.2428 25.4463L11.2436 19.096L5.32098 22.5154V29.3543L15.8971 35.4604V28.6216L21.8197 25.2021L22.2428 25.4463V26.4233L17.1662 29.3543L22.2428 32.2852V25.4463Z" fill="#7A9EB8"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.0521 21.198C33.3541 21.198 37.6521 16.9 37.6521 11.598C37.6521 6.29611 33.3541 1.99805 28.0521 1.99805C22.7502 1.99805 18.4521 6.29611 18.4521 11.598C18.4521 16.9 22.7502 21.198 28.0521 21.198ZM29.2521 7.99805C29.2521 7.3353 28.7149 6.79805 28.0521 6.79805C27.3894 6.79805 26.8521 7.3353 26.8521 7.99805V10.398H24.4521C23.7894 10.398 23.2521 10.9353 23.2521 11.598C23.2521 12.2608 23.7894 12.798 24.4521 12.798H26.8521V15.198C26.8521 15.8608 27.3894 16.398 28.0521 16.398C28.7149 16.398 29.2521 15.8608 29.2521 15.198V12.798H31.6521C32.3149 12.798 32.8521 12.2608 32.8521 11.598C32.8521 10.9353 32.3149 10.398 31.6521 10.398H29.2521V7.99805Z" fill="#00BF8C"/>
                            </svg>
                        </template>
                    </panel>
                </Link>
            </div>
        </div>
    </Show>
</template>

<script>
import Show from "@/Layouts/Show.vue";
import {Link, useForm} from '@inertiajs/vue3'
import SelectComponent from '@/Components/SelectComponent.vue'
import Panel from "@/Components/Panel.vue"

export default {
    components:{
        Link,
        Show,
        SelectComponent,
        Panel
    },
    name: "RemapQR",
    props:{
        trap_id:String,
        user:String,
        qr_data: Object,
    },
    data() {
        return {
            type:0,
            search_text:null,
            collection:[],
            newQR: useForm({
                qr_id:null,
                trap_id:null,
            }),
        }
    },
    mounted() {
        this.newQR.qr_id = this.trap_id
        localStorage.setItem("qr_id", this.trap_id);
        localStorage.setItem("pr_id", this.qr_data?.project?.id ?? '');
        localStorage.setItem("pr_name", this.qr_data?.project?.name ?? '');
    },
    watch: {
        search_text(newQuestion, oldQuestion) {
            if (this.search_text.length > 1) {
                this.find_collection()
            }
        }
    },
    methods: {
        back(){
            if (this.type === 1) {
                this.type = 0;
            } else{
                this.$inertia.visit(this.route('scan'));
            }
        },
        find_collection(){
            axios.get('/api/get_traps?qr_id='+this.search_text+'&user='+this.user)
                .then(response => {
                    this.collection = response.data
                })
        },
        select(value){
            this.newQR.trap_id = value
            this.newQR.post(route('inspection.map_trap'),{
                preserveScroll: true,
                preserveState: true,
                onSuccess:() => {
                    localStorage.removeItem("qr_id");
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
