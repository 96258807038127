<template >
    <BreezeAuthenticatedLayout >
        <div class="pb-24 overflow-x-hidden mx-4" >
            <Link :href="route('user.index')" class="w-full flex flex-wrap justify-start items-center mt-4 ">
                <svg width="32" height="32" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2440_1962)">
                        <rect class="fill-[#232F80] dark:fill-[#2F45C5]" x="40.1875" y="40.6123" width="40" height="40" rx="20" transform="rotate(180 40.1875 40.6123)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0362 26.2608C23.5049 25.7922 23.5049 25.0324 23.0362 24.5638L19.0848 20.6123L23.0362 16.6608C23.5049 16.1922 23.5049 15.4324 23.0362 14.9638C22.5676 14.4951 21.8078 14.4951 21.3392 14.9638L16.5392 19.7638C16.0705 20.2324 16.0705 20.9922 16.5392 21.4608L21.3392 26.2608C21.8078 26.7295 22.5676 26.7295 23.0362 26.2608Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2440_1962">
                            <rect width="40" height="40" fill="white" transform="translate(0.1875 0.612305)"/>
                        </clipPath>
                    </defs>
                </svg>
                <span class="font-bold montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">Back - </span>
                <span  class=" montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">
                Results
            </span>
            </Link>
            <div class="flex flex-wrap mt-12 ">
                <Heading_H1 >Scan history</Heading_H1>
                <template v-for="(value, key, index) in allHistory" :key="index">
                    <div class="my-[3px] w-full mb-[16px]">
                        <span class="text-t_blue_gray-700 dark:text-t_blue_gray-300 my-[8px] font-normal font-fira text-[15px] leading-[120%] tracking-[0.01em]">{{convert_Date(key)}}</span>
                    </div>
                    <Link v-for="(data, index) in value" :key="index" :href="route('user.catch_history_detail', data.id)" class="w-full">
                        <div class="flex flex-row p-[10px] h-[55px] mb-[10px] w-full hover:border-[3px] hover:border-t_purple-500 dark:bg-t_black-800 bg-white rounded-[4px]">
                            <div class=" flex items-center justify-center">
                                <QrCode_Historie/>
                            </div>
                            <div class="flex w-7/12 flex-wrap px-3 items-center ">
                                <p class="montserrat w-full font-bold dark:text-t_white-200 tracking-[.02em] leading-[120%]">{{data.trap.qr_id ?? data.trap.name}}</p>
                                <p class="text-sm font-fira" :class="{'t_blue_gray-400':data.species_caught === 'None', 'text-t_warms-500':data.species_caught !== 'None'}">{{data.species_caught}}</p>
                            </div>
                            <div class="flex items-center justify-end w-3/12">
                                <span class="text-t_blue_gray-700 dark:text-t_blue_gray-300  font-semibold montserrat text-[12px] uppercase leading-[120%] tracking-[0.10em]">{{convert_Time(data.time)}}</span>
                            </div>
                        </div>
                    </Link>

                </template>
<!--                <div class="font-medium text-t_warms-500 text-center w-full" v-if="next_page === null">Whoops! All data loaded.</div>-->
                <div class="flex flex-wrap w-full gap-x-2 justify-center">
                    <Link class="flex align-items-center flex-wrap font-bold gap-x-1 text-t_purple underline dark:text-t_purple-200" v-if="prev_page" :href="prev_page">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                        </svg>
                        Prev
                    </Link>
                    <Link class="flex align-items-center flex-wrap font-bold gap-x-1 text-t_purple underline dark:text-t_purple-200" v-if="next_page" :href="next_page">
                        Next
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </Link>
                </div>
            </div>
        </div>

    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import {Link} from '@inertiajs/vue3';
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import QrCode_Historie from "@/Components/SVG/QrCode_Historie.vue";

export default {
    name: "History",
    props: ['hist', 'next_page','prev_page'],
    components:{
        BreezeAuthenticatedLayout,Link,Heading_H1,QrCode_Historie
    },
    data() {
        return {
            allHistory: this.hist
        }
    },
    methods: {
        convert_Date(date){
            const event = new Date(date);
            const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

            return event.toLocaleDateString('en-GB', options)
        },
        convert_Time(date){
            const event = new Date(date);
            return event.toLocaleTimeString('en-GB',{ hour: "2-digit", minute: "2-digit",  hour12: true })
        },

    }
}
</script>

<style scoped>

</style>
