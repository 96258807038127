<script>
import { Link } from '@inertiajs/vue3'
import TitleText from '@/UI/Text/TitleText.vue';
import Panel from "@/Components/Panel.vue"

export default {
    components:{
        Link,
        TitleText,
        Panel
    },
    name: "CheckoutSuccess",
    props:{

    },
    data() {
        return {
            selected:null,
        }
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<template>
    <div class="w-full  bg-t_blue_gray-100  h-screen">
        <div class="w-full flex flex-wrap justify-between bg-t_black-900 py-[10px] px-[15px] items-center">
            <Link class="w-1/12" :href="route('billing')">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8487 17.6485C15.3174 17.1799 15.3174 16.4201 14.8487 15.9515L10.8973 12L14.8487 8.04853C15.3174 7.5799 15.3174 6.8201 14.8487 6.35147C14.3801 5.88284 13.6203 5.88284 13.1517 6.35147L8.35167 11.1515C7.88304 11.6201 7.88304 12.3799 8.35167 12.8485L13.1517 17.6485C13.6203 18.1172 14.3801 18.1172 14.8487 17.6485Z" fill="white"/>
                </svg>
            </Link>
            <span class="text-[18px] w-10/12 text-center text-t_white font-bold font-fira">Join TrapScan</span>
            <Link class="w-1/12 justify-end" :href="route('scan')">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976312 1.31658 -0.0976313 1.70711 0.292893L6 4.58579L10.2929 0.292891C10.6834 -0.097633 11.3166 -0.0976331 11.7071 0.292891C12.0976 0.683415 12.0976 1.31658 11.7071 1.7071L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.68342 12.0976 0.292895 11.7071C-0.0976291 11.3166 -0.0976292 10.6834 0.292895 10.2929L4.58579 6L0.292894 1.70711C-0.0976309 1.31658 -0.097631 0.683418 0.292893 0.292893Z" fill="white"/>
                </svg>
            </Link>
        </div>
        <div class="bg-t_purple-800 flex justify-center py-[39px]">
            <svg width="76" height="54" viewBox="0 0 76 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.03564 26.848L28.1133 46.9256L67.9647 7.0742" stroke="#00CF98" stroke-width="10" stroke-linecap="square"/>
            </svg>
        </div>
        <div class="w-full text-center py-[16px] text-t_purple-700 bg-t_white-300 font-fira text-[18px] font-bold">
<!--            You are now a Manager-->
            Thanks for subscribing
        </div>
        <div class="flex flex-wrap justify-center w-full mt-[32px] px-[24px] ">
            <Link :href="route('user.projects.create')" class="w-full" >
                <panel text="Create a new Project">
                    <template #svg>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.49731 21.8485C5.49731 21.8485 12.294 21.2669 14.5829 23.8286C14.9912 24.2856 15.2538 24.7685 15.4098 25.2537C13.6151 24.2463 10.6329 23.3393 6.05325 23.2079C6.87357 23.783 7.89482 24.3937 8.93855 25.0178C11.7631 26.7069 14.7523 28.4944 14.3692 29.9413C13.6609 30.4776 11.3262 31.9824 9.39115 30.211C7.08028 28.0955 5.49731 21.8485 5.49731 21.8485Z" fill="#7A9EB8"/>
                            <path d="M19.3457 29.4896C19.3457 29.4896 18.8132 26.8783 20.6504 25.4932C22.4877 24.1081 25.7032 25.4927 25.7032 25.4927C25.7032 25.4927 24.7845 28.7241 22.9471 29.6473C21.1096 30.5705 19.3457 29.4896 19.3457 29.4896Z" fill="#7A9EB8"/>
                            <path d="M11.9265 26.9346C16.504 30.2577 16.8138 33.3699 16.3719 39.2776M19.274 30.0204C17.6996 30.8116 16.5187 32.7896 16.5187 35.1633M14.5829 23.8286C12.294 21.2669 5.49731 21.8485 5.49731 21.8485C5.49731 21.8485 7.08028 28.0955 9.39115 30.211C11.3262 31.9824 13.6609 30.4776 14.3692 29.9413C14.7523 28.4944 11.7631 26.7069 8.93855 25.0178C7.89482 24.3937 6.87357 23.783 6.05325 23.2079C10.6329 23.3393 13.6151 24.2463 15.4098 25.2537C15.2538 24.7685 14.9912 24.2856 14.5829 23.8286ZM20.6504 25.4932C18.8132 26.8783 19.3457 29.4896 19.3457 29.4896C19.3457 29.4896 21.1096 30.5705 22.9471 29.6473C24.7845 28.7241 25.7032 25.4927 25.7032 25.4927C25.7032 25.4927 22.4877 24.1081 20.6504 25.4932Z" stroke="#7A9EB8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M29.8705 23.198C35.1724 23.198 39.4705 18.9 39.4705 13.598C39.4705 8.29611 35.1724 3.99805 29.8705 3.99805C24.5686 3.99805 20.2705 8.29611 20.2705 13.598C20.2705 18.9 24.5686 23.198 29.8705 23.198ZM31.0705 9.99805C31.0705 9.3353 30.5332 8.79805 29.8705 8.79805C29.2078 8.79805 28.6705 9.3353 28.6705 9.99805V12.398H26.2705C25.6078 12.398 25.0705 12.9353 25.0705 13.598C25.0705 14.2608 25.6078 14.798 26.2705 14.798H28.6705V17.198C28.6705 17.8608 29.2078 18.398 29.8705 18.398C30.5332 18.398 31.0705 17.8608 31.0705 17.198V14.798H33.4705C34.1332 14.798 34.6705 14.2608 34.6705 13.598C34.6705 12.9353 34.1332 12.398 33.4705 12.398H31.0705V9.99805Z" fill="#00BF8C"/>
                        </svg>
                    </template>
                </panel>
             </Link>
             <Link :href="route('user.projects.index')" class="w-full" >
                <panel text="View existing projects ">
                    <template #svg>
                        <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.59814 7.60158C4.59814 7.60158 13.0555 6.87788 15.9037 10.0655C16.4117 10.6341 16.7385 11.2351 16.9326 11.8388C14.6994 10.5853 10.9885 9.45666 5.28991 9.29313C6.31067 10.0088 7.58144 10.7687 8.88019 11.5453C12.3949 13.6471 16.1144 15.8713 15.6377 17.6718C14.7564 18.3392 11.8512 20.2116 9.44338 18.0073C6.56788 15.375 4.59814 7.60158 4.59814 7.60158Z" fill="#7A9EB8"/>
                            <path d="M21.8302 17.1097C21.8302 17.1097 21.1676 13.8604 23.4537 12.1369C25.7398 10.4134 29.741 12.1362 29.741 12.1362C29.741 12.1362 28.5978 16.1572 26.3115 17.306C24.0251 18.4547 21.8302 17.1097 21.8302 17.1097Z" fill="#7A9EB8"/>
                            <path d="M12.5981 13.9305C18.2942 18.0655 18.6796 21.938 18.1297 29.2892M21.741 17.7702C19.7818 18.7547 18.3124 21.216 18.3124 24.1696M15.9037 10.0655C13.0555 6.87788 4.59814 7.60158 4.59814 7.60158C4.59814 7.60158 6.56788 15.375 9.44338 18.0073C11.8512 20.2116 14.7564 18.3392 15.6377 17.6718C16.1144 15.8713 12.3949 13.6471 8.88019 11.5453C7.58144 10.7687 6.31067 10.0088 5.28991 9.29313C10.9885 9.45666 14.6994 10.5853 16.9326 11.8388C16.7385 11.2351 16.4117 10.6341 15.9037 10.0655ZM23.4537 12.1369C21.1676 13.8604 21.8302 17.1097 21.8302 17.1097C21.8302 17.1097 24.0251 18.4547 26.3115 17.306C28.5978 16.1572 29.741 12.1362 29.741 12.1362C29.741 12.1362 25.7398 10.4134 23.4537 12.1369Z" stroke="#7A9EB8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </template>
                </panel>
             </Link>
        </div>
    </div>
</template>
