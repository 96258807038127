<template>
  <div class="h-full w-full" v-if="error === null">
    <!-- <test-component @scan="onDecode" :torch="torch"></test-component> -->
    <video ref="video" autoplay muted playsinline class="fixed w-full z-0 top-0 left-0 h-[90%] aspect-square"></video>

    <div class="absolute top-3 left-1/2 -translate-x-1/2">
      <button
        @click="redirect_to_history"
        type="button"
        class="bg-t_black-950 bg-opacity-60 h-[50px] w-[200px] rounded-full px-[32px] py-[8px]"
      >
        <span
          class="uppercase montserrat text-[13px] font-bold text-white text-opacity-100"
          >Scan History</span
        >
      </button>
    </div>
    <div class="absolute bottom-[110px] left-1/2 -translate-x-1/2">
      <button
        @click="find_by_id"
        type="button"
        class="bg-t_black-950 bg-opacity-60 h-[50px] w-[200px] rounded-full px-[32px] py-[8px]"
      >
        <span
          class="uppercase montserrat text-[13px] font-bold text-white text-opacity-100"
          >Enter ID instead</span
        >
      </button>
    </div>
    <div class="absolute bottom-44 left-1/2 -translate-x-1/2">
      <button
        @click="toggleTorch"
        class="bg-t_black-950 bg-opacity-60 rounded-full p-4 px-5"
      >
        <svg
          width="17"
          height="23"
          viewBox="0 0 17 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.6767 1.66504H16.6767V0.665039L15.6767 0.665039V1.66504ZM15.5055 6.21808L16.2714 6.8611L16.2714 6.8611L15.5055 6.21808ZM12.9006 9.32054L13.6665 9.96356L13.6665 9.96356L12.9006 9.32054ZM4.69935 9.32054L5.4652 8.67752L4.69935 9.32054ZM2.09447 6.21808L2.86032 5.57506H2.86032L2.09447 6.21808ZM1.92334 1.66504L1.92334 0.665039L0.92334 0.665039L0.92334 1.66504L1.92334 1.66504ZM14.6767 1.66504V5.74813H16.6767V1.66504L14.6767 1.66504ZM14.7397 5.57506L12.1348 8.67752L13.6665 9.96356L16.2714 6.8611L14.7397 5.57506ZM11.7295 9.79049V20.6401H13.7295V9.79049H11.7295ZM11.9987 20.3709H5.60134L5.60134 22.3709H11.9987V20.3709ZM5.87048 20.6401L5.87048 9.79049H3.87048L3.87048 20.6401H5.87048ZM5.4652 8.67752L2.86032 5.57506L1.32862 6.8611L3.9335 9.96356L5.4652 8.67752ZM1.92334 2.66504L15.6767 2.66504V0.665039L1.92334 0.665039L1.92334 2.66504ZM2.92334 5.74812L2.92334 1.66504L0.92334 1.66504L0.92334 5.74812H2.92334ZM2.86032 5.57506C2.90103 5.62354 2.92334 5.68482 2.92334 5.74812H0.92334C0.92334 6.15523 1.06684 6.54931 1.32862 6.8611L2.86032 5.57506ZM5.87048 9.79049C5.87048 9.38338 5.72698 8.9893 5.4652 8.67752L3.9335 9.96356C3.89279 9.91508 3.87048 9.8538 3.87048 9.79049H5.87048ZM5.60134 20.3709C5.74998 20.3709 5.87048 20.4914 5.87048 20.6401H3.87048C3.87048 21.596 4.64541 22.3709 5.60134 22.3709L5.60134 20.3709ZM11.7295 20.6401C11.7295 20.4914 11.85 20.3709 11.9987 20.3709V22.3709C12.9546 22.3709 13.7295 21.596 13.7295 20.6401H11.7295ZM12.1348 8.67751C11.873 8.9893 11.7295 9.38338 11.7295 9.79049H13.7295C13.7295 9.8538 13.7072 9.91508 13.6665 9.96356L12.1348 8.67751ZM14.6767 5.74813C14.6767 5.68482 14.699 5.62354 14.7397 5.57506L16.2714 6.8611C16.5332 6.54931 16.6767 6.15523 16.6767 5.74813H14.6767Z"
            fill="white"
          />
          <path
            d="M7.59424 9.20605H10.0041V12.1751C10.0041 12.377 9.84053 12.5406 9.63871 12.5406H7.95967C7.75785 12.5406 7.59424 12.377 7.59424 12.1751V9.20605Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
  </div>

  <div class="flex w-full mb-3 justify-center flex-wrap text-center" v-else>
    <div
      class="w-full px-10 mt-5 dark:text-white text-black font-fira text-[20px]"
    >
      {{ error }}
    </div>
    <div class="mt-20">
      <no-camera />
    </div>
    <div class="w-8/12 mt-20">
      <tertiary @click="find_by_id">Enter ID instead</tertiary>
    </div>
  </div>
</template>

<script>
import { useForm } from "@inertiajs/vue3";
import ModalWindow from "@/Components/ModalWindow.vue";
import NoCamera from "@/Components/SVG/NoCamera.vue";
import Tertiary from "@/UI/Buttons/Tertiary.vue";
import jsQR from 'jsqr';
export default {
  components: {
    ModalWindow,
    NoCamera,
    Tertiary,
  },

  name: "Camera",
  data() {
    return {
      error: null,
      read: false,
      form: useForm({
        qr_id: null,
      }),
      stream: null,
      torchOn: false,
      qrCodeFound: false
    };
  },
  mounted() {
    this.initCamera();
  },
  methods: {
    submit_form() {
      this.$inertia.get(route("inspection.index") + "/" + this.form.qr_id);
    },
    onDecode(decodedString) {
      this.read = true;
      console.log(decodedString);
      const split = decodedString.split("/");
      this.form.qr_id = split.at(-1);
      this.submit_form();
    },
    find_by_id() {
      this.$inertia.get(route("find_by_qr"));
    },
    redirect_to_history() {
      this.$inertia.get(route("user.catch_history"));
    },
    async initCamera() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
                const cameras = devices.filter((device) => device.kind === 'videoinput');

                if (cameras.length === 0) {
                    throw 'No camera found on this device.';
                }
                this.camerass = cameras;
                const camera = cameras[cameras.length - 1];
                this.stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        deviceId: camera.deviceId,
                        facingMode: 'environment',
                        height: { ideal: 600 },
                        width: { ideal: 400 },
                        //torch: true, // Try to enable torch initially
                    },
                });
        this.$refs.video.srcObject = this.stream;
        this.$refs.video.play();
        this.scanQRCode();
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    },
    async scanQRCode() {
      const video = this.$refs.video;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d', { willReadFrequently: true });

      video.addEventListener('loadedmetadata', () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const scan = () => {
          if (!this.qrCodeFound && !video.paused && !video.ended) {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);

            if (code) {
              this.qrCodeFound = true;
              console.log('QR code detected:', code.data);
            this.onDecode(code.data);
              this.stopCamera();
            }
          }
          requestAnimationFrame(scan);
        };
        scan();
      });
    },
    toggleTorch() {
      const track = this.stream.getVideoTracks()[0];
      const capabilities = track.getCapabilities();

      if (capabilities.torch) {
        track.applyConstraints({
          advanced: [{ torch: !this.torchOn }]
        }).then(() => {
          this.torchOn = !this.torchOn;
        }).catch((error) => {
          console.error('Error toggling torch:', error);
        });
      } else {
        console.warn('Torch not supported on this device');
      }
    },
    stopCamera() {
        this.stopCamera();
    }
  },
  beforeUnmount() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  },
};
</script>

<style>
video {
  object-fit: cover;
}
</style>
