<template>
    <span class="
            text-[18px]
            font-fira
            font-normal
            dark:text-t_white-300
            text-t_black-800
            tracking-[1%]
            leading-[140%]
    ">
       <slot/>
    </span>
</template>

<script>
export default {
    props:{

    },
    name: "SmallTitle"
}
</script>

<style scoped>

</style>
