<script>
import {Link} from '@inertiajs/vue3'
import TitleText from '@/UI/Text/TitleText.vue';
import Panel from "@/Components/Panel.vue"
import PayChek from "@/Components/SVG/PayChek.vue";
import PayCopy from "@/Components/SVG/PayCopy.vue";

export default {
    components: {
        Link,
        TitleText,
        Panel, PayCopy, PayChek
    },
    name: "BeforeDonation",
    props: {
    },
    data() {
        return {

        }
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<template>
    <div class="bg-gradient-to-b from-t_black-900 to-t_purple-700 h-full min-h-screen w-full">
        <div class="w-full">
            <div class="w-full flex flex-wrap justify-between bg-t_black-900 py-[10px] px-[15px] items-center">
                <Link class="w-1/12" :href="route('user.settings.index')">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14.8487 17.6485C15.3174 17.1799 15.3174 16.4201 14.8487 15.9515L10.8973 12L14.8487 8.04853C15.3174 7.5799 15.3174 6.8201 14.8487 6.35147C14.3801 5.88284 13.6203 5.88284 13.1517 6.35147L8.35167 11.1515C7.88304 11.6201 7.88304 12.3799 8.35167 12.8485L13.1517 17.6485C13.6203 18.1172 14.3801 18.1172 14.8487 17.6485Z"
                              fill="white"/>
                    </svg>
                </Link>
                <span class="text-[18px] w-10/12 text-center text-t_white font-bold font-fira">Join TrapScan</span>
                <Link class="w-1/12 justify-end" :href="route('scan')">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M0.292893 0.292893C0.683417 -0.0976312 1.31658 -0.0976313 1.70711 0.292893L6 4.58579L10.2929 0.292891C10.6834 -0.097633 11.3166 -0.0976331 11.7071 0.292891C12.0976 0.683415 12.0976 1.31658 11.7071 1.7071L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.68342 12.0976 0.292895 11.7071C-0.0976291 11.3166 -0.0976292 10.6834 0.292895 10.2929L4.58579 6L0.292894 1.70711C-0.0976309 1.31658 -0.097631 0.683418 0.292893 0.292893Z"
                              fill="white"/>
                    </svg>
                </Link>
            </div>
            <div class=" pt-[32px] px-[24px] w-full flex flex-wrap">
                <title-text color="text-t_white " text="text-[28px] mb-[24px]">We also accept donations</title-text>
                <p class="w-full text-[16px] font-fira text-t_white-200 leading-[140%]">
                    Contribute what you can to help make TrapScan better for everyone.
                </p>
                <p class="w-full mt-[10px] mb-[24px] text-[16px] font-fira text-t_white-200 leading-[140%]">
                    You can do this anytime from within your account.
                </p>
                <Link :href="route('donation')" class="w-full">

                <panel type_panel="1" text="Donate to TrapScan">
                        <template #svg>
                            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.44701 11.9087L0.487615 3.00011C0.305232 2.58975 0.394414 2.10944 0.711951 1.7919L0.727535 1.77632L6.00664 0.720499C6.10308 0.701211 6.20295 0.708495 6.29557 0.741575L13.4254 3.28793C13.6373 3.36361 13.8204 3.50329 13.9495 3.68763L17.1978 8.32819C17.4162 8.64005 17.4551 9.04348 17.3005 9.39135L15.3791 13.7146L15.1207 12.9396C14.9387 12.3935 14.5471 11.942 14.0322 11.6846L12.6658 11.0014L13.0848 9.74445C13.1586 9.52287 13.0824 9.27888 12.8956 9.13873L11.1826 7.85399C11.0886 7.78354 10.9744 7.74546 10.857 7.74546H7.78197L9.32862 10.3232C9.38171 10.4117 9.45895 10.4832 9.55125 10.5294L13.2416 12.3746C13.5723 12.5399 13.8405 12.8081 14.0058 13.1388C14.4529 14.033 14.0403 15.1183 13.1121 15.4896L12.9165 15.5679C12.4119 15.7697 11.845 15.7461 11.3589 15.5031L4.70021 12.1737C4.58763 12.1174 4.49813 12.0238 4.44701 11.9087Z" fill="#2E526B"/>
                                <path d="M14.5965 17.0497C14.5965 19.4473 12.6529 21.3909 10.2553 21.3909C7.85772 21.3909 5.9141 19.4473 5.9141 17.0497C5.9141 16.1411 6.19327 15.2976 6.67053 14.6004C6.67053 14.6004 10.7979 17.0487 11.8832 17.0487C13.5112 17.0487 14.4081 15.7809 14.4081 15.7809C14.5306 16.1822 14.5965 16.6083 14.5965 17.0497Z" fill="#FFB947"/>
                            </svg>
                        </template>
                    </panel>
                </Link>

                <Link :href="route('user.settings.index')" class="w-full">
                    <panel type_panel="1" text="Maybe later">
                    </panel>
                </Link>
            </div>
        </div>
    </div>
</template>
