<template>
    <BreezeAuthenticatedLayout>
        <div class="w-full overflow-hidden p-4">
            <div class="mb-10 ">
                <div class="w-full bg-white dark:bg-mirage-400 rounded-xl dark:text-white p-6 flex flex-col mt-6">
                    <div class="flex flex-wrap justify-start w-full gap-y-[12px]">
                        <p class="font-fira dark:text-white text-light text-[18px] leading-[134%]">
                            TrapScan is a mobile web application designed by conservation volunteers to simplify their record keeping and reporting workload.
                        </p>
                        <p class="font-fira dark:text-white text-light text-[18px] leading-[134%]">
                            TrapScan is developed iteratively based on user input, so please contact us if you have any issues, questions, feedback or ideas.
                             Joining our <a class="text-t_purple underline dark:text-t_purple-200" href="https://www.facebook.com/trapscan">Facebook community</a> and messaging us there is best, but you can also email us at
                            <a class="text-t_purple underline dark:text-t_purple-200" href="mail://team@trapscan.app">team@trapscan.app</a>
                        </p>
                    </div>

                </div>


            </div>
        </div>

    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import { Link } from '@inertiajs/vue3'
export default {
    components:{
        Link,
        BreezeAuthenticatedLayout
    },
    name: "About",
    props:{
        projects:Object,
    },
    data() {
        return {
            usr_s:null
        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>
<style scoped>

</style>
