<script>
import { ref, onMounted, watch, reactive } from 'vue';
import Show from "@/Layouts/Show.vue";
import { useForm, router, Link } from "@inertiajs/vue3";
import Primary from "@/UI/Buttons/Primary.vue";

export default {
    components: {
        Show,
        Primary,
        Link
    },
    name: "CreateProject",
    setup(props) {
        const step = ref(0);
        const form = useForm({
            latitude: null,
            longitude: null,
            description: '',
            name: '',
            type: 1
        });
        const description_error = reactive({
            error: false,
            count_words: 0
        });
        const back = () => {
            if (step.value === 0) {
                router.visit(route('user.projects.index'));
            } else {
                step.value--;
            }
        };
        watch(form, (newForm) => {

            if (newForm.description.length > 500) {
                description_error.error = true;
                description_error.count_words = newForm.description.length - 500
            } else {
                description_error.error = false;
            }
        })
        function getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        form.latitude = position.coords.latitude;
                        form.longitude = position.coords.longitude;
                        step.value++;
                    },
                    error => {
                        console.log(error.message)
                    }
                )
            } else {
                console.log("Geolocation is not supported by this browser.")
            }
        }

        const goToScan = (view = null) => {
            if(view !== null){
                router.visit(route(view));
            }
            router.visit(route('user.projects.index'));
        };

        const subbmit = () =>{
            form.post(route('user.projects.store_new_project'), {
                preserveScroll: true,
                onSuccess: () => {
                    step.value = 3
                },
            })
        }

        onMounted(() => {

        });

        return {
            step,
            back,
            goToScan,
            getLocation,
            form,
            description_error,
            subbmit
        };
    }
};
</script>

<template >
    <Show :type="1" v-if="step !== 3">
        <template #header>
            <div @click="back" class="flex items-center w-2/12 h-full">
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7.39316 12.0653C7.86179 11.5966 7.86179 10.8368 7.39316 10.3682L3.44168 6.41675L7.39316 2.46528C7.86179 1.99665 7.86179 1.23685 7.39316 0.76822C6.92453 0.299591 6.16473 0.299591 5.6961 0.76822L0.896099 5.56822C0.427469 6.03685 0.427469 6.79665 0.896099 7.26528L5.6961 12.0653C6.16473 12.5339 6.92453 12.5339 7.39316 12.0653Z"
                        fill="white" />
                </svg>
            </div>
            <div class="flex items-center justify-center w-8/12 h-full">
                <h1 class="text-white font-bold ml-1 text-[16px] montserrat tracking-[.05em]">Create Project</h1>
            </div>
            <div @click="goToScan()" class="flex items-center justify-end w-2/12 h-full">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.837327 0.709641C1.22785 0.319117 1.86102 0.319117 2.25154 0.709641L6.54443 5.00253L10.8373 0.709639C11.2279 0.319115 11.861 0.319115 12.2515 0.709639C12.6421 1.10016 12.6421 1.73333 12.2515 2.12385L7.95865 6.41675L12.2515 10.7096C12.6421 11.1002 12.6421 11.7333 12.2515 12.1239C11.861 12.5144 11.2279 12.5144 10.8373 12.1239L6.54443 7.83096L2.25154 12.1239C1.86102 12.5144 1.22785 12.5144 0.837329 12.1239C0.446804 11.7333 0.446804 11.1002 0.837329 10.7096L5.13022 6.41675L0.837327 2.12385C0.446803 1.73333 0.446803 1.10017 0.837327 0.709641Z"
                        fill="white" />
                </svg>
            </div>
        </template>
        <div class="px-[14px] w-full">
            <template v-if="step === 0">
                <span class="w-full  text-[24px]  text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                    Set Project location
                </span>
                <p class="text-[16px]  text-t_black-200 font-[400] dark:text-t_white-200 font-fira mt-[8px] mb-[32px]">
                    Your current location will be saved as the Project’s location.
                </p>
                <p class="text-[16px]  text-t_black-200 font-[400] dark:text-t_white-200 font-fira mt-[8px] mb-[32px]">
                    This may be used for future features such as “view Projects near me” to help more people find out about
                    your cause, but the specific location will not be shared.
                </p>
                <primary @click="getLocation()">
                    Save location
                </primary>
                <primary class="mt-[32px]" color="border-[2px] border-t_purple-700 dark:border-t_purple-500 text-t_purple-700  dark:text-t_purple-500"
                    @click="step = 1">
                    Skip
                </primary>
            </template>
            <template v-if="step === 1">
                <span class="w-full  text-[24px]  text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                    Name your Project
                </span>
                <div class="flex flex-wrap w-full mb-5 mt-[32px]">
                    <label class="w-full mb-2 font-fira text-[18px] text-t_black-800 dark:text-t_white-200">
                        Project name
                    </label>
                    <input type="text" maxlength="25"
                        class="
                            border-[3.5px]
                            dark:bg-dark_input_bg w-full
                            px-[12px] py-[8px]
                            dark:text-t_white-200
                            font-fira
                            focus:border-t_purple-500
                            dark:focus:border-t_purple-500
                            rounded-[0.225em]
                            shadow-sm mb-[32px]" v-model="form.name" />
                    <primary @click="step = 2">
                        Save
                    </primary>
                </div>
            </template>
            <template v-if="step === 2">
                <span class="w-full  text-[24px]  text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                    Describe your Project
                </span>
                <div class="flex flex-wrap w-full mb-5 mt-[32px]">

                    <textarea type="text" rows="7"
                        class="
                            border-[3.5px]
                            dark:bg-dark_input_bg w-full
                            px-[12px] py-[8px]
                            dark:text-t_white-200
                            font-fira
                            focus:border-t_purple-500
                            dark:focus:border-t_purple-500
                            rounded-[0.225em]
                            shadow-sm mb-[18px]" v-model="form.description" />

                    <p class="w-full text-t_warms-500 font-fira text-[18px]" v-if="description_error.error">
                        500 character limit (remove {{ description_error.count_words }})
                    </p>
                    <primary class="mt-[32px]" @click="subbmit()">
                        Save
                    </primary>
                    <primary class="mt-[32px] " color="border-[2px] border-t_purple-700 dark:border-t_purple-500 text-t_purple-700 dark:text-t_purple-500"
                        @click="subbmit()">
                        Skip
                    </primary>
                </div>
            </template>
        </div>
    </Show>

    <template v-else>
        <div class="min-h-screen h-full bg-t_blue_gray-100  dark:bg-t_black-900 ">
        <nav class="bg-t_blue-600 h-[44px] flex items-center justify-between flex-wrap w-full shadow-2xl px-4">
            <div></div>
            <div>
                <h1 class="text-white font-bold ml-1 text-[16px] font-fira tracking-wide">Create Project</h1>
            </div>
            <div>
                <Link :href="route('scan')">
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.837327 0.709641C1.22785 0.319117 1.86102 0.319117 2.25154 0.709641L6.54443 5.00253L10.8373 0.709639C11.2279 0.319115 11.861 0.319115 12.2515 0.709639C12.6421 1.10016 12.6421 1.73333 12.2515 2.12385L7.95865 6.41675L12.2515 10.7096C12.6421 11.1002 12.6421 11.7333 12.2515 12.1239C11.861 12.5144 11.2279 12.5144 10.8373 12.1239L6.54443 7.83096L2.25154 12.1239C1.86102 12.5144 1.22785 12.5144 0.837329 12.1239C0.446804 11.7333 0.446804 11.1002 0.837329 10.7096L5.13022 6.41675L0.837327 2.12385C0.446803 1.73333 0.446803 1.10017 0.837327 0.709641Z" fill="white"/>
                    </svg>
                </Link>
            </div>
        </nav>
        <main class="h-full w-full flex flex-wrap  mx-auto justify-center overflow-auto">
            <div class="h-[116px] justify-center flex items-center w-full bg-t_blue-800">
                <svg width="75" height="54" viewBox="0 0 75 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.55371 26.8481L27.6313 46.9258L67.4828 7.07432" stroke="#BBD6FB" stroke-width="10" stroke-linecap="square"/>
                </svg>
            </div>
            <div class="flex justify-center flex-col w-full bg-light_menu_bar_bg dark:bg-t_black-800 h-[50px]">
                <h1 class="text-[18px] font-fira font-semibold text-black  text-center dark:text-t_white-150">Project Created</h1>
            </div>
            <div class="flex justify-center flex-col w-full mt-6">
                <div class="flex flex-wrap mt-[32px] ml-2 px-4 text-t_black-800 dark:text-t_white-150 font-fira text-[18px]">
                    <span class="w-full">Project name</span>
                    <span class="w-full font-bold capitalize">{{ form.name }}</span>
                </div>
                <div class="flex flex-wrap mt-[32px] mx-[24px] p-[16px]  bg-t_warms-50 dark:bg-t_purple-800 text-t_black-800 dark:text-t_white-200 font-fira text-[16px] leading-6">
                    You can now add Traps to this Project 👍
                </div>
                <div class="flex flex-wrap mt-[32px] mx-[24px] text-t_black-800 dark:text-t_white-150 font-fira text-[18px]">
                    <span class="w-full">When people create a new Trap, they can select this Project.   </span>
                    <span class="w-full mt-[8px]">
                        To add an existing Trap of yours, go to <span class="font-bold">Me → My Traps</span> then select a trap and edit the Project it belongs to.
                    </span>
                </div>
                <div class="flex flex-wrap mt-[32px] ml-2 px-4 text-t_black-800 dark:text-t_white-150 font-fira text-[18px]">
                    <primary class="mt-[32px] mx-[24px] w-full" @click="goToScan()">
                            finish
                    </primary>
                    <!-- <primary class="mt-[32px] mx-[24px]" color="border-[2px] border-t_purple-700 dark:border-t_white-200"
                        @click="goToScan('view')">
                            View project
                    </primary> -->
                </div>

            </div>

        </main>

    </div>
    </template>
</template>
