<script>
import {Link} from '@inertiajs/vue3'
import TitleText from '@/UI/Text/TitleText.vue';
import Panel from "@/Components/Panel.vue"
import PayChek from "@/Components/SVG/PayChek.vue";
import PayCopy from "@/Components/SVG/PayCopy.vue";

export default {
    components: {
        Link,
        TitleText,
        Panel, PayCopy, PayChek
    },
    name: "Donation",
    props: {
    },
    data() {
        return {
            banking: false,
            items: [
                { value: 'TrapScan', isCopied: false, name:'Payee Name' },
                { value: '02-0576-0066395-21', isCopied: false, name:'account number' },
            ]
        }
    },
    mounted() {
    },
    methods: {
        copyToClipboard(item) {
            navigator.clipboard.writeText(this.items[item].value);
            this.items[item].isCopied = true;
            setTimeout(() => {
                this.items[item].isCopied = false;
            }, 1000);
        },
    }
}
</script>

<template>
    <div class="bg-gradient-to-b from-t_black-900 to-t_purple-700 h-full min-h-screen w-full">
        <div class="w-full" v-if="!banking">
            <div class="w-full flex flex-wrap justify-between bg-t_black-900 py-[10px] px-[15px] items-center">
                <Link class="w-1/12" :href="route('user.settings.index')">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14.8487 17.6485C15.3174 17.1799 15.3174 16.4201 14.8487 15.9515L10.8973 12L14.8487 8.04853C15.3174 7.5799 15.3174 6.8201 14.8487 6.35147C14.3801 5.88284 13.6203 5.88284 13.1517 6.35147L8.35167 11.1515C7.88304 11.6201 7.88304 12.3799 8.35167 12.8485L13.1517 17.6485C13.6203 18.1172 14.3801 18.1172 14.8487 17.6485Z"
                              fill="white"/>
                    </svg>
                </Link>
                <span class="text-[18px] w-10/12 text-center text-t_white font-bold font-fira">Donate to TrapScan</span>
                <Link class="w-1/12 justify-end" :href="route('scan')">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M0.292893 0.292893C0.683417 -0.0976312 1.31658 -0.0976313 1.70711 0.292893L6 4.58579L10.2929 0.292891C10.6834 -0.097633 11.3166 -0.0976331 11.7071 0.292891C12.0976 0.683415 12.0976 1.31658 11.7071 1.7071L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.68342 12.0976 0.292895 11.7071C-0.0976291 11.3166 -0.0976292 10.6834 0.292895 10.2929L4.58579 6L0.292894 1.70711C-0.0976309 1.31658 -0.097631 0.683418 0.292893 0.292893Z"
                              fill="white"/>
                    </svg>
                </Link>
            </div>
            <div class=" pt-[32px] px-[24px] w-full flex flex-wrap">
                <p class="w-full mt-[24px] text-[16px] font-fira text-t_white-200 leading-[140%]">
                    Contribute what you can to help make TrapScan better for everyone.
                </p>
                <title-text color="text-t_white " text="text-[28px] mb-[24px]">Select payment method</title-text>
                <a href="https://donate.stripe.com/fZe7vj6WI0KM3Kw6ou" class="w-full" target="_blank">
                    <panel type_panel="1" text="Card payment">
                        <template #svg>
                            <svg width="31" height="23" viewBox="0 0 31 23" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.05859 0C1.98753 0 0.308594 1.67893 0.308594 3.75V5.625H30.3086V3.75C30.3086 1.67893 28.6297 0 26.5586 0H4.05859Z"
                                    fill="#2F45C5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M30.3086 9.375H0.308594V18.75C0.308594 20.8211 1.98753 22.5 4.05859 22.5H26.5586C28.6297 22.5 30.3086 20.8211 30.3086 18.75V9.375ZM4.05859 16.875C4.05859 15.8395 4.89806 15 5.93359 15H7.80859C8.84413 15 9.68359 15.8395 9.68359 16.875C9.68359 17.9105 8.84413 18.75 7.80859 18.75H5.93359C4.89806 18.75 4.05859 17.9105 4.05859 16.875ZM13.4336 15C12.3981 15 11.5586 15.8395 11.5586 16.875C11.5586 17.9105 12.3981 18.75 13.4336 18.75H15.3086C16.3441 18.75 17.1836 17.9105 17.1836 16.875C17.1836 15.8395 16.3441 15 15.3086 15H13.4336Z"
                                      fill="#2F45C5"/>
                            </svg>
                        </template>
                    </panel>
                </a>
                <panel type_panel="1" text="Bank transfer" @click="banking = !banking">
                    <template #svg>
                        <svg width="18" height="31" viewBox="0 0 18 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.45987 13.1925C5.6691 12.2073 4.45005 11.1886 4.45005 9.60216C4.45005 7.78193 6.13669 6.51277 8.95889 6.51277C11.9314 6.51277 13.0335 7.93222 13.1337 10.0196H16.8243C16.7074 7.14735 14.954 4.50884 11.4638 3.65717V0H6.45398V3.60707C3.21429 4.30845 0.609183 6.41257 0.609183 9.63556C0.609183 13.4931 3.79877 15.4136 8.45791 16.5324C12.6328 17.5344 13.4677 19.0039 13.4677 20.557C13.4677 21.7092 12.6495 23.5462 8.95889 23.5462C5.51881 23.5462 4.16616 22.0098 3.98246 20.0393H0.308594C0.508987 23.6965 3.24769 25.7505 6.45398 26.4352V30.0589H11.4638V26.4686C14.7202 25.8507 17.3086 23.9637 17.3086 20.5403C17.3086 15.7976 13.2506 14.1778 9.45987 13.1925Z"
                                fill="#2F45C5"/>
                        </svg>
                    </template>
                </panel>
            </div>
        </div>
        <div class="w-full" v-if="banking">
            <div class="w-full flex flex-wrap justify-between bg-t_black-900 py-[10px] px-[15px] items-center">
                <div class="w-1/12" @click="banking = !banking">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14.8487 17.6485C15.3174 17.1799 15.3174 16.4201 14.8487 15.9515L10.8973 12L14.8487 8.04853C15.3174 7.5799 15.3174 6.8201 14.8487 6.35147C14.3801 5.88284 13.6203 5.88284 13.1517 6.35147L8.35167 11.1515C7.88304 11.6201 7.88304 12.3799 8.35167 12.8485L13.1517 17.6485C13.6203 18.1172 14.3801 18.1172 14.8487 17.6485Z"
                              fill="white"/>
                    </svg>
                </div>
                <span class="text-[18px] w-10/12 text-center text-t_white font-bold font-fira">Join TrapScan</span>
                <Link class="w-1/12 justify-end" :href="route('scan')">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M0.292893 0.292893C0.683417 -0.0976312 1.31658 -0.0976313 1.70711 0.292893L6 4.58579L10.2929 0.292891C10.6834 -0.097633 11.3166 -0.0976331 11.7071 0.292891C12.0976 0.683415 12.0976 1.31658 11.7071 1.7071L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.68342 12.0976 0.292895 11.7071C-0.0976291 11.3166 -0.0976292 10.6834 0.292895 10.2929L4.58579 6L0.292894 1.70711C-0.0976309 1.31658 -0.097631 0.683418 0.292893 0.292893Z"
                              fill="white"/>
                    </svg>
                </Link>
            </div>
            <div class="pt-[32px] px-[24px] w-full flex flex-wrap">
                <title-text color="text-t_white " text="text-[28px] mb-[24px]">Donation payment instructions</title-text>
                <div class="w-full flex flex-wrap gap-y-[8px] mt-[24px]" v-for="(item, index) in items" :key="index" >
                      <span class="montserrat font-semibold text-[12px] tracking-[0.105em] leading-[120%] text-t_white-200">
                        {{item.name}}
                      </span>
                      <div class="relative w-full">
                                        <input @click="copyToClipboard(index)"
                                            readonly
                                            :value="item.value"
                                            type="text"
                                            class="block w-full pr-10 pl-[16px] py-[10px] rounded-[4px] text-t_white font-bold  border-t_purple-700 bg-t_purple-700"
                                        />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none z-40" >
                          <span
                              :class="{ 'text-t_green': item.isCopied }"

                              class="text-t_blue_gray-300 leading-[140%] font-fira text-[15px] mr-[10px] cursor-pointer"
                          >
                            {{ item.isCopied ? 'Copied' : 'Copy' }}
                          </span>
                            <!-- Assuming pay-copy component is used for each input -->
                            <pay-copy v-if="!item.isCopied" />
                            <pay-chek v-else />
                        </div>
                    </div>
                </div>
                <p class="w-full mt-[24px] text-[16px] font-bold text-center font-fira text-t_white-200 leading-[140%]">
                    Thank you!
                </p>
                <div class="w-full mt-[24px]">
                    <Link :href="route('user.settings.index')" class="flex w-full h-[50px] items-center justify-center text-white bg-t_green-800 rounded-[40px]">
                        <span class="font-bold  uppercase montserrat">Payment has been made</span>
                    </Link>
                </div>
                <div
                    class="w-full flex flex-wrap text-center mt-[24px] text-t_warms-300 font-fira text-[18px] leading-[140%] mb-[40px]">
                    <span class="w-full">Your support means a lot to us 🙏</span>
                    <span class="w-full mt-[15px]">It allows us to continue helping trappers across the country. </span>
                </div>

            </div>
        </div>
    </div>
</template>
