<template>
    <BreezeAuthenticatedLayout>
        <div class="flex flex-wrap mt-[15px] mb-[8px] px-[24px]">
            <Link :href="route('user.settings.index')" class="w-full flex flex-wrap justify-start items-center ">
                <BlueCircle/>
                <span class="font-bold montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">Back - </span>
                <span  class=" montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">
                ME
              </span>
            </Link>
            <div class="w-full mt-[16px]">
                <span class="text-[24px] mb-[40px] mfont-bold text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                Edit Profile
                </span>
            </div>
            <div class="mt-[16px] w-full">
                <ts-input v-model="form.username" label="User name"/>
                <div class="flex flex-col">
                    <BreezeValidationErrors :errors="$page.props.errors" class="mb-4" />
                </div>
                <primary @click="submit" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    save changes
                </primary>
            </div>
        </div>
    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import { Link, useForm } from '@inertiajs/vue3'
import Primary from "@/UI/Buttons/Primary.vue";
import TsInput from "@/Components/Input.vue";
import BreezeValidationErrors from '@/Components/ValidationErrors.vue';
import BlueCircle from "@/Components/SVG/BlueCircle.vue";

export default {
    components:{
        Link,
        BreezeAuthenticatedLayout,
        Primary,
        TsInput,
        BreezeValidationErrors,
        BlueCircle
    },
    name: "UserIndex",
    props:{
        projects:Object,
    },
    data() {
        return {
            form: useForm({
                username: this.$page.props.auth.user.name
            })
        }
    },
    mounted() {
    },
    methods: {
        submit(){
            this.form.put(route('user.settings.update'))
        }
    }
}
</script>
<style scoped>

</style>
