<template>
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M35.8862 39.6174C36.1031 39.7426 36.3703 39.7426 36.5872 39.6174L41.1194 37.0008C41.3362 36.8756 41.4698 36.6442 41.4698 36.3937V31.1605C41.4698 30.91 41.3362 30.6786 41.1194 30.5534L33.4474 26.124C33.2305 25.9988 32.9633 25.9988 32.7464 26.124L28.2143 28.7406C27.9974 28.8658 27.8638 29.0972 27.8638 29.3477V34.5809C27.8638 34.8314 27.9974 35.0628 28.2143 35.188L35.8862 39.6174ZM39.8999 31.6622L33.0969 27.7344L29.4337 29.8494V34.0792L35.9751 37.8559V33.626L39.6383 31.5111L39.8999 31.6622V32.2664L36.76 34.0792L39.8999 35.892V31.6622Z"
            fill="#60859F" />
        <path
            d="M30.3462 15.6888C30.1337 15.6888 29.9613 15.8612 29.9613 16.0737C29.9613 16.2863 30.1337 16.4586 30.3462 16.4586L30.3462 15.6888ZM33.0887 24.2027C33.239 24.353 33.4827 24.353 33.633 24.2027L36.0824 21.7533C36.2327 21.603 36.2327 21.3593 36.0824 21.209C35.9321 21.0586 35.6884 21.0586 35.5381 21.209L33.3608 23.3862L31.1836 21.209C31.0332 21.0586 30.7895 21.0586 30.6392 21.209C30.4889 21.3593 30.4889 21.603 30.6392 21.7533L33.0887 24.2027ZM30.3462 16.4586L31.0515 16.4586L31.0515 15.6888L30.3462 15.6888L30.3462 16.4586ZM32.9759 18.3831L32.9759 23.9306L33.7457 23.9306L33.7457 18.3831L32.9759 18.3831ZM31.0515 16.4586C32.1143 16.4586 32.9759 17.3202 32.9759 18.3831L33.7457 18.3831C33.7457 16.8951 32.5395 15.6888 31.0515 15.6888L31.0515 16.4586Z"
            fill="#60859F" />
        <g clip-path="url(#clip0_3819_9780)">
            <rect x="11.0303" y="3.24683" width="18.0071" height="26.4105" rx="0.983661"
                transform="rotate(9.7611 11.0303 3.24683)" fill="white" stroke="#00BF8C" stroke-width="2" />
            <path
                d="M18.6815 11.126L18.5593 11.8367M21.344 20.3652L22.7206 20.602M18.5909 19.8916L17.2143 19.6547L16.7252 22.4977M18.0702 14.6796L17.7034 16.8118M17.7034 16.8118L17.7103 16.813M17.7034 16.8118L20.4634 17.2866M19.4784 22.9713L22.2315 23.4449M12.1971 15.8646L14.9502 16.3382M23.2097 17.7591L23.2165 17.7602M21.6339 14.5609L23.6987 14.9161L24.1878 12.073L21.4416 11.6008C21.4347 11.5995 21.4416 11.6003 21.4347 11.5995L21.0679 13.7318C21.0003 14.1243 21.2537 14.4955 21.6339 14.5609ZM14.7513 13.3771L12.6865 13.0218L13.1756 10.1788L15.9218 10.6514C15.9287 10.6525 15.9219 10.6509 15.9287 10.6525L15.5619 12.7847C15.4943 13.1773 15.1314 13.4425 14.7513 13.3771ZM13.7801 19.0637L11.7153 18.7085L11.2262 21.5516L13.9724 22.0239C13.9793 22.0251 13.9724 22.0243 13.9793 22.0251L14.3461 19.8929C14.4137 19.5003 14.1602 19.1291 13.7801 19.0637Z"
                stroke="#1A2B3D" stroke-width="1.39766" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_3819_9780">
            <rect width="28.8114" height="29.7511" fill="white" transform="translate(5.91016 1.14771) rotate(9.7611)" />
        </clipPath>
    </defs>
</svg>
</template>
