<template>
    <svg class="my-auto" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2375_2775)">
            <path d="M25.9861 13.2434C25.9861 12.1781 25.8977 11.4008 25.7062 10.5947H13.2588V15.4025H20.5652C20.4179 16.5974 19.6224 18.3967 17.8547 19.6058L17.83 19.7668L21.7656 22.7462L22.0383 22.7727C24.5424 20.5127 25.9861 17.1875 25.9861 13.2434Z" fill="#4285F4"/>
            <path d="M13.259 25.9106C16.8385 25.9106 19.8436 24.759 22.0385 22.7726L17.855 19.6056C16.7355 20.3686 15.2329 20.9012 13.259 20.9012C9.75315 20.9012 6.77758 18.6412 5.71687 15.5176L5.56139 15.5305L1.46905 18.6254L1.41553 18.7708C3.59565 23.0028 8.07379 25.9106 13.259 25.9106Z" fill="#34A853"/>
            <path d="M5.71691 15.5175C5.43704 14.7114 5.27506 13.8477 5.27506 12.9552C5.27506 12.0627 5.43704 11.199 5.70219 10.393L5.69478 10.2213L1.55115 7.07666L1.41558 7.13968C0.517045 8.89587 0.00146484 10.868 0.00146484 12.9552C0.00146484 15.0425 0.517045 17.0145 1.41558 18.7707L5.71691 15.5175Z" fill="#FBBC05"/>
            <path d="M13.259 5.00937C15.7485 5.00937 17.4277 6.06018 18.3853 6.93833L22.1269 3.36839C19.8289 1.28114 16.8385 0 13.259 0C8.07379 0 3.59565 2.90773 1.41553 7.13978L5.70214 10.3931C6.77758 7.26938 9.75315 5.00937 13.259 5.00937Z" fill="#EB4335"/>
        </g>
        <defs>
            <clipPath id="clip0_2375_2775">
                <rect width="26" height="26" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

