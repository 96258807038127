<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3006_12278)">
            <path d="M12.6543 3.5C7.6843 3.5 3.6543 7.53 3.6543 12.5H0.654297L4.5443 16.39L4.6143 16.53L8.6543 12.5H5.6543C5.6543 8.63 8.7843 5.5 12.6543 5.5C16.5243 5.5 19.6543 8.63 19.6543 12.5C19.6543 16.37 16.5243 19.5 12.6543 19.5C10.7243 19.5 8.9743 18.71 7.7143 17.44L6.2943 18.86C7.9243 20.49 10.1643 21.5 12.6543 21.5C17.6243 21.5 21.6543 17.47 21.6543 12.5C21.6543 7.53 17.6243 3.5 12.6543 3.5ZM11.6543 8.5V13.5L15.9343 16.04L16.6543 14.83L13.1543 12.75V8.5H11.6543Z" fill="#EEF6FB"/>
        </g>
        <defs>
            <clipPath id="clip0_3006_12278">
                <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "QrCode"
}
</script>

<style scoped>

</style>
