<script >
import BreezeGuestLayout from '@/Layouts/Guest.vue';
import { Link } from '@inertiajs/vue3'
import BillingImg from "@/Components/SVG/BillingImg.vue"
import TitleText from '@/UI/Text/TitleText.vue';
import Predator from "@/Components/SVG/Sponsors/2050.vue"
import Apvcc from "@/Components/SVG/Sponsors/APVCC.vue"
import Clare from "@/Components/SVG/Sponsors/Clare.vue"
import Primary from '@/UI/Buttons/Primary.vue';
import StepOne from '@/Components/SVG/Billing/StepOne.vue';
import StepTwo from '@/Components/SVG/Billing/StepTwo.vue';
import StepThree from '@/Components/SVG/Billing/StepThree.vue';
import StepFour from '@/Components/SVG/Billing/StepFour.vue';
import Line from '@/Components/SVG/Billing/Line.vue';
import Minus from '@/Components/SVG/Billing/Minus.vue';
import Plus from '@/Components/SVG/Billing/Plus.vue';
export default {
    components:{
        Link,
        BreezeGuestLayout,
        BillingImg,
        TitleText,
        Clare,
        Predator,
        Apvcc,
       StepTwo, StepOne, StepThree, StepFour, Line, Minus, Plus
    },
    name: "BillingIndex",
    props:{},
    data() {
        return {
            selected:null,
        }
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<template>
    <BreezeGuestLayout>
        <div class="flex flex-wrap px-4 ">
            <div class="w-full mb-[24px]">
                <div class="w-2/3">
                    <title-text font_style="font-medium " color="text-t_black-800 dark:text-t_white">Join TrapScan as a manager</title-text>
                </div>
                <p class="mt-[8px] dark:text-t_blue_gray-300 text-t_blue_gray-800 font-fira font-normal text-[13px]">
                    Free version available
                </p>
                <div class="flex justify-center mb-[24px]">
                    <billing-img/>
                </div>
                <div class="w-full flex justify-between mb-[48px]">
                    <p class="w-1/2 mt-[8px] pr-[25px] leading-[140%] text-t_green-300 font-fira font-medium text-[16px]">
                        Create, join and manage Projects
                    </p>
                    <Link :href="route('billing_select_plan')" class="flex w-1/2 h-[50px] items-center justify-center bg-t_green-800 rounded-[40px]">
                        <span class="font-bold uppercase montserrat text-t_white text-[13px]">join now</span>
                    </Link>
                </div>
                <title-text color="text-t_black-800 dark:text-t_white">How it works</title-text>

                <div class="w-full flex flex-wrap justify-center mt-[16px]">
                    <!-- Step 1 -->
                    <div  class="w-full">
                        <div class="w-full bg-white rounded-t-[5px] py-[16px] px-[24px] border-b-[1px] border-t_blue_gray-300">
                            <step-one />
                            <span class="monserrat font-semibold text-[14px] leading-[120%] text-t_blue_gray-700 mb-[16px] uppercase">STEP 1</span>
                            <p class="w-full monserrat font-bold text-[24px] leading-[120%] text-t_purple-700 uppercase">
                                CHOOSE YOUR MEMBERSHIP
                            </p>
                        </div>
                        <div class="w-full bg-t_white-150 rounded-b-[5px] py-[16px] px-[24px] flex flex-wrap ">
                            <div @click="(selected === 1 ? selected = 0 : selected = 1)" class="w-full flex flex-row justify-start">
                                <plus v-if="selected !== 1"/>
                                <minus v-if="selected === 1"/>
                                <h2 class="ml-[10px] font-fira font-medium text-[18px] leading-[120%] text-t_black-800">What’s included in the memberships?</h2>
                            </div>

                            <div class="w-full pl-[30px]" v-if="selected === 1">
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    Ability to create and manage projects.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    Import traps from Trap.NZ.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    Upload catch records to Trap.NZ.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    Export Trap and Catch records.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em]">
                                    Reporting: see your project’s data and insights, more on that below.
                                </p>
                            </div>
                        </div>
                    </div>
                    <svg width="7" height="32" viewBox="0 0 7 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.5 0L3.5 32" stroke="#00BF8C" stroke-width="3" stroke-dasharray="4 4"/>
                    </svg>
                     <!-- Step 2 -->
                    <div class="w-full">
                        <div class="w-full bg-white rounded-t-[5px] py-[16px] px-[24px] border-b-[1px] border-t_blue_gray-300">
                            <step-two />
                            <span class="monserrat font-semibold text-[14px] leading-[120%] text-t_blue_gray-700 mb-[16px] uppercase">STEP 2</span>
                            <p class="w-full monserrat font-bold text-[24px] leading-[120%] text-t_purple-700 uppercase">
                                CREATE PROJECTS AND ADD TRAPS
                            </p>
                        </div>
                        <div class="w-full bg-t_white-150 rounded-b-[5px] py-[16px] px-[24px] flex flex-wrap ">
                            <div @click="(selected === 2 ? selected = 0 : selected = 2)" class="w-full flex flex-row justify-start">
                                <plus v-if="selected !== 2"/>
                                <minus v-if="selected === 2"/>
                                <h2 class="ml-[10px] font-fira font-medium text-[18px] leading-[120%] text-t_black-800">What do I need to know about Projects?</h2>
                            </div>
                            <div class="w-full pl-[30px]" v-if="selected === 2">
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    Projects group your traps so you can easily view and manage them.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    They act a public profile where visitors can view your results.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    You can create Projects and Traps in TrapScan, or import them from Trap.NZ.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    Projects and Traps you create in TrapScan are also created in Trap.NZ.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    Your membership allows you to manage multiple projects at once.
                                </p>
                            </div>
                        </div>
                    </div>
                    <svg width="7" height="32" viewBox="0 0 7 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.5 0L3.5 32" stroke="#00BF8C" stroke-width="3" stroke-dasharray="4 4"/>
                    </svg>
                    <!-- Step 3 -->
                    <div  class="w-full">
                        <div class="w-full bg-white rounded-t-[5px] py-[16px] px-[24px] border-b-[1px] border-t_blue_gray-300">
                            <step-three />
                            <span class="monserrat font-semibold text-[14px] leading-[120%] text-t_blue_gray-700 mb-[16px] uppercase">STEP 3</span>
                            <p class="w-full monserrat font-bold text-[24px] leading-[120%] text-t_purple-700 uppercase">
                                Order QR cards
                            </p>
                        </div>
                        <div class="w-full bg-t_white-150 rounded-b-[5px] py-[16px] px-[24px] flex flex-wrap ">
                            <div @click="(selected === 3 ? selected = 0 : selected = 3)" class="w-full flex flex-row justify-start">
                                <plus v-if="selected !== 3"/>
                                <minus v-if="selected === 3"/>
                                <h2 class="ml-[10px] font-fira font-medium text-[18px] leading-[120%] text-t_black-800">How do the QR cards work?</h2>
                            </div>

                            <div class="w-full pl-[30px]" v-if="selected === 3">
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    Each QR has a unique ID, you can read this at the top of the card, and it is also encoded in the QR code.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    QR cards need to be “linked” to a Trap.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    When you scan the QR and record a trap check, that record is saved against the trap the QR card is linked to.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em]">
                                    QR cards can be moved from trap to trap, allowing for flexibility and to avoid waste.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em]">
                                    They are durable, designed to be outside in the elements.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em]">
                                    They are made of PVC which can be recycled easily.
                                </p>
                            </div>
                        </div>
                    </div>
                    <svg width="7" height="32" viewBox="0 0 7 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.5 0L3.5 32" stroke="#00BF8C" stroke-width="3" stroke-dasharray="4 4"/>
                    </svg>
                    <!-- Step 4 -->
                    <div  class="w-full">
                        <div class="w-full bg-white rounded-t-[5px] py-[16px] px-[24px] border-b-[1px] border-t_blue_gray-300">
                            <step-four />
                            <span class="monserrat font-semibold text-[14px] leading-[120%] text-t_blue_gray-700 mb-[16px] uppercase">STEP 4</span>
                            <p class="w-full monserrat font-bold text-[24px] leading-[120%] text-t_purple-700 uppercase">
                                Unlock data & insights
                            </p>
                        </div>
                        <div class="w-full bg-t_white-150 rounded-b-[5px] py-[16px] px-[24px] flex flex-wrap ">
                            <div @click="(selected === 4 ? selected = 0 : selected = 4)" class="w-full flex flex-row justify-start">
                                <plus v-if="selected !== 4"/>
                                <minus v-if="selected === 4"/>
                                <h2 class="ml-[10px] font-fira font-medium text-[18px] leading-[120%] text-t_black-800">What does TrapScan enable us to see?</h2>
                            </div>

                            <div class="w-full pl-[30px]" v-if="selected === 4">
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    Live Charts and total Scans, Catches and Traps are easily visible within your project.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    View performance of traps: You can view your traps ordered by least and most catches
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                                    See the number of people contributing to your project over time and their current engagement level.
                                </p>
                                <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em]">
                                    No more wrestling with Excel spreadsheets to know what’s going on.
                                </p>
                            </div>
                        </div>
                    </div>

                    <Link :href="route('billing_select_plan')" class="flex w-1/2 h-[50px] mt-[35px] items-center justify-center bg-t_green-800 rounded-[40px]">
                        <span class="font-bold uppercase montserrat text-t_white text-[13px]">join now</span>
                    </Link>
                </div>
            </div>
        </div>
    </BreezeGuestLayout>
</template>
