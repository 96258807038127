<template>
    <BreezeAuthenticatedLayout v-if="!delete_trap_with_qr">
        <div class="mb-[8px] mt-[15px] flex flex-wrap px-[24px]">
            <Link
                :href="route('user.settings.traps')"
                class="flex w-full flex-wrap items-center justify-start"
                v-if="step === 0"
            >
                <BlueCircle />
                <span
                    class="montserrat ml-3 text-[13px] font-bold uppercase text-t_purple-700 dark:text-white"
                    >Back -
                </span>
                <span
                    class="montserrat ml-3 text-[13px] uppercase text-t_purple-700 dark:text-white"
                >
                    ME
                </span>
            </Link>
            <div
                v-else
                class="flex w-full flex-wrap items-center justify-start"
                @click="step = 0"
            >
                <BlueCircle />
                <span
                    class="montserrat ml-3 text-[13px] font-bold uppercase text-t_purple-700 dark:text-white"
                    >Back -
                </span>
                <span
                    class="montserrat ml-3 text-[13px] uppercase text-t_purple-700 dark:text-white"
                >
                    {{ trap.name }}
                </span>
            </div>
            <template v-if="step === 0">
                <div class="mt-[16px] w-full">
                    <span
                        class="mfont-bold mb-[40px] font-fira text-[24px] font-semibold text-t_black-800 dark:text-t_white-200"
                    >
                        {{ trap.name }}
                    </span>
                </div>
                <div
                    class="mt-[16px] flex w-full flex-wrap gap-y-[12px] font-fira text-[18px] text-t_black-800 dark:text-t_white-300"
                >
                    <div class="w-1/2">Installed</div>
                    <div class="w-1/2">
                        {{ formattedDate }}
                    </div>
                    <div class="w-1/2" @click="step = 2">Project</div>
                    <div
                        class="flex w-1/2 flex-wrap justify-between"
                        @click="step = 2"
                    >
                        {{ trap?.project?.name ?? "None" }}
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.75187 18.1485C8.28324 17.6799 8.28324 16.9201 8.75186 16.4515L12.7033 12.5L8.75186 8.54853C8.28323 8.0799 8.28323 7.3201 8.75186 6.85147C9.22049 6.38284 9.98029 6.38284 10.4489 6.85147L15.2489 11.6515C15.7176 12.1201 15.7176 12.8799 15.2489 13.3485L10.4489 18.1485C9.98029 18.6172 9.22049 18.6172 8.75187 18.1485Z"
                                fill="#7A9EB8"
                            />
                        </svg>
                    </div>
                    <Link
                        :href="route('user.settings.trap.edit_trap_name', trap.id)"
                        method="get"
                        type="button"
                        class="flex w-full flex-wrap"
                    >
                        <div class="w-1/2">Trap name</div>
                        <div class="flex w-1/2 flex-wrap justify-between">
                            {{ trap.name }}
                            <svg
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8.75187 18.1485C8.28324 17.6799 8.28324 16.9201 8.75186 16.4515L12.7033 12.5L8.75186 8.54853C8.28323 8.0799 8.28323 7.3201 8.75186 6.85147C9.22049 6.38284 9.98029 6.38284 10.4489 6.85147L15.2489 11.6515C15.7176 12.1201 15.7176 12.8799 15.2489 13.3485L10.4489 18.1485C9.98029 18.6172 9.22049 18.6172 8.75187 18.1485Z"
                                    fill="#7A9EB8"
                                />
                            </svg>
                        </div>
                    </Link>

                    <div class="w-1/2">QR ID</div>
                    <div class="w-1/2">
                        {{ trap?.qr_id ?? "Not set" }}
                    </div>
                    <div class="w-1/2">Owner</div>
                    <div class="w-1/2">
                        {{ trap.owner.name }}
                    </div>

                    <div class="w-1/2">Latitude</div>
                    <div class="w-1/2">
                        {{ trap?.coordinates?.coordinates[1] ?? "Not set" }}
                    </div>
                    <div class="w-1/2">Longitude</div>
                    <div class="w-1/2">
                        {{ trap?.coordinates?.coordinates[0] ?? "Not set" }}
                    </div>
                    <div class="w-1/2">Altitude</div>
                    <div class="w-1/2">
                        {{ trap.altitude ?? "Not set" }}
                    </div>
                </div>

                <div class="mt-[32px] flex w-full flex-wrap justify-center">
                    <Link
                        :href="route('user.settings.trap.edit_trap_location', trap.id)"
                        method="get"
                        type="button"
                        class="w-full"
                    >
                        <primary> update trap location </primary>
                    </Link>
                </div>
                <span v-if="trap.qr_id === null"
                    class="mt-[42px] w-full font-fira text-[16px] font-medium text-t_warms-500 underline"
                    @click="visible = !visible"
                >
                    Delete trap
                </span>
                <span v-else
                    class="mt-[42px] w-full font-fira text-[16px] font-medium text-t_warms-500 underline"
                    @click="delete_trap_with_qr = true"
                >
                    Delete trap
                </span>
            </template>
            <template v-if="step === 2">
                <div class="flex w-full flex-wrap">
                    <Heading_H1 class="my-[32px]">
                        Which Project would you like to add this trap to?
                    </Heading_H1>

                    <panel
                        v-for="(pr, index) in projects"
                        :key="index"
                        @click="updatePr(pr.id)"
                        :text="pr?.name ?? ''"
                    />

                    <panel @click="updatePr(null)" text="None for now">
                        <template #svg>
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.9673 2.43188L2.03271 18.3665M2.03271 2.43188L17.9673 18.3665"
                                    stroke="#D44944"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </template>
                    </panel>
                </div>
            </template>
        </div>
        <modal-window :showing="visible">
            <h1 class="montserrat text-bay-of-many-500 mb-3 text-xl font-bold">
                Are you sure you want delete?
            </h1>
            <div class="flex flex-wrap">
                <div class="mx-auto flex w-full">
                    <button
                        @click="delete_trap"
                        class="mt-2 flex w-full items-center justify-center rounded-full bg-valencia py-2 text-sm font-bold text-white transition duration-150 ease-in focus:outline-none sm:text-base"
                    >
                        <span class="mr-2 uppercase">yes</span>
                    </button>
                </div>
                <div class="mx-auto flex w-full">
                    <button
                        @click="visible = false"
                        class="mt-2 flex w-full items-center justify-center rounded-full border-2 border-t_purple-700 py-2 text-sm font-bold text-t_purple-700 transition duration-150 ease-in focus:outline-none dark:border-t_purple-700 dark:text-t_purple-700 sm:text-base"
                    >
                        <span class="mr-2 uppercase">No</span>
                    </button>
                </div>
            </div>
        </modal-window>
    </BreezeAuthenticatedLayout>

    <Show v-if="delete_trap_with_qr">
        <template #header>
            <div @click="delete_trap_with_qr = false">
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.39316 12.0653C7.86179 11.5966 7.86179 10.8368 7.39316 10.3682L3.44168 6.41675L7.39316 2.46528C7.86179 1.99665 7.86179 1.23685 7.39316 0.76822C6.92453 0.299591 6.16473 0.299591 5.6961 0.76822L0.896099 5.56822C0.427469 6.03685 0.427469 6.79665 0.896099 7.26528L5.6961 12.0653C6.16473 12.5339 6.92453 12.5339 7.39316 12.0653Z" fill="white"/>
                </svg>
            </div>
            <div>
                <h1 class="text-white font-bold ml-1 text-[16px] montserrat capitalize  tracking-[.05em]">{{ trap.name }}</h1>
            </div>
            <div @click="delete_trap_with_qr = false">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.837327 0.709641C1.22785 0.319117 1.86102 0.319117 2.25154 0.709641L6.54443 5.00253L10.8373 0.709639C11.2279 0.319115 11.861 0.319115 12.2515 0.709639C12.6421 1.10016 12.6421 1.73333 12.2515 2.12385L7.95865 6.41675L12.2515 10.7096C12.6421 11.1002 12.6421 11.7333 12.2515 12.1239C11.861 12.5144 11.2279 12.5144 10.8373 12.1239L6.54443 7.83096L2.25154 12.1239C1.86102 12.5144 1.22785 12.5144 0.837329 12.1239C0.446804 11.7333 0.446804 11.1002 0.837329 10.7096L5.13022 6.41675L0.837327 2.12385C0.446803 1.73333 0.446803 1.10017 0.837327 0.709641Z" fill="white"/>
                </svg>
            </div>
        </template>
        <template #last>
            <div class="bg-t_warms-300 w-full flex justify-center  py-[6px] gap-x-2">
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8951 15.1646L10.9649 0.742227C10.4778 -0.143893 9.26065 -0.143893 8.77355 0.742227L0.843357 15.1646C0.356259 16.0507 0.964814 17.1574 1.93901 17.1574H17.7994C18.7736 17.1574 19.3822 16.0507 18.8951 15.1646Z" fill="#1F3747"/>
                </svg>
                <h6 class="italic text-t_black-800  montserrat text-[12px] " >
                    QR <span class="font-bold montserrat capitalize">{{trap?.qr_id}}</span> is linked to this trap
                </h6>
            </div>
        </template>
        <div class="px-[24px] w-full flex flex-wrap">
            <div class="w-full">
                <div class="flex justify-center flex-col w-full">
                    <div class="text-t_black-800 dark:text-t_white-150 font-fira text-[18px]">
                        <p class="mb-4">Deleting this trap <span class="font-bold">{{trap.name}}</span> will remove the QR card</p>
                        <p class="mb-4"> <span class="font-bold montserrat capitalize">{{trap?.qr_id}}</span>  will be uninstalled and free to install on another trap</p>
                    </div>
                </div>
                <span class="w-full text-[24px] font-bold text-t_black-800 dark:text-t_white-200 font-fira ">
                    Are you sure you want to install on this trap?
                </span>
                <div class="flex flex-wrap overflow-hidden  mt-[24px] ">
                    <panel @click="delete_trap" text="Yes" small_text="Delete and remove QR ">
                        <template #svg>
                            <good/>
                        </template>
                    </panel>
                    <panel @click="delete_trap_with_qr = false" text="No" small_text="Don’t delete this trap">
                        <template #svg>
                            <no/>
                        </template>
                    </panel>
                </div>
            </div>

        </div>
    </Show>
</template>

<script>
import BreezeAuthenticatedLayout from "@/Layouts/Authenticated.vue";
import { Link, useForm } from "@inertiajs/vue3";
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import QrCode_Historie from "@/Components/SVG/QrCode_Historie.vue";
import Primary from "@/UI/Buttons/Primary.vue";
import BlueCircle from "@/Components/SVG/BlueCircle.vue";
import ModalWindow from "@/Components/ModalWindow.vue";
import { Inertia } from "@inertiajs/inertia";
import Panel from "@/Components/Panel.vue";
import moment from "moment";
import Show from "@/Layouts/Show.vue";
import No from  "@/Components/SVG/No.vue";
import Good from "@/Components/SVG/Good.vue";
export default {
    name: "UserTrapView",
    props: ["trap", "projects"],
    components: {
        BreezeAuthenticatedLayout,
        Link,
        Heading_H1,
        QrCode_Historie,
        Primary,
        BlueCircle,
        ModalWindow,
        Panel,
        Show,
        No,
        Good
    },
    data() {
        return {
            form: useForm({
                trap_id: this.trap.id,
            }),
            updatePrForm: useForm({
                project_id: this.trap.project_id,
            }),
            visible: false,
            step: 0,
            formattedDate: "",
            delete_trap_with_qr:false
        };
    },
    mounted() {
        // Parse the original date string using moment
        const parsedDate = moment(this.trap.created_at);

        // Format the date according to your desired format
        this.formattedDate = parsedDate.format("DD MMM YYYY");
    },
    methods: {
        delete_trap() {
            Inertia.delete(route("user.settings.trap.delete", this.trap.id));
            this.visible = false;
        },
        update() {
            this.updatePrForm.put(
                route("user.projects.view_trap.update", this.trap),
                {
                    preserveScroll: true,
                    onSuccess: () => (this.step = 0),
                }
            );
        },
        updatePr(data) {
            this.updatePrForm.project_id = data;
            this.update();
        },
    },
};
</script>

<style scoped></style>
