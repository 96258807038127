<template>
    <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M17.5718 40.3831C17.9225 40.5856 18.3545 40.5856 18.7052 40.3831L26.0327 36.1526C26.3834 35.9501 26.5994 35.576 26.5994 35.1711V26.7099C26.5994 26.305 26.3834 25.9309 26.0327 25.7284L13.6286 18.5669C13.278 18.3645 12.846 18.3645 12.4953 18.5669L5.16774 22.7975C4.81709 22.9999 4.60107 23.3741 4.60107 23.779V32.2401C4.60107 32.645 4.81709 33.0192 5.16775 33.2216L17.5718 40.3831ZM24.0611 27.5211L13.062 21.1707L7.13934 24.5901V31.429L17.7155 37.5351V30.6963L23.6381 27.2768L24.0611 27.5211V28.498L18.9846 31.429L24.0611 34.3599V27.5211Z"
            fill="#7A9EB8" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M29.8705 23.2718C35.1724 23.2718 39.4705 18.9737 39.4705 13.6718C39.4705 8.36984 35.1724 4.07178 29.8705 4.07178C24.5686 4.07178 20.2705 8.36984 20.2705 13.6718C20.2705 18.9737 24.5686 23.2718 29.8705 23.2718ZM31.0705 10.0718C31.0705 9.40904 30.5332 8.87178 29.8705 8.87178C29.2078 8.87178 28.6705 9.40904 28.6705 10.0718V12.4718H26.2705C25.6078 12.4718 25.0705 13.009 25.0705 13.6718C25.0705 14.3345 25.6078 14.8718 26.2705 14.8718H28.6705V17.2718C28.6705 17.9345 29.2078 18.4718 29.8705 18.4718C30.5332 18.4718 31.0705 17.9345 31.0705 17.2718V14.8718H33.4705C34.1332 14.8718 34.6705 14.3345 34.6705 13.6718C34.6705 13.009 34.1332 12.4718 33.4705 12.4718H31.0705V10.0718Z"
            fill="#00BF8C" />
    </svg>
</template>
