<template>
    <BreezeAuthenticatedLayout>
        <div class="flex flex-wrap justify-end">
            <img src="../../assets/pr_bg.png" class="absolute top-[100px] z-10 h-[177px] object-right" />
        </div>
        <div class="relative z-20 mb-[8px] mt-[15px] flex flex-wrap px-[24px]">
            <Link :href="route('user.projects.index')" class="flex flex-wrap items-center justify-start w-full">
            <BlueCircle />
            <span class="montserrat ml-3 text-[13px] font-bold uppercase text-t_purple-700 dark:text-white">Back -
            </span>
            <span class="montserrat ml-3 text-[13px] uppercase text-t_purple-700 dark:text-white">
                projects
            </span>
            </Link>
            <div class="mt-[24px] flex w-full flex-wrap tracking-[.015em]">
                <TagText class="mb-[8px] w-full" color="text-t_warms-300 uppercase tracking-widest	">project</TagText>
                <Heading_H1 :type="true">{{ project.name ?? "" }}</Heading_H1>
                <div class="mb-[16px] mt-[8px] w-full font-fira text-[18px] text-t_black-800 dark:text-t_white-200">
                    {{
                        user_in_pr && !coordinator
                        ? "You are a member"
                        : coordinator
                            ? "You're a manager"
                            : ""
                    }}
                </div>
                <div class="w-full" v-if="coordinator">
                    <button @click="visiblemenu = !visiblemenu"
                        class="py-[8px] flex h-[37.74px] w-[120px] flex-wrap content-center items-center justify-between rounded-[4px] bg-t_white-400 px-[16px] font-fira text-t_blue-700 dark:bg-t_black-800 dark:text-t_blue-200">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.7344 8.62324C13.7666 8.3823 13.7826 8.13332 13.7826 7.86828C13.7826 7.61127 13.7666 7.35425 13.7264 7.11331L15.3568 5.84432C15.5014 5.73188 15.5415 5.51502 15.4532 5.35439L13.9111 2.68791C13.8148 2.51121 13.614 2.45499 13.4373 2.51121L11.5177 3.28224C11.1161 2.97704 10.6905 2.72003 10.2166 2.52728L9.92747 0.487254C9.89534 0.294496 9.73471 0.157959 9.54195 0.157959L6.45783 0.157959C6.26507 0.157959 6.11247 0.294496 6.08034 0.487254L5.79121 2.52728C5.31734 2.72003 4.88364 2.98508 4.49009 3.28224L2.57054 2.51121C2.39385 2.44696 2.19306 2.51121 2.09668 2.68791L0.562647 5.35439C0.466268 5.52305 0.498394 5.73188 0.659025 5.84432L2.28944 7.11331C2.24928 7.35425 2.21715 7.6193 2.21715 7.86828C2.21715 8.11726 2.23322 8.3823 2.27337 8.62324L0.642962 9.89223C0.498394 10.0047 0.458236 10.2215 0.546583 10.3822L2.08865 13.0486C2.18503 13.2253 2.38582 13.2816 2.56251 13.2253L4.48206 12.4543C4.88364 12.7595 5.30931 13.0165 5.78317 13.2093L6.07231 15.2493C6.11247 15.4421 6.26507 15.5786 6.45783 15.5786H9.54195C9.73471 15.5786 9.89534 15.4421 9.91944 15.2493L10.2086 13.2093C10.6824 13.0165 11.1161 12.7595 11.5097 12.4543L13.4292 13.2253C13.6059 13.2896 13.8067 13.2253 13.9031 13.0486L15.4452 10.3822C15.5415 10.2055 15.5014 10.0047 15.3488 9.89223L13.7344 8.62324ZM7.99989 10.7596C6.40964 10.7596 5.10852 9.45853 5.10852 7.86828C5.10852 6.27802 6.40964 4.97691 7.99989 4.97691C9.59014 4.97691 10.8913 6.27802 10.8913 7.86828C10.8913 9.45853 9.59014 10.7596 7.99989 10.7596Z"
                                class="fill-t_blue-700 dark:fill-t_blue-200" />
                        </svg>
                        Manage
                    </button>

                </div>
                <div class="w-auto" v-else>
                    <Link :href="route('user.projects.ask_manager', project.id)"
                        class="py-[8px] flex  gap-x-2 flex-wrap content-center items-center justify-between rounded-[4px] bg-t_white-400 px-[16px] font-fira text-t_blue-700 dark:bg-t_black-800 dark:text-t_blue-200 ">
                        Join as a manager
                        <svg  width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:fill-t_blue-200 " fill-rule="evenodd" clip-rule="evenodd" d="M9.90071 3.62883C10.2717 3.25784 10.8732 3.25784 11.2442 3.62883L16.9442 9.32883C17.3152 9.69983 17.3152 10.3013 16.9442 10.6723L11.2442 16.3723C10.8732 16.7433 10.2717 16.7433 9.90071 16.3723C9.52971 16.0013 9.52971 15.3998 9.90071 15.0288L13.979 10.9506L2.97246 10.9506C2.44779 10.9506 2.02246 10.5253 2.02246 10.0006C2.02246 9.47591 2.44779 9.05059 2.97246 9.05059H13.979L9.90071 4.97234C9.52971 4.60134 9.52971 3.99983 9.90071 3.62883Z" fill="#03407C"/>
                        </svg>
                    </Link>

                </div>
                <div
                    class="mt-[48px] flex h-[56px] w-full flex-row items-center justify-between rounded-[12px] border-[1px] border-t_blue_gray-300 bg-t_white-100 dark:border-t_blue_gray-700 dark:bg-t_black-800">
                    <div
                        class="montserrat flex w-1/3 tracking-wider flex-wrap items-center justify-center border-r-[1px] border-t_blue_gray-300 text-[24px] font-extrabold text-light_button dark:border-t_blue_gray-700 dark:text-t_blue-300">
                        {{ catched_count }}
                    </div>
                    <div
                        class="montserrat flex w-1/3 tracking-wider flex-wrap items-center justify-center border-r-[1px] border-t_blue_gray-300 text-[24px] font-extrabold text-light_button dark:border-t_blue_gray-700 dark:text-t_blue-300">
                        {{ inspection_count }}
                    </div>
                    <div
                        class="montserrat flex w-1/3 tracking-wider flex-wrap items-center justify-center text-[24px] font-extrabold text-light_button dark:text-t_blue-300">
                        {{ members_count }}
                    </div>
                </div>
                <div class="mt-[8px] tracking-widest flex w-full flex-row items-center justify-between">
                    <div
                        class="montserrat flex w-1/3 flex-wrap items-center justify-center text-[12px] font-semibold uppercase text-[#182532] dark:text-t_white-200">
                        catches
                    </div>
                    <div
                        class="montserrat flex w-1/3 flex-wrap items-center justify-center text-[12px] font-semibold uppercase text-[#182532] dark:text-t_white-200">
                        scans
                    </div>
                    <div
                        class="montserrat flex w-1/3 flex-wrap items-center justify-center text-[12px] font-semibold uppercase text-[#182532] dark:text-t_white-200">
                        members
                    </div>
                </div>

                <div class="mt-[40px] flex w-full flex-wrap justify-between pb-[16px] px-[25px]">
                    <span
                        class="montserrat text-[14px] tracking-widest font-extrabold uppercase text-t_purple-700 dark:text-t_blue-300">Catches</span>
                    <span class="font-fira text-[15px] text-t_blue_gray-800 dark:text-t_white-200">{{ catchCount }}
                        {{
                            firstSelected !== "all time" ? periodText[firstSelected] : "all time"
                        }}</span>
                </div>
                <hr class="h-[1px] w-full border-0 bg-t_blue_gray-300 dark:bg-gray-700" />

                <hr class="w-full border-0 bg-t_blue_gray-300 dark:bg-gray-700" />
                <div
                    class="mt-[16px] flex py-[6px] w-full flex-row items-center justify-between rounded-[5px] bg-t_blue_gray-100 px-[12px] dark:bg-t_black-950 overflow-x-auto">
                    <span :class="firstSelected === 'all time' ? period_menu_selected : period_menu"
                        @click="firstSelected = 'all time'">All</span>
                    <span :class="firstSelected === 'year' ? period_menu_selected : period_menu"
                        @click="firstSelected = 'year'">Year</span>
                    <span :class="firstSelected === 'quarter' ? period_menu_selected : period_menu"
                        @click="firstSelected = 'quarter'">Quarter</span>
                    <span :class="firstSelected === 'month' ? period_menu_selected : period_menu"
                        @click="firstSelected = 'month'">Month</span>
                    <span :class="firstSelected === 'week' ? period_menu_selected : period_menu"
                        @click="firstSelected = 'week'">Week</span>
                </div>
                <div
                    class="mb-[12px] mt-[4px] flex w-2/8 py-[4px] flex-row items-center rounded-[5px] bg-t_blue_gray-100 px-[12px] dark:bg-t_black-950">
                    <div class="flex flex-row items-center justify-between w-full">
                        <span :class="period_menu">Show Scans</span>
                        <CheckBox @change="firstCharType = firstCharType" v-model="firstCharType" type="small" />
                    </div>
                </div>
                <div class="flex flex-wrap items-center content-center justify-center w-full">
                    <Chart :dataSet1="dataSet1" :dataSet2="dataSet2" :xLabels="xLabels" lineColor1="stroke-t_green-700"
                        lineColor2="stroke-t_warms-500" v-if="firstCharType" />
                    <Chart :dataSet2="dataSet2" :xLabels="xLabels" lineColor1="stroke-t_green-700"
                        lineColor2="stroke-t_warms-500" v-else />
                </div>
                <div class="my-[16px] flex w-full flex-wrap" v-if="catched.length > 0">
                    <span class="w-full font-fira text-[15px] text-t_blue_gray-800 dark:text-t_white-200">Catch
                        records</span>
                    <div class="my-[8px] w-full grid grid-cols-4 gap-4 items-stretch">
                        <Link v-for="(data, index) in catched" :key="index"
                            :href="route('user.projects.view_inspection', data.id)" class="h-[72px] w-[71.5px]">
                        <div
                            class="flex h-full w-full flex-wrap justify-center rounded-[4px] bg-t_white-100 p-[8px] dark:bg-t_black-800">
                            <span class="w-full font-fira text-[11px] font-medium text-t_black-800 dark:text-t_white-200">{{
                                data.species_caught }}</span>
                            <RatSmall v-if="data.species_caught === 'Rat'" />
                            <HedgehogSmall v-else-if="data.species_caught === 'Hedgehog'" />
                            <MouseSmall v-else-if="data.species_caught === 'Mouse'" />
                            <StoatSmall v-else-if="data.species_caught === 'Stoat'" />
                            <WeaselSmall v-else-if="data.species_caught === 'Weasel'" />
                            <svg v-else width="32" height="46" viewBox="0 0 32 19" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.7804 6.27318C6.04569 8.61538 9.27522 11.0108 12.5135 13.3985C12.8565 13.6515 13.1365 13.85 13.4207 14.1697M11.1301 3.44704C8.41998 7.86231 5.5865 12.2428 2.5341 16.3678C2.33773 16.6332 1.6179 17.3917 2.26317 16.7465M20.2529 2.40738C22.4142 5.61268 24.8628 8.56763 27.4187 11.3782C28.0338 12.0545 28.7302 12.6044 29.1104 13.4771M30 2C26.3576 5.47644 22.5096 8.80894 19.0434 12.5135C18.7045 12.8757 19.3301 12.5058 19.4453 12.4526"
                                    stroke="#D44944" stroke-width="2.5" stroke-linecap="round" />
                            </svg>
                        </div>
                        <span class="ml-1 font-fira text-[11px] text-t_blue_gray-700 dark:text-t_blue_gray-300">{{
                            data.formatted_date }}
                        </span>
                        </Link>

                        <Link v-if="catched_count > 3" :href="route('user.projects.view_inspections', project.id)"
                            class="flex w-[71.5px] h-full flex-col place-content-center content-center items-center rounded-[4px] bg-t_blue_gray-100 p-[8px] font-medium text-t_black-800 dark:bg-t_black-800 dark:text-t_blue_gray-300">
                        <div class="w-full font-fira text-[20px]">
                            {{ catched_count - 3 }}
                        </div>
                        <div class="w-full font-fira text-[13px] flex flex-wrap items-center gap-x-1">
                            more
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                            </svg>
                        </div>
                        </Link>
                    </div>
                </div>
                <div class="mt-[24px] flex w-full flex-wrap justify-between pb-[16px]  px-[25px]">
                    <span
                        class="montserrat text-[14px] tracking-widest font-extrabold uppercase text-t_purple-700 dark:text-t_blue-300">Traps</span>
                    <span class="font-fira text-[15px] text-t_blue_gray-800 dark:text-t_white-200">{{ trapsCount }}
                        {{
                            secondSelected !== "all time" ? periodText[secondSelected] : "all time"
                        }}</span>
                </div>
                <hr class="h-[1px] w-full border-0 bg-t_blue_gray-300 dark:bg-gray-700" />
                <div
                    class="my-[16px] flex py-[6px] w-full flex-row items-center justify-between rounded-[5px] bg-t_blue_gray-100 px-[12px] dark:bg-t_black-950 overflow-x-auto">
                    <span :class="secondSelected === 'all time' ? period_menu_selected : period_menu"
                        @click="secondSelected = 'all time'">All
                    </span>
                    <span :class="secondSelected === 'year' ? period_menu_selected : period_menu"
                        @click="secondSelected = 'year'">Year</span>
                    <span :class="secondSelected === 'quarter' ? period_menu_selected : period_menu"
                        @click="secondSelected = 'quarter'">Quarter</span>
                    <span :class="secondSelected === 'month' ? period_menu_selected : period_menu"
                        @click="secondSelected = 'month'">Month</span>
                    <span :class="secondSelected === 'week' ? period_menu_selected : period_menu"
                        @click="secondSelected = 'week'">Week</span>
                </div>
                <div class="flex flex-wrap items-center content-center justify-center w-full">
                    <Chart :dataSet1="dataSet3" :xLabels="xLabels2" lineColor1="stroke-t_blue-600" />
                </div>
                <div class="w-full mt-[24px]">
                    <Link :href="route('user.projects.view_traps', [project.id])" class="w-full ">
                        <Primary @click="enter">View all traps</Primary>
                    </Link>
                </div>
<!--                <div class="my-[16px] flex w-full flex-wrap">-->
<!--                    <div class="my-[8px] w-full grid grid-cols-4 gap-4 items-stretch">-->
<!--                        <Link v-for="(data, index) in traps" :key="index" :href="route('user.projects.view_trap', data.id)"-->
<!--                            class="w-[71.5px]">-->
<!--                        <div-->
<!--                            class="flex h-full items-stretch w-full flex-wrap justify-start rounded-[4px] bg-t_white-100 p-[8px] dark:bg-t_black-800">-->
<!--                            <span class="w-full font-fira text-[11px] font-medium text-t_black-800 dark:text-t_white-200">{{-->
<!--                                data.name }}</span>-->
<!--                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                                    d="M11.7396 20.3612C12.049 20.5399 12.4302 20.5399 12.7396 20.3612L19.2051 16.6284C19.5145 16.4498 19.7051 16.1197 19.7051 15.7624V8.29675C19.7051 7.93948 19.5145 7.60936 19.2051 7.43072L8.26037 1.11179C7.95096 0.933162 7.56977 0.933162 7.26037 1.1118L0.794922 4.84462C0.485521 5.02325 0.294922 5.35338 0.294922 5.71065V13.1763C0.294922 13.5336 0.485521 13.8637 0.794922 14.0423L11.7396 20.3612ZM17.4654 9.01245L7.76037 3.40922L2.53456 6.42635V12.4606L11.8664 17.8483V11.8141L17.0922 8.79692L17.4654 9.01245V9.87449L12.9862 12.4606L17.4654 15.0467V9.01245Z"-->
<!--                                    fill="#7A9EB8" />-->
<!--                            </svg>-->
<!--                        </div>-->
<!--                        <span class="ml-1 font-fira text-[11px] text-t_warms-500 dark:text-t_warms-500">{{-->
<!--                            data.inspections_count }} catches</span>-->
<!--                        </Link>-->

<!--                        <Link v-if="traps_count > 3" :href="route('user.projects.view_traps', [project.id])"-->
<!--                            class="flex h-full w-[71.5px] flex-col rounded-[4px] bg-t_blue_gray-100 p-[8px] font-medium text-t_black-800 dark:bg-t_black-800 dark:text-t_blue_gray-300">-->
<!--                        <div class="w-full font-fira text-[20px]">-->
<!--                            {{ traps_count - 3 }}-->
<!--                        </div>-->
<!--                        <div class="w-full font-fira text-[13px] flex flex-wrap items-center gap-x-1">-->
<!--                            more-->
<!--                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24"-->
<!--                                stroke="currentColor" stroke-width="2">-->
<!--                                <path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />-->
<!--                            </svg>-->
<!--                        </div>-->
<!--                        </Link>-->
<!--                    </div>-->
<!--                </div>-->
                <div v-if="user_in_pr" class="mt-[36px] flex w-full flex-wrap justify-start">
                    <span class="font-bold underline text-md font-fira text-t_warms-500" @click="visible = !visible">Leave
                        project</span>
                </div>
                <div v-else class="mt-[16px] grid w-full justify-items-end">
                    <div class="mt-[16px] w-1/3">
                        <Primary @click="enter">join</Primary>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="visiblemenu" @click="visiblemenu = !visiblemenu"
            class="fixed inset-0 z-50 flex flex-wrap items-end w-full h-screen overflow-x-hidden overflow-y-auto bg-t_blue_gray-300 bg-opacity-80 dark:bg-t_blue-800 dark:bg-opacity-80">
            <div
                class="flex h-[416px] pb-[40px] w-full flex-wrap rounded-t-[5px] bg-t_white-150 px-[24px] dark:bg-t_black-900 dark:text-gray-300">
                <div class="my-[21px] flex w-full flex-wrap items-center justify-center">
                    <svg width="64" height="4" viewBox="0 0 64 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2H62" stroke="#7A9EB8" stroke-width="3" stroke-linecap="round" />
                    </svg>
                </div>
                <TagText class="w-full">notification emails</TagText>
                <div class="flex flex-wrap items-center justify-between w-full">
                    <body-text>Catches</body-text>
                    <CheckBox @change="update('notify_catches', notify_catches_local)" v-model="notify_catches_local" />
                </div>
                <div class="flex flex-wrap items-center justify-between w-full">
                    <body-text>Device issues</body-text>
                    <CheckBox @change="update('notify_problems', notify_problems_local)" v-model="notify_problems_local" />
                </div>

                <TagText class="mt-[48px] w-full">data management</TagText>
                <Link :href="route('user.projects.export_data', project)"
                    class="flex flex-wrap items-center justify-between w-full">
                <body-text>Export scan records</body-text>
                <ArrowNext />
                </Link>
                <Link :href="route('user.projects.sync_trap', project)"
                    class="flex flex-wrap items-center justify-between w-full" v-if="project.type === 0">
                <body-text>Import new Trap.NZ traps</body-text>
                <ArrowNext />
                </Link>
            </div>
        </div>

        <modal-window :showing="visible">
            <h1 class="mb-3 text-xl font-bold montserrat text-bay-of-many-500">
                Are you sure you want to leave {{ project.name ?? "" }}?
            </h1>
            <form @submit.prevent="leave">
                <div class="flex flex-wrap">
                    <div class="flex w-full mx-auto">
                        <button :class="{ 'opacity-25': form.processing }" :disabled="form.processing" type="submit"
                            class="flex items-center justify-center w-full py-2 mt-2 text-sm font-bold text-white transition duration-150 ease-in rounded-full bg-valencia-500 hover:bg-valencia-600 focus:outline-none sm:text-base">
                            <span class="mr-2 uppercase">yes, leave</span>
                        </button>
                    </div>
                    <div class="flex w-full mx-auto">
                        <button @click="visible = false"
                            class="flex items-center justify-center w-full py-2 mt-2 text-sm font-bold transition duration-150 ease-in border-2 rounded-full border-bay-of-many-500 text-bay-of-many-500 hover:bg-bay-of-many-500 hover:text-white focus:outline-none dark:border-mystic-100 dark:text-mystic-100 sm:text-base">
                            <span class="mr-2 uppercase">No, stay</span>
                        </button>
                    </div>
                </div>
            </form>
        </modal-window>
    </BreezeAuthenticatedLayout>
</template>

<script setup>
import { Link, useForm } from "@inertiajs/vue3";
import BreezeAuthenticatedLayout from "@/Layouts/Authenticated.vue";
import ModalWindow from "@/Components/ModalWindow.vue";
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import BlueCircle from "@/Components/SVG/BlueCircle.vue";
import TagText from "@/UI/Text/TagText.vue";
import CheckBox from "@/UI/CheckBox.vue";
import ArrowNext from "@/Components/SVG/ArrowNext.vue";
import BodyText from "@/UI/Text/BodyText.vue";
import { ref, onMounted, watch } from "vue";
import Chart from "@/Components/Chart.vue";
import axios from "axios";
import RatSmall from "@/Components/SVG/AnimalsSmall/Rat.vue";
import HedgehogSmall from "@/Components/SVG/AnimalsSmall/Hedgehog.vue";
import MouseSmall from "@/Components/SVG/AnimalsSmall/Mouse.vue";
import StoatSmall from "@/Components/SVG/AnimalsSmall/Stoat.vue";
import WeaselSmall from "@/Components/SVG/AnimalsSmall/Weasel.vue";
import Primary from "@/UI/Buttons/Primary.vue";

const period_menu = ref(
    "font-fira text-[12px] text-t_blue-700 dark:text-t_blue-200 px-[16px] py-[6px] cursor-pointer leading-[120%] tracking-[2%]"
);
const period_menu_selected = ref(
    "font-fira text-[12px] text-t_blue-700 dark:text-t_blue-200 leading-[120%] tracking-[2%] dark:bg-t_black-800 bg-t_white-100 px-[16px] py-[6px] rounded-[5px] cursor-pointer"
);

const dataSet1 = ref([]);
const dataSet2 = ref([]);
const xLabels = ref([]);
const catchCount = ref(0);
const firstCharType = ref(true);

const dataSet3 = ref();
const xLabels2 = ref();
const trapsCount = ref(0);
const catch_list = ref([]);

const props = defineProps([
    "project",
    "user_in_pr_data",
    "user_in_pr",
    "coordinator",
    "catched",
    "catched_count",
    "inspection_count",
    "members_count",
    "traps_count",
    "traps",
]);

const periodText = ref({
    month: "in last 30 days",
    year: "in last 365 days",
    week: "in last 7 days",
    quarter: "in last 90 days",
});

const firstSelected = ref("month");
const secondSelected = ref("year");

watch(firstSelected, (newFirstSelected) => {
    getFirstGraphData();
});

watch(secondSelected, (newSecondSelected) => {
    getTrapGraphData();
});

const visible = ref(false);
const visiblemenu = ref(false);
const notify_problems_local = ref(props.user_in_pr_data?.pivot?.notify_catches ?? false);
const notify_catches_local = ref(props.user_in_pr_data?.pivot?.notify_problems ?? false);

const form = useForm({
    projectId: props.project.id,
});

const updateUserSettings = useForm({
    projectId: props.project.id,
    value: null,
    key: null,
});

function enter() {
    form.post(route("user.projects.enter"));
}

function leave() {
    form.post(route("user.projects.leave"));
}

function syncPr() {
    visible.value = true;
    form.post(route("user.projects.sync"));
}

function update(key, value) {
    updateUserSettings.value = value;
    updateUserSettings.key = key;
    updateUserSettings.put(route("user.projects.update_user"), {
        preserveScroll: true,
        preserveState: true,
    });
}

function getFirstGraphData() {
    let res = axios
        .get(
            "/user/project/get_data/" +
            props.project.id +
            "?type=catch&period=" +
            firstSelected.value
        )
        .then((response) => {
            dataSet1.value = response.data.inspection;
            dataSet2.value = response.data.catch;
            xLabels.value = response.data.label;
            catchCount.value = response.data.catch_count;
            catch_list.value = response.data.cathed;
        })
        .catch((e) => {
            console.log(e);
        });
}

function getTrapGraphData() {
    let res = axios
        .get(
            "/user/project/get_data/" +
            props.project.id +
            "?type=traps&period=" +
            secondSelected.value
        )
        .then((response) => {
            dataSet3.value = response.data.traps;
            xLabels2.value = response.data.label;
            trapsCount.value = response.data.traps_count;
        })
        .catch((e) => {
            console.log(e);
        });
}

onMounted(() => {
    getFirstGraphData();
    getTrapGraphData();
    localStorage.setItem("pr_id_back", props.project.id);
});
</script>
