<script setup>
defineProps({
    type: {
        type: String,
        default: 'submit',
    },
    color: {
        type: String,
        default:"bg-t_purple-700 dark:bg-t_purple-500 text-t_white-200"
    }
});
</script>

    <template>

        <button :type="type" :class="
`    w-full
            rounded-full
            py-[8px]
            px-[32px]
            flex
            justify-center
            items-center
            font-extrabold
            text-[13px]
            montserrat
            hover:bg-opacity-80
            uppercase
            h-[50px] ` + color
">
            <slot />
        </button>
    </template>
