<script>
import { Link } from '@inertiajs/vue3'
import TitleText from '@/UI/Text/TitleText.vue';
import Minus from '@/Components/SVG/Billing/Minus.vue';
import Plus from '@/Components/SVG/Billing/Plus.vue';
export default {
    components:{
        Link,
        TitleText, Minus, Plus
    },
    name: "SelectPlan",
    props:{
        subActive:Number
    },
    data() {
        return {
            selected:null,
        }
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<template>
    <div class="w-full">
        <div class="w-full flex flex-wrap justify-between bg-t_black-900 py-[10px] px-[15px] items-center">
            <Link class="w-1/12" :href="route('billing')">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8487 17.6485C15.3174 17.1799 15.3174 16.4201 14.8487 15.9515L10.8973 12L14.8487 8.04853C15.3174 7.5799 15.3174 6.8201 14.8487 6.35147C14.3801 5.88284 13.6203 5.88284 13.1517 6.35147L8.35167 11.1515C7.88304 11.6201 7.88304 12.3799 8.35167 12.8485L13.1517 17.6485C13.6203 18.1172 14.3801 18.1172 14.8487 17.6485Z" fill="white"/>
                </svg>
            </Link>
            <span class="text-[18px] w-10/12 text-center text-t_white font-bold font-fira">Join TrapScan</span>
            <Link class="w-1/12 justify-end" :href="route('scan')">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976312 1.31658 -0.0976313 1.70711 0.292893L6 4.58579L10.2929 0.292891C10.6834 -0.097633 11.3166 -0.0976331 11.7071 0.292891C12.0976 0.683415 12.0976 1.31658 11.7071 1.7071L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.68342 12.0976 0.292895 11.7071C-0.0976291 11.3166 -0.0976292 10.6834 0.292895 10.2929L4.58579 6L0.292894 1.70711C-0.0976309 1.31658 -0.097631 0.683418 0.292893 0.292893Z" fill="white"/>
                </svg>
            </Link>
        </div>
        <div class="bg-gradient-to-b from-t_black-900 to-t_purple-700 h-full min-h-screen pt-[32px] pb-[48px] px-[24px]">
            <title-text color="text-t_white " text="text-[28px]">Choose membership</title-text>
            <Link :href="route('billing_payment_method', 'standard_plan')" class="flex mt-[24px] flex-wrap justify-between items-center w-full px-[24px] py-[16px] bg-white border-[1px] border-b-[4px] rounded-[4px] border-t_green-800" >
                <div class="flex flex-wrap w-9/12 pr-[10px]">
                    <div class="w-full mb-[4px] flex flex-wrap gap-x-[10px]">
                        <span class="montserrat font-bold text-[16px] text-t_black-800">Standard</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_3783_13077)">
                            <path d="M11.1336 3.14147C11.4549 2.15267 12.8537 2.15267 13.175 3.14147L14.6075 7.5502C14.7512 7.9924 15.1633 8.2918 15.6282 8.2918H20.2638C21.3035 8.2918 21.7358 9.62222 20.8947 10.2333L17.1444 12.9581C16.7682 13.2314 16.6108 13.7158 16.7545 14.158L18.187 18.5667C18.5083 19.5555 17.3766 20.3778 16.5354 19.7667L12.7851 17.0419C12.409 16.7686 11.8996 16.7686 11.5235 17.0419L7.77317 19.7667C6.93204 20.3778 5.80032 19.5555 6.1216 18.5667L7.55408 14.158C7.69776 13.7158 7.54036 13.2314 7.1642 12.9581L3.41391 10.2333C2.57279 9.62222 3.00507 8.2918 4.04475 8.2918H8.68036C9.14533 8.2918 9.55741 7.9924 9.70109 7.5502L11.1336 3.14147Z" fill="#00BF8C"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_3783_13077">
                            <rect width="24" height="24" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <p class="w-full font-fira text-13px leading-[140%] tracking-[1%] text-t_blue_gray-700">
                        Pay the minimum amount to contribute to the basic operating costs of TrapScan.
                    </p>
                </div>
                <div class="flex flex-wrap w-3/12 items-center text-center">
                    <span class="w-full montserrat text-[20px] font-semibold leading-[120%] tracking-[10.5%] text-t_blue_gray-700">
                        $9
                    </span>
                    <span class="w-full text-[10px] montserrat text-t_blue_gray-500 font-semibold">
                        Per month
                    </span>
                </div>
            </Link>

            <!--Second-->
            <Link :href="route('billing_payment_method', 'supporter_plan')" class="flex mt-[24px] flex-wrap justify-between items-center w-full px-[24px] py-[16px] bg-white border-[1px] border-b-[4px] rounded-[4px] border-t_warms-500">
                <div class="flex flex-wrap w-9/12 pr-[10px]">
                    <div class="w-full mb-[4px] flex flex-wrap gap-x-[10px]">
                        <span class="montserrat font-bold text-[16px] text-t_black-800">Supporter</span>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.27373 17.4808C9.75985 17.9729 10.549 17.9754 11.0327 17.4825C11.0327 17.4825 13.7792 14.7832 16.4039 11.4995C18.9039 8.37185 19.5289 4.62185 16.4039 2.74685C13.2829 0.874241 10.1539 3.99685 10.1539 3.99685C10.1539 3.99685 7.02609 0.873555 3.90393 2.74685C0.778932 4.62185 1.40393 8.37185 3.90393 11.4995C6.52145 14.7743 9.27373 17.4808 9.27373 17.4808Z" fill="#D44944"/>
                        </svg>

                    </div>
                    <p class="w-full font-fira text-13px leading-[140%] tracking-[1%] text-t_blue_gray-700">
                        Pay a higher amount to fund improvements and feature development to make TrapScan better for everyone.
                    </p>
                </div>
                <div class="flex flex-wrap w-3/12 items-center text-center">
                    <span class="w-full montserrat text-[20px] font-semibold leading-[120%] tracking-[10.5%] text-t_blue_gray-700">
                        $18
                    </span>
                    <span class="w-full text-[10px] montserrat text-t_blue_gray-500 font-semibold">
                        Per month
                    </span>
                </div>
            </Link>
            <!--Third-->
            <Link :href="route('billing_payment_method', 'free_sub')" class="flex mt-[24px] mb-[32px] flex-wrap justify-between items-center w-full px-[24px] py-[16px] bg-white border-[1px] border-b-[4px] rounded-[4px] border-t_blue_gray-400">
                <div class="flex flex-wrap w-9/12 pr-[10px]">
                    <div class="w-full mb-[4px] flex flex-wrap gap-x-[10px]">
                        <span class="montserrat font-bold text-[16px] text-t_black-800">Free</span>
                    </div>
                    <p class="w-full font-fira text-13px leading-[140%] tracking-[1%] text-t_blue_gray-700">
                        For projects who want to use TrapScan but cannot afford any extra expense right now.
                    </p>
                </div>
                <div class="flex flex-wrap w-3/12 items-center text-center">
                    <span class="w-full montserrat text-[20px] font-semibold leading-[120%] tracking-[10.5%] text-t_blue_gray-700">
                        $0
                    </span>
                    <span class="w-full text-[10px] montserrat text-t_blue_gray-500 font-semibold">
                        Per month
                    </span>
                </div>
            </Link>
            <Link v-if="subActive === 1" :href="route('stripe.unsubscribe')" class="flex mt-[24px] mb-[32px] flex-wrap justify-between items-center w-full px-[24px] py-[16px] bg-white border-[1px] border-b-[4px] rounded-[4px] border-t_blue_gray-400">
                <div class="flex flex-wrap w-full pr-[10px]">
                    <div class="w-full mb-[4px] flex flex-wrap gap-x-[10px] items-center justify-between">
                        <span class="montserrat font-bold text-[16px] w-10/12 text-t_black-800">Stop being a Project Manager</span>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#D44944"/>
                        </svg>
                    </div>
                    <p class="w-full font-fira text-13px leading-[140%] tracking-[1%] text-t_blue_gray-700">
                        You project management permissions will be removed, and you’ll be removed from any projects you manage.
                    </p>
                </div>
            </Link>
            <title-text color="text-t_white " text="text-[28px]">Frequently asked questions</title-text>
            <div class="w-full bg-t_white-150 rounded-[5px] mt-[16px] ">
                <div class="w-full py-[16px] px-[24px] flex flex-wrap gap-y-[16px]">
                    <div @click="(selected === 1 ? selected = 0 : selected = 1)"  class="w-full flex flex-row justify-start">
                        <div>
                            <plus  v-if="selected !== 1"/>
                            <minus v-if="selected === 1"/>
                        </div>
                        <h2 class="ml-[10px] font-fira font-medium text-[18px] leading-[120%] text-t_black-800">Do I really need a membership? and do I have to pay?</h2>
                    </div>
                    <div class="w-full pl-[30px] mt-[10px]" v-if="selected === 1">
                        <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] ">
                            If you want to manage projects you do need a membership, if you cannot afford to pay, you can join for free :)
                        </p>

                    </div>
                </div>
                <div class="w-full py-[16px] px-[24px] flex flex-wrap gap-y-[16px] border-t-[1px] border-t_blue_gray-300">
                    <div @click="(selected === 2 ? selected = 0 : selected = 2)"  class="w-full flex flex-row justify-start">
                        <div>
                            <plus  v-if="selected !== 2"/>
                            <minus v-if="selected === 2"/>
                        </div>
                        <h2 class="ml-[10px] font-fira font-medium text-[18px] leading-[120%] text-t_black-800">
                            Why is TrapScan charging volunteers like this?
                        </h2>
                    </div>
                    <div class="w-full pl-[30px] mt-[10px]" v-if="selected === 2">
                        <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                            There are many costs involved with software design and development, community engagement, support, and web hosting services. Most of the work done to create TrapScan so far has been volunteer effort and a few small grants.
                        </p>
                        <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                            As the amount of users grow, there needs to be a stream of income to support the increased workload and web hosting costs.
                        </p>
                        <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                            We do not want to rely on grants as applying for them takes a lot of time that we could spend on improving TrapScan and supporting users instead. It is also not guaranteed and is very inconsistent, which is too risky for us to rely on solely.
                        </p>
                        <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] ">
                            The bottom line is that TrapScan needs to be able to generate it’s own income, so that it can continue to help trappers across Aotearoa.
                        </p>
                    </div>
                </div>
                <div class="w-full py-[16px] px-[24px] flex flex-wrap gap-y-[16px] border-t-[1px] border-t_blue_gray-300">
                    <div @click="(selected === 3 ? selected = 0 : selected = 3)"  class="w-full flex flex-row justify-start">
                        <div>
                            <plus  v-if="selected !== 3"/>
                            <minus v-if="selected === 3"/>
                        </div>
                        <h2 class="ml-[10px] font-fira font-medium text-[18px] leading-[120%] text-t_black-800">
                            What if I can’t afford it anymore?
                        </h2>
                    </div>
                    <div class="w-full pl-[30px] mt-[10px]" v-if="selected === 3">
                        <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                            There are no contracts - you are free to change your membership level at any time, including down to the free version.
                        </p>
                        <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                            We want to be a flexible as possible - if you can pay great, but if not not worries!
                        </p>
                        <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em]">
                            We also accept donations, so if you prefer you can use TrapScan for free and give us a donation if you have found it to be valuable.
                        </p>
                    </div>
                </div>
                <div class="w-full py-[16px] px-[24px] flex flex-wrap gap-y-[16px] border-t-[1px] border-t_blue_gray-300">
                    <div @click="(selected === 4 ? selected = 0 : selected = 4)"  class="w-full flex flex-row justify-start">
                        <div>
                            <plus  v-if="selected !== 4"/>
                            <minus v-if="selected === 4"/>
                        </div>
                        <h2 class="ml-[10px] font-fira font-medium text-[18px] leading-[120%] text-t_black-800">
                            I use Trap.NZ, how does TrapScan work with Trap.NZ?
                        </h2>
                    </div>
                    <div class="w-full pl-[30px] mt-[10px]" v-if="selected === 4">
                        <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                            TrapScan is the first application to be fully integrated with Trap.NZ
                        </p>
                        <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                            All records captured through TrapScan are instantly and automatically uploaded to Trap.NZ
                        </p>
                        <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                            You can import your existing Trap.NZ Projects and Traps into TrapScan once you have a TrapScan membership.
                        </p>
                        <p class="font-fira text-t_blue_gray-700 text-[15px] leading-[140%] tracking-[0.02em] mb-[16px]">
                            We provide specialist support in this area if you have any questions or needs - we work very closely with the Trap.NZ team.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
