<script setup>
import Primary from "@/UI/Buttons/Primary.vue";
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import {  Map, MapStyle, Marker, config } from '@maptiler/sdk';
import {ref, shallowRef, onMounted, onUnmounted, markRaw, computed } from 'vue';
import '@maptiler/sdk/dist/maptiler-sdk.css';

const emit = defineEmits(['update-location']);
const props = defineProps({
    location_type:{
        type: Object,
    }
})
const type = ref(props.location_type.value)

const mapContainer = shallowRef(null);
const map = shallowRef(null);
const markerData = shallowRef(null);

const userLocation =  computed(() => {
    const lat = localStorage.getItem('lat');
    const lng = localStorage.getItem('lng');
    const zoom = localStorage.getItem('zoom');
    return { lng: (lng != null ? lng : 171.691162), lat: (lat != null ? lat : -42.406418) , zoom: (zoom != null ? zoom : 4.6)  }
});


onMounted(() => {
    config.apiKey = 'j8XguUeSTfgQe3qtQzjE';

    map.value = markRaw(new Map({
        container: mapContainer.value ?? 'test',
        style: MapStyle.OUTDOOR,
        center: [userLocation.value.lng, userLocation.value.lat],
        zoom: userLocation.value.zoom,
        pitch: 40,
    }));


    markerData.value = new Marker({color: "#FF0000"})
        .setLngLat([userLocation.value.lng, userLocation.value.lat])
        .addTo(map.value);

    map.value.on('move', function(e) {
        markerData.value.setLngLat(map.value.getCenter())
    });
})

onUnmounted(() => {
    map.value?.remove();
    markerData.value?.remove();
})

function setLocation(){
    const cords = markerData.value.getLngLat();
    localStorage.setItem("lng", cords.lng );
    localStorage.setItem("lat", cords.lat );
    localStorage.setItem("zoom", map.value.getZoom());

    emit('update-location', {
        lat: cords.lat,
        lon: cords.lng,
        altitude: 0,
    })
}

function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            emit('update-location', {
                lat: position.coords.latitude,
                lon: position?.coords?.longitude,
                altitude: position?.coords?.altitude,
            })
          },
          error => {
            console.log(error.message)
          }
        )
      } else {
        console.log("Geolocation is not supported by this browser.")
      }
    }
</script>
<template>
        <div class="w-full flex flex-wrap px-[14px] mt-[32px]" v-if="type === 1">
            <Heading_H1 class="mb-[32px]">
                Hold your phone against the trap then save the location
            </Heading_H1>
            <span class="">
            </span>
            <p class="w-full mb-[32px] dark:text-t_white-200 text-t_black-800 font-normal font-fira text-[18px] leading-[140%] tracking-[1%]">
                You may need to provide access to your phone’s location.
            </p>
            <primary  @click="getLocation">
                Save location
            </primary>
            <div ref="mapContainer" class="display-0">

            </div>
        </div>
        <div class="w-full flex flex-wrap" v-if="type === 2">
            <div class="map-wrap">
                <div class="map" ref="mapContainer"></div>
                <div class="w-full z-50 flex absolute bottom-14 px-10">
                    <primary  @click="setLocation">
                        Save location
                    </primary>
                </div>
            </div>

        </div>
</template>


<style scoped>
.map-wrap {
    position: relative;
    width: 100%;
    height: calc(100vh - 25px); /* calculate height of the screen minus the heading */
}

.map {
    position: absolute;
    width: 100%;
    height: 100%;
}
</style>
