<template >
    <BreezeAuthenticatedLayout >
        <div class="flex flex-wrap mt-[15px] mb-[8px] px-[24px]">
            <Link :href="route('user.projects.view', project)" class="flex flex-wrap items-center justify-start w-full ">
                <svg width="32" height="32" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2440_1962)">
                        <rect class="fill-[#232F80] dark:fill-[#2F45C5]" x="40.1875" y="40.6123" width="40" height="40" rx="20" transform="rotate(180 40.1875 40.6123)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0362 26.2608C23.5049 25.7922 23.5049 25.0324 23.0362 24.5638L19.0848 20.6123L23.0362 16.6608C23.5049 16.1922 23.5049 15.4324 23.0362 14.9638C22.5676 14.4951 21.8078 14.4951 21.3392 14.9638L16.5392 19.7638C16.0705 20.2324 16.0705 20.9922 16.5392 21.4608L21.3392 26.2608C21.8078 26.7295 22.5676 26.7295 23.0362 26.2608Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2440_1962">
                            <rect width="40" height="40" fill="white" transform="translate(0.1875 0.612305)"/>
                        </clipPath>
                    </defs>
                </svg>
                <span class="font-bold montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">Back - </span>
                <span  class=" montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">
                    {{ project.name }}
              </span>
            </Link>
            <div class="w-full mt-[16px] flex justify-between">
                <span class="text-[24px]  mfont-bold text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                    Traps
                </span>
                <button @click="visiblemenu = !visiblemenu"
                        class="py-[8px] flex gap-x-[8px] flex-wrap content-center items-center justify-between rounded-[4px]
                         bg-t_white-400 px-[12px] font-fira text-t_blue-700 dark:bg-t_black-800 dark:text-t_blue-200">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="fill-t_blue-700 dark:fill-t_blue-200" d="M6.15469 15.3762C6.15469 16.0389 6.69194 16.5762 7.35469 16.5762C8.01743 16.5762 8.55469 16.0389 8.55469 15.3762L8.55469 8.67323L10.1062 10.2247C10.5748 10.6933 11.3346 10.6933 11.8032 10.2247C12.2718 9.75607 12.2718 8.99627 11.8032 8.52764L8.20322 4.92764C7.97817 4.7026 7.67295 4.57617 7.35469 4.57617C7.03643 4.57617 6.7312 4.7026 6.50616 4.92764L2.90616 8.52764C2.43753 8.99627 2.43753 9.75607 2.90616 10.2247C3.37479 10.6933 4.13459 10.6933 4.60322 10.2247L6.15469 8.67323L6.15469 15.3762Z"/>
                            <path class="fill-t_blue-700 dark:fill-t_blue-200" d="M18.1547 10.5762C18.1547 9.91343 17.6174 9.37617 16.9547 9.37617C16.2919 9.37617 15.7547 9.91343 15.7547 10.5762L15.7547 17.2791L14.2032 15.7276C13.7346 15.259 12.9748 15.259 12.5062 15.7276C12.0375 16.1963 12.0375 16.9561 12.5062 17.4247L16.1062 21.0247C16.3312 21.2497 16.6364 21.3762 16.9547 21.3762C17.2729 21.3762 17.5782 21.2497 17.8032 21.0247L21.4032 17.4247C21.8718 16.9561 21.8718 16.1963 21.4032 15.7276C20.9346 15.259 20.1748 15.259 19.7062 15.7276L18.1547 17.2791L18.1547 10.5762Z"/>
                        </svg>
                        Sort
                    </button>
            </div>
            <div class="mt-[20px] w-full">
                <Link v-for="(data, index) in traps" :key="index" :href="route('user.projects.view_trap', data.id)" class="w-full">
                    <div class="flex flex-row p-[10px] h-[55px] mb-[10px] w-full hover:border-[3px] hover:border-t_purple-500 dark:bg-t_black-800 bg-white rounded-[4px]">
                        <div class="flex items-center justify-center">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7396 22.1254C14.049 22.304 14.4302 22.304 14.7396 22.1254L21.2051 18.3926C21.5145 18.2139 21.7051 17.8838 21.7051 17.5265V10.0609C21.7051 9.70363 21.5145 9.3735 21.2051 9.19487L10.2604 2.87594C9.95096 2.69731 9.56977 2.69731 9.26037 2.87594L2.79492 6.60877C2.48552 6.7874 2.29492 7.11752 2.29492 7.47479V14.9404C2.29492 15.2977 2.48552 15.6278 2.79492 15.8065L13.7396 22.1254ZM19.4654 10.7766L9.76037 5.17337L4.53456 8.19049V14.2247L13.8664 19.6125V13.5782L19.0922 10.5611L19.4654 10.7766V11.6386L14.9862 14.2247L19.4654 16.8108V10.7766Z" fill="#7A9EB8"/>
                            </svg>

                        </div>
                        <div class="flex flex-wrap items-center w-8/12 px-3 ">
                            <p class="montserrat w-full font-bold dark:text-t_white-200 tracking-[.02em] leading-[120%]">{{trimmedText(data.name)}}</p>
                            <p class="text-sm font-fira" :class="{'t_blue_gray-400':data.catch  !== null, 'text-t_warms-500':data.catch  === null, 'text-t_warms-500':data.catch  === 0}">{{data.catch}} catches</p>
                        </div>
                        <div style="line-height: 120%; letter-spacing: 0.105em;" class="flex items-center justify-end w-3/12 text-t_blue_gray-700 dark:text-t_white-200 font-fira font-semibold text-[12px]">
                           {{ data.formatted_date }}
                        </div>
                    </div>
                </Link>
            </div>

            <div class="flex flex-wrap justify-center w-full gap-x-2">
                <Link class="flex flex-wrap font-bold underline align-items-center gap-x-1 text-t_purple dark:text-t_purple-200" v-if="prev_page" :href="prev_page ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    Prev
                </Link>
                <Link class="flex flex-wrap font-bold underline align-items-center gap-x-1 text-t_purple dark:text-t_purple-200" v-if="next_page" :href="next_page ">
                    Next
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </Link>
            </div>
        </div>
        <div v-show="visiblemenu" @click="visiblemenu = !visiblemenu"
            class="fixed inset-0 z-50 flex flex-wrap items-end w-full h-screen overflow-x-hidden overflow-y-auto
             bg-t_blue_gray-300 bg-opacity-80 dark:bg-t_blue-800 dark:bg-opacity-80">
            <div
                class=" flex w-full flex-wrap rounded-t-[5px] bg-t_white-150 dark:bg-t_black-900 dark:text-gray-300">
                <div class="flex gap-y-[16px] w-full flex-wrap items-center justify-center my-[21px] mx-[26px]">
                    <svg width="64" height="4" viewBox="0 0 64 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2H62" stroke="#7A9EB8" stroke-width="3" stroke-linecap="round" />
                    </svg>
                    <TagText class="w-full">Sort by</TagText>
                    <div class="w-full flow-root" @click="filterSet('most_catches')">
                        <div class="float-left flex  gap-x-[12px]">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.50608 11.8481C6.28083 11.6231 6.1543 11.3179 6.1543 10.9997C6.1543 10.6815 6.28083 10.3764 6.50608 10.1513L11.3121 5.35134C11.5374 5.12638 11.843 5 12.1616 5C12.4801 5 12.7857 5.12638 13.011 5.35134L17.817 10.1513C18.0359 10.3777 18.157 10.6808 18.1543 10.9954C18.1515 11.3101 18.0252 11.611 17.8024 11.8335C17.5796 12.056 17.2783 12.1822 16.9632 12.185C16.6482 12.1877 16.3447 12.0667 16.1181 11.8481L13.3631 9.09654L13.3631 18.1997C13.3631 18.518 13.2365 18.8232 13.0111 19.0483C12.7858 19.2733 12.4802 19.3997 12.1616 19.3997C11.8429 19.3997 11.5373 19.2733 11.312 19.0483C11.0866 18.8232 10.96 18.518 10.96 18.1997L10.96 9.09654L8.205 11.8481C7.97969 12.0731 7.67414 12.1995 7.35554 12.1995C7.03695 12.1995 6.7314 12.0731 6.50608 11.8481Z" fill="#7A9EB8"/>
                            </svg>
                            <body-text>Most catches</body-text>
                        </div>
                        <svg v-if="filter === 'most_catches'" class="float-right" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6126 6.35147C20.0812 6.8201 20.0812 7.5799 19.6126 8.04853L10.0126 17.6485C9.54396 18.1172 8.78416 18.1172 8.31553 17.6485L3.51553 12.8485C3.04691 12.3799 3.04691 11.6201 3.51553 11.1515C3.98416 10.6828 4.74396 10.6828 5.21259 11.1515L9.16406 15.1029L17.9155 6.35147C18.3842 5.88284 19.144 5.88284 19.6126 6.35147Z" fill="#00CF98"/>
                        </svg>
                    </div>
                    <div class="w-full flow-root" @click="filterSet('last_catches')">
                        <div class="float-left flex  gap-x-[12px]">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8025 12.5516C18.0278 12.7766 18.1543 13.0818 18.1543 13.4C18.1543 13.7182 18.0278 14.0234 17.8025 14.2484L12.9965 19.0484C12.7712 19.2734 12.4656 19.3997 12.147 19.3997C11.8284 19.3997 11.5229 19.2734 11.2976 19.0484L6.49157 14.2484C6.27271 14.0221 6.1516 13.719 6.15434 13.4043C6.15708 13.0897 6.28344 12.7887 6.50621 12.5662C6.72898 12.3437 7.03033 12.2175 7.34536 12.2148C7.66039 12.2121 7.96389 12.333 8.1905 12.5516L10.9455 15.3032V6.2C10.9455 5.88174 11.0721 5.57652 11.2975 5.35147C11.5228 5.12643 11.8284 5 12.147 5C12.4657 5 12.7713 5.12643 12.9966 5.35147C13.222 5.57652 13.3485 5.88174 13.3485 6.2V15.3032L16.1036 12.5516C16.3289 12.3266 16.6345 12.2003 16.9531 12.2003C17.2716 12.2003 17.5772 12.3266 17.8025 12.5516Z" fill="#7A9EB8"/>
                            </svg>
                            <body-text>Least catches</body-text>
                        </div>
                        <svg v-if="filter === 'last_catches'" class="float-right" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6126 6.35147C20.0812 6.8201 20.0812 7.5799 19.6126 8.04853L10.0126 17.6485C9.54396 18.1172 8.78416 18.1172 8.31553 17.6485L3.51553 12.8485C3.04691 12.3799 3.04691 11.6201 3.51553 11.1515C3.98416 10.6828 4.74396 10.6828 5.21259 11.1515L9.16406 15.1029L17.9155 6.35147C18.3842 5.88284 19.144 5.88284 19.6126 6.35147Z" fill="#00CF98"/>
                        </svg>
                    </div>
                    <div class="w-full flow-root" @click="filterSet('newest_traps')">
                        <div class="float-left flex  gap-x-[12px]">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.1543 9L20.4043 6.25L23.1543 5L20.4043 3.75L19.1543 1L17.9043 3.75L15.1543 5L17.9043 6.25L19.1543 9ZM11.6543 9.5L9.1543 4L6.6543 9.5L1.1543 12L6.6543 14.5L9.1543 20L11.6543 14.5L17.1543 12L11.6543 9.5ZM19.1543 15L17.9043 17.75L15.1543 19L17.9043 20.25L19.1543 23L20.4043 20.25L23.1543 19L20.4043 17.75L19.1543 15Z" fill="#7A9EB8"/>
                            </svg>
                            <body-text>Newest traps</body-text>
                        </div>
                        <svg v-if="filter === 'newest_traps'" class="float-right" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6126 6.35147C20.0812 6.8201 20.0812 7.5799 19.6126 8.04853L10.0126 17.6485C9.54396 18.1172 8.78416 18.1172 8.31553 17.6485L3.51553 12.8485C3.04691 12.3799 3.04691 11.6201 3.51553 11.1515C3.98416 10.6828 4.74396 10.6828 5.21259 11.1515L9.16406 15.1029L17.9155 6.35147C18.3842 5.88284 19.144 5.88284 19.6126 6.35147Z" fill="#00CF98"/>
                        </svg>
                    </div>
                    <div class="w-full flow-root" @click="filterSet('oldest_traps')">
                        <div class="float-left flex  gap-x-[12px]">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.1443 2C6.6243 2 2.1543 6.48 2.1543 12C2.1543 17.52 6.6243 22 12.1443 22C17.6743 22 22.1543 17.52 22.1543 12C22.1543 6.48 17.6743 2 12.1443 2ZM12.1543 20C7.7343 20 4.1543 16.42 4.1543 12C4.1543 7.58 7.7343 4 12.1543 4C16.5743 4 20.1543 7.58 20.1543 12C20.1543 16.42 16.5743 20 12.1543 20Z" fill="#7A9EB8"/>
                                <path d="M12.6543 7H11.1543V13L16.4043 16.15L17.1543 14.92L12.6543 12.25V7Z" fill="#7A9EB8"/>
                            </svg>
                            <body-text>Oldest traps</body-text>
                        </div>
                        <svg v-if="filter === 'oldest_traps'" class="float-right" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6126 6.35147C20.0812 6.8201 20.0812 7.5799 19.6126 8.04853L10.0126 17.6485C9.54396 18.1172 8.78416 18.1172 8.31553 17.6485L3.51553 12.8485C3.04691 12.3799 3.04691 11.6201 3.51553 11.1515C3.98416 10.6828 4.74396 10.6828 5.21259 11.1515L9.16406 15.1029L17.9155 6.35147C18.3842 5.88284 19.144 5.88284 19.6126 6.35147Z" fill="#00CF98"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import {Link} from '@inertiajs/vue3';
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import QrCode_Historie from "@/Components/SVG/QrCode_Historie.vue";
import TagText from "@/UI/Text/TagText.vue";
import BodyText from "@/UI/Text/BodyText.vue";
import { router } from '@inertiajs/vue3'

export default {
    name: "ProjectTraps",
    props: ['traps', 'next_page','prev_page','project', 'filters'],
    components:{
        BreezeAuthenticatedLayout,Link,Heading_H1,QrCode_Historie,TagText, BodyText
    },
    data() {
        return {
            visiblemenu: false,
            filter: 'most_catches'
        }
    },
    mounted(){
        localStorage.setItem("pr_id_back", this.project.id);
        if(this.filters){
            this.filter = this.filters;
        }
    },
    methods: {
        trimmedText(edit_text) {
            let text = '';
            if (edit_text.length > 18) {
                text = edit_text.substring(0, 15) + "...";
            } else {
                return text = edit_text;
            }

            return text
        },
        filterSet(param){
            this.filter = param;
            router.visit(route('user.projects.view_traps', [this.project.id])+'?filter='+this.filter)

        }
    }
}
</script>

<style scoped>

</style>
