<template>
    <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M26.9753 19.5353H32.1419M26.9753 26.3045H30.4197M37.3086 14.4583V29.6891C37.3086 31.5584 35.7665 33.0737 33.8641 33.0737H9.75304C7.85072 33.0737 6.30859 31.5584 6.30859 29.6891V14.4583C6.30859 12.5891 7.85072 11.0737 9.75304 11.0737H18.3641H33.8641C35.7665 11.0737 37.3086 12.5891 37.3086 14.4583Z"
            stroke="#7A9EB8" stroke-width="3.38462" stroke-linecap="round" stroke-linejoin="round" />
        <g clip-path="url(#clip0_3816_14335)">
            <path
                d="M15.35 16.2922C15.5697 15.6161 16.5263 15.6161 16.746 16.2922L17.7255 19.3069C17.8238 19.6093 18.1056 19.8141 18.4235 19.8141H21.5934C22.3043 19.8141 22.5999 20.7238 22.0248 21.1417L19.4603 23.0049C19.2031 23.1918 19.0954 23.5231 19.1937 23.8254L20.1732 26.8402C20.3929 27.5163 19.619 28.0786 19.0439 27.6607L16.4794 25.7975C16.2222 25.6106 15.8739 25.6106 15.6166 25.7975L13.0521 27.6607C12.477 28.0786 11.7031 27.5163 11.9228 26.8402L12.9023 23.8254C13.0006 23.5231 12.893 23.1918 12.6357 23.0049L10.0712 21.1417C9.49607 20.7238 9.79167 19.8141 10.5026 19.8141H13.6725C13.9904 19.8141 14.2722 19.6093 14.3705 19.3069L15.35 16.2922Z"
                fill="#00BF8C" />
        </g>
        <defs>
            <clipPath id="clip0_3816_14335">
                <rect width="16.4114" height="16.4114" fill="white" transform="translate(7.84229 14.144)" />
            </clipPath>
        </defs>
    </svg>
</template>
