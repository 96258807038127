<script setup>
import Primary from "@/UI/Buttons/Primary.vue";
import Heading_H1 from "@/UI/Text/Heading_H1.vue";

const emit = defineEmits(['update-location']);

function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            emit('update-location', {
                lat: position.coords.latitude,
                lon: position?.coords?.longitude,
                altitude: position?.coords?.altitude,
            })
          },
          error => {
            console.log(error.message)
          }
        )
      } else {
        console.log("Geolocation is not supported by this browser.")
      }
    }
</script>
<template>
        <div class="w-full flex flex-wrap px-[14px] mt-[32px]" >
            <Heading_H1 class="mb-[32px]">
                Hold your phone against the trap then save the location
            </Heading_H1>
            <span class="">
            </span>
            <p class="w-full mb-[32px] dark:text-t_white-200 text-t_black-800 font-normal font-fira text-[18px] leading-[140%] tracking-[1%]">
                You may need to provide access to your phone’s location.
            </p>
            <primary  @click="getLocation">
                Save location
            </primary>
            <div ref="mapContainer" class="display-0">

            </div>
        </div>

</template>

