<template >
    <BreezeAuthenticatedLayout >
        <div class="flex flex-wrap mt-[15px] mb-[8px] px-[24px]">
            <Link :href="route('user.settings.index')" class="w-full flex flex-wrap justify-start items-center ">
                <svg width="32" height="32" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2440_1962)">
                        <rect class="fill-[#232F80] dark:fill-[#2F45C5]" x="40.1875" y="40.6123" width="40" height="40" rx="20" transform="rotate(180 40.1875 40.6123)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0362 26.2608C23.5049 25.7922 23.5049 25.0324 23.0362 24.5638L19.0848 20.6123L23.0362 16.6608C23.5049 16.1922 23.5049 15.4324 23.0362 14.9638C22.5676 14.4951 21.8078 14.4951 21.3392 14.9638L16.5392 19.7638C16.0705 20.2324 16.0705 20.9922 16.5392 21.4608L21.3392 26.2608C21.8078 26.7295 22.5676 26.7295 23.0362 26.2608Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2440_1962">
                            <rect width="40" height="40" fill="white" transform="translate(0.1875 0.612305)"/>
                        </clipPath>
                    </defs>
                </svg>
                <span class="font-bold montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">Back - </span>
                <span  class=" montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">
                ME
              </span>
            </Link>
            <div class="w-full mt-[16px]">
                <span class="text-[24px] mb-[40px] mfont-bold text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                My Traps
                </span>
            </div>
            <div class="mt-[16px] w-full">
                <Link v-for="(data, index) in traps.data" :key="index" :href="route('user.settings.trap.view', data.id)" class="w-full">
                    <div class="flex flex-row p-[10px]  mb-[10px] w-full hover:border-[3px] hover:border-t_purple-500 dark:bg-t_black-800 bg-white rounded-[4px]">
                        <div class="flex items-center justify-center">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7396 22.1254C14.049 22.304 14.4302 22.304 14.7396 22.1254L21.2051 18.3926C21.5145 18.2139 21.7051 17.8838 21.7051 17.5265V10.0609C21.7051 9.70363 21.5145 9.3735 21.2051 9.19487L10.2604 2.87594C9.95096 2.69731 9.56977 2.69731 9.26037 2.87594L2.79492 6.60877C2.48552 6.7874 2.29492 7.11752 2.29492 7.47479V14.9404C2.29492 15.2977 2.48552 15.6278 2.79492 15.8065L13.7396 22.1254ZM19.4654 10.7766L9.76037 5.17337L4.53456 8.19049V14.2247L13.8664 19.6125V13.5782L19.0922 10.5611L19.4654 10.7766V11.6386L14.9862 14.2247L19.4654 16.8108V10.7766Z" fill="#7A9EB8"/>
                            </svg>

                        </div>
                        <div class="flex w-8/12 flex-wrap px-3 items-center ">
                            <p class="montserrat w-full font-bold dark:text-t_white-200 tracking-[.02em] leading-[120%]">{{trimmedText(data.name)}}</p>
                            <p class="text-sm font-fira" :class="{'t_blue_gray-400':data.qr_id  !== null, 'text-t_warms-500':data.qr_id  === null}">{{data.qr_id}}</p>
                        </div>
                        <div class="flex items-center justify-end w-3/12">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75187 18.1485C8.28324 17.6799 8.28324 16.9201 8.75186 16.4515L12.7033 12.5L8.75186 8.54853C8.28323 8.0799 8.28323 7.3201 8.75186 6.85147C9.22049 6.38284 9.98029 6.38284 10.4489 6.85147L15.2489 11.6515C15.7176 12.1201 15.7176 12.8799 15.2489 13.3485L10.4489 18.1485C9.98029 18.6172 9.22049 18.6172 8.75187 18.1485Z" fill="#7A9EB8"/>
                            </svg>
                        </div>
                    </div>
                </Link>
            </div>

            <div class="flex flex-wrap w-full gap-x-2 justify-center">
                <Link class="flex align-items-center flex-wrap font-bold gap-x-1 text-t_purple underline dark:text-t_purple-200" v-if="prev_page" :href="prev_page">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    Prev
                </Link>
                <Link class="flex align-items-center flex-wrap font-bold gap-x-1 text-t_purple underline dark:text-t_purple-200" v-if="next_page" :href="next_page">
                    Next
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </Link>
            </div>
        </div>
    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import {Link} from '@inertiajs/vue3';
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import QrCode_Historie from "@/Components/SVG/QrCode_Historie.vue";

export default {
    name: "UserTraps",
    props: ['traps', 'next_page','prev_page'],
    components:{
        BreezeAuthenticatedLayout,Link,Heading_H1,QrCode_Historie
    },
    data() {
        return {
        }
    },

    methods: {
        trimmedText(edit_text) {
            let text = '';
            if (edit_text.length > 18) {
                text = edit_text.substring(0, 15) + "...";
            } else {
                return text = edit_text;
            }

            return text
        },
    }
}
</script>

<style scoped>

</style>
