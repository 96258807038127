<template>
    <svg width="154" height="187" viewBox="0 0 154 187" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3137_11438)">
    <path d="M66.5516 37.4488C67.3485 36.6885 68.3575 36.3997 69.3043 35.9208C70.2548 35.4895 71.2126 35.0837 72.185 34.7218L71.856 35.0325C72.0936 34.4038 72.3714 33.5484 72.1155 32.9196C71.8487 32.4883 71.5014 32.0167 71.059 31.7682C71.0188 31.7243 70.9201 31.6219 70.9018 31.589C70.3937 30.9384 70.6496 29.9404 71.1139 29.3884C71.5123 28.8401 72.2544 28.5952 72.8649 28.4343C73.9726 28.1638 75.04 28.0688 76.1696 28.0213C77.8768 28.2772 79.5767 28.6171 81.2766 28.9205L81.1377 28.9132C82.3806 28.8072 83.7478 28.767 84.8445 28.1419C85.678 27.6082 86.559 27.0964 87.4108 26.6029C87.7764 26.3471 90.2659 24.9909 89.8637 26.1058C88.7963 27.3414 87.2646 27.8751 85.9778 28.7962C84.5996 29.8563 82.812 30.0172 81.145 30.1634C79.4524 29.8929 77.7635 29.6078 76.0746 29.3153L76.2025 29.3263C75.2119 29.3665 74.1481 29.4652 73.1903 29.7028C72.737 29.8161 72.2508 30.0135 72.1594 30.1817C71.9985 30.412 71.8779 30.5874 71.9364 30.8104C71.9364 30.8141 71.9474 30.8287 71.9437 30.8287C71.94 30.825 71.951 30.836 71.9181 30.7995L71.7792 30.6898C72.1996 30.9603 72.4921 31.271 72.7882 31.6475C73.8666 32.8173 73.4754 34.4696 72.8613 35.749C72.3568 35.9574 66.1312 38.4321 66.5516 37.4488Z" fill="#60859F"/>
    <path d="M31.9658 52.6558C33.249 52.0197 34.7149 51.0949 36.1187 50.6891C36.3965 50.6124 36.7255 50.5722 37.018 50.5466C37.4493 50.4881 37.8661 50.3857 38.2938 50.2614C44.6071 48.1778 50.4635 44.8038 56.3198 41.671C58.2829 40.6073 60.1912 39.4375 62.1799 38.414C62.8635 38.1069 63.474 37.6719 64.3184 37.65C64.4208 37.6463 64.4975 37.7706 64.4354 37.8583C63.9821 38.5675 63.2985 38.8709 62.6807 39.2986C58.1915 41.9744 53.6 44.4784 48.95 46.8655C46.2814 48.2144 43.5908 49.5303 40.776 50.6343C39.6098 51.073 38.4144 51.5153 37.1569 51.6834C36.1845 51.7236 35.3729 52.1623 34.459 52.6119L32.474 53.6318C31.8379 53.9462 31.3297 53.0067 31.9658 52.6558Z" fill="#60859F"/>
    <path d="M4.39827 69.2842C8.06124 66.813 12.3128 64.0678 16.0598 61.7575C16.3486 61.5967 17.0322 61.1141 17.343 61.0045C17.4234 60.9789 17.5075 60.9533 17.5915 60.935C17.6829 60.8729 18.096 61.0812 18.096 61.2786C18.1143 61.4651 18.1253 61.6405 18.0522 61.6807C17.8986 61.8854 17.7012 62.0426 17.4819 62.1669L16.6447 62.697C15.5882 63.3659 12.7368 65.201 11.6511 65.9028C11.6438 65.9065 5.00511 70.198 4.99779 70.2017C4.40192 70.5782 3.80605 69.6936 4.39827 69.2842Z" fill="#60859F"/>
    <path d="M0.000488281 99.9174C6.09447 95.9731 12.2287 92.1093 18.4872 88.4355C21.642 86.6151 24.7895 84.769 28.1344 83.2922C29.2129 82.7878 30.4485 82.5611 31.5744 82.1115C38.2277 79.3553 44.1828 75.1003 50.4669 71.6129L50.7374 72.0187C48.8511 73.5576 46.7966 74.8334 44.7494 76.1275C40.6112 78.6936 36.4986 81.2525 31.9692 83.1314C30.8543 83.592 29.6844 83.7857 28.5622 84.3048C25.2794 85.756 22.1538 87.5838 19.0392 89.3823C12.799 93.0451 6.66841 96.9053 0.596363 100.835C0.600018 100.835 0.000488281 99.9174 0.000488281 99.9174Z" fill="#60859F"/>
    <path d="M65.9307 64.5031C65.9307 64.5031 68.5298 63.0044 68.921 62.7777" stroke="#60859F" stroke-width="0.874407" stroke-miterlimit="10"/>
    <path d="M72.8359 60.7049L78.5168 57.4259" stroke="#60859F" stroke-width="0.874407" stroke-miterlimit="10"/>
    <path d="M83.8286 55.4119L88.1569 52.9115" stroke="#60859F" stroke-width="0.874407" stroke-miterlimit="10"/>
    <path d="M93.6001 49.7718C93.6001 49.7718 99.5442 46.6646 101.672 45.3962C103.799 44.1277 106.68 42.8629 106.68 42.8629C106.68 42.8629 114.638 39.0393 115.794 37.6027" stroke="#60859F" stroke-width="0.874407" stroke-miterlimit="10"/>
    <path d="M134.503 30.4014C134.503 30.4014 145.946 24.0957 152.482 22.9296" stroke="#60859F" stroke-width="0.874407" stroke-miterlimit="10"/>
    <path d="M98.6267 22.2198C98.6267 22.2198 88.4018 23.7003 85.9927 30.4009C83.5836 37.1014 82.2822 39.1411 82.2822 39.1411C82.2822 39.1411 78.6193 44.4672 78.5169 47.1321C78.4145 49.7969 77.4019 52.6775 78.908 58.5884C78.908 58.5884 75.3072 59.389 75.9506 62.7776C76.594 66.1662 77.8004 66.8644 77.8004 66.8644C77.8004 66.8644 78.7143 67.2958 78.5132 68.6922C78.3122 70.0886 78.0453 76.5917 78.0453 76.5917C78.0453 76.5917 76.8097 80.6749 76.2175 82.3967C75.6253 84.1147 74.6602 88.3624 74.5505 93.8969C74.4445 99.4313 72.3242 100.656 72.3242 100.656C72.3242 100.656 61.0209 111.136 55.8993 112.116C55.8993 112.116 50.9532 113.944 48.8037 117.921C48.8037 117.921 48.2115 118.835 48.1055 121.2C48.1055 121.2 48.376 120.608 49.3411 119.427C50.3062 118.246 53.0625 116.656 53.0625 116.656C53.0625 116.656 57.0801 115.128 59.6062 114.375C62.1322 113.622 70.84 109.645 70.84 109.645C70.84 109.645 77.0071 106.508 78.2062 105.423C78.2062 105.423 79.9207 104.151 80.1656 103.343" stroke="#60859F" stroke-width="0.582938" stroke-miterlimit="10"/>
    <path d="M80.1726 103.343C80.1726 103.343 80.0921 104.864 81.6495 106.531C83.2068 108.198 88.8621 112.822 91.432 113.626C93.9983 114.427 96.6998 116.583 96.6998 116.583C96.6998 116.583 102.022 120.239 102.772 121.204C103.525 122.173 105.192 123.193 105.192 123.193C105.192 123.193 106.998 123.949 106.446 122.469C105.89 120.988 105.024 121.27 104.6 120.243C104.172 119.215 102.67 115.669 102.132 115.132C101.595 114.595 97.8879 108.523 96.0052 107.448C94.1226 106.374 93.2087 104.707 92.2984 103.687C91.3881 102.667 87.462 96.5915 86.3872 95.7872C86.3872 95.7872 86.1715 90.4137 86.0655 88.2094C85.9595 86.0051 85.5281 83.9105 85.5281 83.9105C85.5281 83.9105 84.9907 82.5653 84.9907 81.5454C84.9907 80.5255 84.2377 77.7839 84.2377 77.7839C84.2377 77.7839 82.8412 72.0338 83.0569 68.2723C83.0569 68.2723 83.81 66.5542 84.669 65.8012C85.5281 65.0482 85.9047 63.1144 85.9047 63.1144C85.9047 63.1144 86.0655 59.9451 85.0456 59.4077C84.0256 58.8704 83.2031 58.3952 83.2031 58.3952C83.2031 58.3952 83.6162 55.0102 85.0456 52.9229C86.6102 50.6309 86.4749 46.7158 86.2373 44.5043C86.1532 43.722 85.9485 42.9653 85.6853 42.2232C85.4184 41.4775 85.1479 39.9897 85.583 37.3724C85.9558 35.1316 87.0525 32.6751 87.992 30.8766C89.0887 28.7747 90.7923 27.042 92.8943 25.949C94.7733 24.9693 97.3359 24.0481 100.312 24.0481" stroke="#60859F" stroke-width="0.582938" stroke-miterlimit="10"/>
    <path d="M92.6535 104.11C92.6535 104.11 95.0662 104.765 97.0878 104.403C99.1094 104.041 103.226 104.871 103.226 104.871C103.226 104.871 106.837 106.172 107.631 106.136C108.424 106.099 109.418 106.114 109.437 105.81C109.455 105.507 108.607 104.619 108.607 104.619C108.607 104.619 106.128 102.703 105.291 102.703C104.454 102.703 102.001 102.21 101.241 101.519C100.484 100.828 97.1243 99.7093 97.1243 99.7093C97.1243 99.7093 92.021 98.7735 88.4531 98.3202" stroke="#60859F" stroke-width="0.582938" stroke-miterlimit="10"/>
    <path d="M76.9629 108.544C76.9629 108.544 70.602 121.997 66.6649 125.005C62.7241 128.014 64.7603 123.378 64.7603 123.378C64.7603 123.378 66.2225 120.845 66.2664 119.562C66.3103 118.279 68.6572 112.745 68.6572 112.745C68.6572 112.745 69.2531 111.392 68.957 110.496" stroke="#60859F" stroke-width="0.582938" stroke-miterlimit="10"/>
    <path d="M78.6741 105.061C78.6741 105.061 77.731 132.532 77.508 133.727C77.285 134.923 77.4202 135.277 77.0218 136.118C76.6233 136.959 75.161 140.457 76.2248 142.581C77.2886 144.705 78.6156 143.29 78.6156 143.29C78.6156 143.29 80.1401 142.362 79.8878 138.289C79.8878 138.289 79.3687 136.078 79.058 135.588C78.7472 135.102 79.058 129.922 79.058 129.922C79.058 129.922 80.9114 106.505 80.4691 104.71" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M77.4819 106.351C76.9847 111.835 76.4692 117.318 75.8405 122.786L75.8295 122.871C75.219 123.335 74.6341 123.876 74.1589 124.475C73.8079 124.951 73.3985 125.55 73.7714 126.098L73.9066 126.347C74.0857 126.658 74.2978 127.016 74.5464 127.096C74.8352 127.173 75.1788 126.965 75.4274 126.771C76.0013 126.31 76.5277 125.747 77.0286 125.188C77.3247 124.695 77.4892 123.719 77.0176 123.148C76.9774 123.083 76.9737 123.097 76.9847 123.02L78.2678 111.396L78.6334 111.411L77.3503 123.064L77.3173 122.936C77.5732 123.295 77.7122 123.741 77.6902 124.179C77.6683 124.625 77.544 125.046 77.31 125.433C76.7946 126.011 76.2718 126.57 75.6577 127.067C75.3214 127.316 74.9192 127.579 74.4477 127.451C73.998 127.29 73.8189 126.888 73.5959 126.53C73.5118 126.351 73.3948 126.19 73.3217 125.992C73.2376 125.769 73.2559 125.528 73.3071 125.323C73.6836 124.161 74.6597 123.379 75.5553 122.626L75.4822 122.754C75.8624 117.259 76.3522 111.776 76.8604 106.293C76.8531 106.293 77.4819 106.351 77.4819 106.351Z" fill="#60859F"/>
    <path d="M77.8044 128.255C77.8044 128.255 77.4425 129.775 76.7735 129.505C76.1081 129.234 75.6548 128.968 75.6548 128.255C75.6548 127.542 75.5963 126.884 76.046 126.478" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M81.8946 107.064C81.4048 120.981 82.1286 122.845 82.1286 122.845C82.666 122.984 83.4556 124.523 83.5031 125.667C83.5506 126.807 81.9421 127.041 81.3828 127.184C80.8235 127.323 80.5676 126.95 79.9827 125.576C79.4015 124.201 80.7285 123.382 80.7285 123.382C80.7285 123.382 80.0705 118.184 80.0449 116.214" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M83.3316 108.102C83.3316 108.102 83.5693 123.894 83.8617 124.749C83.8617 124.749 83.9421 125.042 84.103 125.152C84.2638 125.258 85.1704 125.674 84.7171 126.482C84.2638 127.29 83.5985 128.928 82.5274 128.917C81.4563 128.906 81.1931 128.544 81.1127 128.303C81.0322 128.061 81.0322 127.645 81.0322 127.305" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M82.4618 123.075L81.7271 114.832" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M83.9054 123.594C84.0516 123.825 84.2966 124.044 84.5342 124.077C85.035 124.11 85.6711 123.591 85.9014 123.156C86.1317 121.964 86.7166 119.226 84.8449 119.211L84.8486 119.014L84.9984 113.417C85.0131 112.273 85.0752 111.103 85.0277 109.967C84.7608 109.44 84.1065 108.994 83.6276 108.618L83.931 108.453C84.0516 111.703 84.2161 114.953 84.1832 118.206C84.154 118.469 84.2161 118.849 84.0443 119.098C83.9017 119.248 83.7519 119.383 83.5691 119.485L83.4229 119.35C83.5142 119.16 83.6385 119.003 83.7775 118.849C83.92 115.406 83.6458 111.619 83.5289 108.069L83.8506 108.307C84.3295 108.665 84.8083 109.034 85.1958 109.502C85.375 109.689 85.4481 110.01 85.4262 110.27L85.4298 110.625C85.4298 112.5 85.2909 117.091 85.247 119.032L85.0423 118.824C86.7495 118.985 86.6398 121.072 86.4388 122.308C86.4132 122.446 86.3474 122.9 86.3181 123.039C86.1865 123.762 85.1959 124.519 84.4684 124.446C84.0699 124.377 83.7884 124.11 83.5764 123.784C83.591 123.777 83.9054 123.594 83.9054 123.594Z" fill="#60859F"/>
    <path d="M86.9356 111.005C87.1915 113.673 87.2573 116.356 87.2171 119.032C87.1951 119.423 87.2024 119.829 87.1147 120.235L87.1257 120.169C87.1257 120.217 87.1366 120.279 87.1476 120.319C87.1549 120.366 87.1915 120.388 87.1513 120.363C87.1293 120.352 87.1074 120.356 87.111 120.356C87.1184 120.356 87.1695 120.341 87.1951 120.337C87.6155 120.326 87.9738 120.604 88.2224 120.908C88.5587 121.28 88.5148 121.814 88.6026 122.267C88.6062 122.286 88.6208 122.311 88.6062 122.289C88.577 122.249 88.5039 122.231 88.449 122.253C88.3905 122.282 88.3978 122.286 88.3832 122.304L88.6574 122.249C89.0595 122.644 88.8438 123.141 88.6391 123.499C88.3138 124.088 88.1566 125.389 87.2792 125.36C86.8186 125.225 86.6285 125.269 86.1606 125.195C85.5135 125.115 85.3381 124.362 85.371 123.828L85.7365 123.836C85.7402 124.245 85.7475 124.757 86.2337 124.837C86.4969 124.892 86.8661 124.866 87.1695 124.958C87.3011 124.998 87.4108 125.035 87.5314 124.929C87.9299 124.534 88.0542 123.85 88.3101 123.342C88.449 123.086 88.6282 122.772 88.4307 122.534L88.7049 122.479C88.6757 122.527 88.6611 122.56 88.5697 122.596C88.1529 122.677 88.2114 122.001 88.1712 121.748C88.1456 121.258 87.7398 120.78 87.2573 120.699C87.25 120.699 87.239 120.695 87.239 120.699C87.2353 120.703 87.2536 120.695 87.228 120.703C87.1695 120.725 87.0818 120.736 86.9977 120.695C86.8405 120.608 86.8223 120.498 86.793 120.421C86.7674 120.315 86.7455 120.217 86.7674 120.114C86.7967 119.855 86.7784 119.317 86.7711 119.029C86.7199 117.259 86.6175 115.073 86.5773 113.308C86.5627 112.544 86.5554 111.78 86.57 111.016C86.57 111.023 86.9356 111.005 86.9356 111.005Z" fill="#60859F"/>
    <path d="M85.898 119.431L85.2363 111.513" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M46.4126 137.975C46.4126 137.975 46.4126 138.523 46.4126 139.068C46.4126 139.613 46.2628 156.026 46.2628 156.026C47.1694 155.949 47.4435 156.706 47.5349 156.918C47.6263 157.13 47.2315 157.554 47.0195 157.645C46.8074 157.736 46.727 158.109 46.727 158.109C46.8586 158.899 45.9374 160.153 45.9374 160.153C45.9374 160.153 44.9613 160.668 44.6908 159.699C44.4203 158.731 44.4203 157.974 44.4203 157.974C44.4203 157.974 44.3911 157.459 44.1169 157.217C43.8464 156.976 43.8756 156.34 44.3289 156.099C44.7822 155.858 44.7822 156.007 44.7822 156.007C44.7822 156.007 45.0235 141.908 44.9028 140.848C44.7822 139.788 44.6323 138.819 44.6323 138.819" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M48.4492 139.839C48.4492 139.839 48.0069 141.642 48.0069 143.747C48.0069 145.853 48.2299 157.313 48.2299 157.313C48.2299 157.313 48.8623 157.331 49.0451 157.784C49.2315 158.237 49.4801 158.384 49.4801 158.384C49.4801 158.384 49.8311 159.634 49.1328 160.065C48.4346 160.497 48.0215 160.906 47.5426 160.617C47.0674 160.328 46.7786 160.16 46.7786 159.755C46.7786 159.349 46.647 158.797 46.647 158.797" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M47.0454 156.223C47.0454 156.223 47.0454 148.426 47.0454 147.271C47.0454 146.116 47.0454 138.505 47.0454 138.505" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M48.9867 160.157V161.451C49.4582 161.414 49.4656 162.036 49.7836 162.076C50.1016 162.116 49.9445 162.873 50.1126 163.03C50.2844 163.187 50.1126 163.79 50.1126 163.79C50.1126 163.79 49.8348 163.951 49.7434 164.346C49.652 164.741 48.76 164.572 48.76 164.572C48.76 164.572 48.23 164.265 47.7511 164.108C47.2722 163.947 47.2356 163.271 47.2356 163.271C47.2356 163.271 47.2356 162.913 46.8371 162.715C46.4387 162.518 46.5191 161.802 46.647 161.56C46.775 161.323 47.7511 161.56 47.7511 161.56V160.705" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M49.3086 141.1C49.3086 141.1 49.305 138.852 49.0454 157.784" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M50.6135 141.773C50.6903 142.12 50.6318 142.658 50.6391 143.016C50.6318 143.784 50.5989 145.944 50.5879 146.745C50.5221 150.06 50.4271 153.372 50.2406 156.684C50.0981 158.325 50.1054 160.007 49.7033 161.612C49.6813 161.663 49.6375 161.729 49.5899 161.758L49.3998 161.447C49.3852 161.451 49.3669 161.465 49.3633 161.48C49.5278 160.848 49.5241 159.795 49.579 159.133C49.6667 157.477 49.7983 153.409 49.8641 151.694C49.9189 150.068 50.0652 145.823 50.1273 144.248C50.1383 143.882 50.1785 142.753 50.1895 142.387C50.2004 142.179 50.1895 141.974 50.2516 141.766L50.6135 141.773Z" fill="#60859F"/>
    <path d="M43.9418 139.675C43.9418 139.675 43.5214 143.104 43.5214 144.347C43.5214 145.59 43.3715 155.006 43.3715 155.006V158.848C43.3715 158.848 43.7188 159.396 43.6567 160.157C43.5982 160.921 43.5214 162.164 42.995 162.164C42.4686 162.164 41.222 162.504 41.2257 161.973C41.2293 161.447 41.222 161.37 41.2257 160.712C41.2293 160.054 41.6022 158.885 41.6022 158.885C41.6022 158.885 41.9568 158.303 42.2712 158.501C42.2712 158.501 42.3955 153.083 42.2821 152.031C42.1688 150.974 42.1323 142.574 42.1323 142.574" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M40.1647 146.529C40.1647 146.529 40.1647 156.72 40.1647 157.784C40.1647 157.784 39.5981 157.981 39.5981 158.848C39.5981 159.714 39.1082 160.994 39.1082 160.994C39.1082 160.994 38.6549 162.5 39.1082 163.18C39.5615 163.856 40.3511 163.669 40.3511 163.669L40.9507 163.322" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M41.5987 158.885L41.2222 158.281C41.2222 158.281 41.4488 152.403 41.4123 151.954C41.3757 151.5 41.2222 144.46 41.2222 144.46" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M41.9858 158.497C41.9858 158.497 41.5325 151.095 41.3936 150.824" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M41.5582 162.258C41.5582 162.258 41.0684 162.372 41.0062 162.737C40.9441 163.103 40.6406 164.221 40.6406 164.221C40.6406 164.221 40.4981 165.779 40.7101 166.579C40.9221 167.383 41.6679 167.636 41.8068 167.636C41.9457 167.636 42.9254 167.402 43.1192 166.74C43.3129 166.078 43.5359 165.775 43.5652 165.541C43.5944 165.307 43.7297 164.039 43.5944 163.691C43.5944 163.691 43.3897 163.22 43.3897 162.982C43.3897 162.745 43.1813 162.229 43.0717 162.149" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M43.5684 165.545C43.5684 165.545 43.8316 165.899 44.4859 165.665C45.1403 165.435 45.3414 165.318 45.3414 165.318" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M45.3452 162.774C45.3452 162.774 45.0088 162.043 44.7603 161.849C44.7603 161.849 44.7932 160.734 44.8699 160.058" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M43.397 162.986C43.397 162.986 43.5432 162.214 43.6602 162.01C43.7735 161.805 43.6456 159.696 43.6456 159.696" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M45.3454 160.372C45.3454 160.372 45.3198 165.318 45.3454 166.1C45.3709 166.883 46.2702 172.34 46.2702 172.34C46.2702 172.34 47.4876 176.084 47.5022 176.822C47.5168 177.56 47.5168 180.159 47.5168 180.159C47.5168 180.159 46.4494 181 46.5261 181.932C46.6029 182.864 47.6521 183.928 48.0286 183.917C48.4051 183.906 49.5311 184.414 50.0429 184.232C50.5547 184.053 51.6294 183.84 51.6989 183.391C51.772 182.941 52.9601 182.159 52.6128 181.15C52.2655 180.141 51.6952 178.953 51.3955 178.909C51.0957 178.865 50.9641 178.789 50.9641 178.789C50.9641 178.789 49.8747 177.33 49.5603 176.127C49.2459 174.925 48.4271 172.322 48.4271 172.322C48.4271 172.322 47.674 170.352 47.579 168.981C47.4876 167.61 47.1622 164.375 47.1622 164.375C47.1622 164.375 47.1622 163.435 47.2317 163.267" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M47.4219 167.021C47.4219 167.021 47.952 167.424 48.3687 167.361C48.7854 167.299 49.0779 167.131 49.1108 166.762C49.14 166.393 49.3886 166.002 49.3411 165.742C49.2936 165.482 49.2058 164.653 49.0669 164.561" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10"/>
    <path d="M47.4951 180.236C47.8534 180.094 48.2299 179.966 48.6247 180.101C48.961 180.342 49.4363 180.335 49.7616 180.072C49.9919 179.695 50.2113 179.282 50.5768 178.997C50.7231 178.876 50.8912 178.763 51.1033 178.745L51.1106 178.869C50.6353 179.019 50.3173 179.556 50.0906 179.98C50.0724 180.039 50.0066 180.181 49.9261 180.247C49.5788 180.547 49.0159 180.62 48.632 180.353C48.3396 180.134 47.9119 180.258 47.5426 180.353C47.5426 180.357 47.4951 180.236 47.4951 180.236Z" fill="#60859F"/>
    <path d="M54.4992 70.162C53.684 70.8237 52.8907 71.5877 52.3643 72.4723C51.805 73.4337 51.1104 74.2745 50.4195 75.1152L49.8931 75.7367C49.7505 75.9231 49.5202 76.1168 49.4324 76.3252C48.9938 77.2244 49.3411 78.3503 49.7615 79.1984C49.8748 79.436 50.0539 79.6224 50.2221 79.8162C50.7339 80.5509 50.7375 81.4941 50.8143 82.3056C51.0007 85.0362 51.1908 87.9241 49.9004 90.4537C49.4909 91.2689 48.917 92.0987 48.917 93.0162C48.917 93.0308 48.9243 93.0345 48.9243 93.0345C48.917 93.0272 48.9353 93.0345 48.8731 92.9796C48.8512 92.9577 48.7488 92.9285 48.7232 92.9175C49.034 92.9796 49.3008 93.0637 49.5787 93.188C50.5767 93.718 51.5235 94.6502 51.8708 95.7761C52.0865 97.1469 52.2839 98.6201 51.794 99.9763C51.3005 101.311 50.244 102.433 48.9901 103.087C48.7708 103.219 48.7013 103.284 48.6501 103.467C48.4856 104.429 48.3796 105.595 48.3248 106.582C48.248 107.66 48.3467 108.574 48.7964 109.579C49.1875 110.574 49.5787 111.593 49.8967 112.632C50.2148 113.673 50.4706 114.737 50.6461 115.816C50.679 116.017 50.5438 116.203 50.3427 116.236C50.1416 116.269 49.9552 116.134 49.9223 115.933C49.7541 114.891 49.5056 113.86 49.1948 112.851C48.7671 111.261 47.9519 109.868 47.6229 108.223C47.5534 107.649 47.5644 107.097 47.59 106.553C47.6595 105.434 47.7399 104.381 47.9446 103.266C48.0067 102.937 48.3394 102.601 48.6172 102.458C50.8691 101.281 51.7063 99.1684 51.2566 96.7119L51.2164 96.2988C51.136 95.4179 50.5072 94.8147 49.8528 94.2335C49.4909 93.8935 48.9938 93.7144 48.5185 93.623C48.2334 93.4585 48.1786 93.1661 48.1822 92.8919C48.2626 91.872 48.8329 90.9764 49.3008 90.1137C50.2915 88.275 50.3537 86.0269 50.3135 83.9579C50.2513 82.927 50.2623 81.7828 50.0502 80.7922C49.9662 80.4925 49.8748 80.2146 49.6664 80.0209C48.8219 78.8987 48.3211 77.1696 49.1071 75.8902C50.1014 74.7204 51.2018 73.547 51.9841 72.242C52.58 71.2696 53.3952 70.5203 54.2579 69.833C54.3456 69.7636 54.4736 69.7782 54.5431 69.8659C54.6125 69.961 54.5942 70.0926 54.4992 70.162Z" fill="#60859F"/>
    <path d="M57.0325 66.0825C55.0767 66.5833 52.5032 69.3286 52.5032 69.3286C52.5032 69.3286 49.1326 73.7481 48.1127 75.075C47.0928 76.402 47.4949 77.9373 47.4949 77.9373C47.4949 77.9373 47.6521 79.0815 45.7438 80.1489C43.8356 81.2163 43.2799 83.7898 43.2799 83.7898C43.2799 83.7898 42.7352 89.3315 42.6548 90.5524C42.578 91.7734 43.8758 93.4147 43.8758 93.4147C41.0646 94.8184 40.5711 96.0686 39.946 98.982C39.3208 101.895 43.0423 104.966 43.0423 104.966C43.0423 104.966 43.1447 107.28 43.1447 108.245C43.1447 109.206 42.1832 110.614 41.3497 112.149C40.5162 113.684 40.7246 115.79 40.7246 115.79C38.8237 116.832 37.4199 122.944 37.4199 122.944C37.4199 122.944 37.4199 125.883 36.6376 127.758C35.8553 129.633 35.4678 134.078 35.3874 134.989C35.3106 135.899 34.9194 136.89 34.2943 138.085C33.6692 139.28 31.6147 147.114 31.6147 147.114C31.6147 147.114 27.5313 155.05 27.5058 156.227C27.4802 157.404 22.3293 164.075 22.3293 164.075C22.3293 164.075 21.1339 166.002 20.9256 167.172C20.7172 168.341 22.6912 167.373 22.6912 167.373L27.5277 163.695C31.6111 161.685 37.3102 151.936 37.3102 151.936C38.385 148.379 45.7365 137.219 45.7365 137.219C45.7365 137.219 49.6408 140.903 50.1197 141.36C50.5985 141.817 56.0309 144.058 56.3197 144.285C56.6085 144.511 60.754 146.244 63.5506 146.759C66.3471 147.275 70.9386 150.55 71.6149 150.938C72.2912 151.321 73.8632 153.347 74.6126 154.052C75.362 154.758 75.2779 153.668 75.2779 153.668C75.2779 153.668 75.15 151.292 73.7023 149.589C72.2547 147.885 66.4714 144.416 63.3751 143.499C60.2787 142.581 55.6141 139.017 54.2323 137.957C52.8504 136.897 51.3735 132.558 51.3406 130.536C51.3077 128.511 51.114 118.265 51.114 118.265" stroke="#60859F" stroke-width="0.582938" stroke-miterlimit="10"/>
    <path d="M50.2476 148.989C50.2476 148.989 54.415 152.648 55.4971 153.693C56.5792 154.739 56.9667 156.227 56.9667 156.227C56.9667 156.227 57.7015 157.586 58.2023 157.787C58.7031 157.989 58.7031 158.877 58.7031 158.877C58.7031 158.877 58.8676 159.421 59.5914 158.683C60.3189 157.945 60.6735 156.227 60.6735 156.227C60.6735 156.227 60.944 154.699 60.6735 153.617C60.403 152.535 59.628 150.871 58.3522 149.014C57.0763 147.157 56.3818 146.309 56.3818 146.309C56.3818 146.309 55.9175 145.728 55.9175 145.187C55.9175 144.646 55.9175 144.076 55.9175 144.076" stroke="#60859F" stroke-width="0.582938" stroke-miterlimit="10"/>
    <path d="M33.0956 141.769C33.0956 141.769 27.5098 150.455 26.3509 151.573C25.1921 152.688 22.136 155.934 21.1709 156.786C20.2058 157.638 17.2264 159.692 17.2264 159.692C17.2264 159.692 15.797 159.999 15.7568 160.712C15.7166 161.425 16.1809 161.663 16.7219 161.6C17.263 161.538 20.1619 160.372 20.1619 160.372C20.1619 160.372 25.8611 157.561 28.0179 154.662" stroke="#60859F" stroke-width="0.582938" stroke-miterlimit="10"/>
    <path d="M43.3891 99.8446C43.6523 100.645 43.9813 101.49 44.5443 102.122C45.2608 102.747 45.6776 103.767 45.5277 104.732C45.4911 104.966 45.1365 104.918 45.1658 104.681C45.1841 104.07 45.0049 103.42 44.6247 102.937C44.1714 102.458 43.6377 101.903 43.3964 101.267C43.21 100.832 43.0821 100.382 43.0309 99.9068C43.0199 99.8081 43.093 99.7167 43.1917 99.7057C43.2868 99.6948 43.3672 99.7569 43.3891 99.8446Z" fill="#60859F"/>
    <path d="M46.4459 102.458C46.9504 100.353 47.4841 98.112 46.9906 95.9589C46.9613 95.8602 47.0198 95.7542 47.1222 95.7286C47.2063 95.7067 47.294 95.7469 47.3306 95.8236C47.857 96.8911 47.8606 98.1266 47.6998 99.2708C47.5426 100.415 47.2428 101.523 46.7895 102.583C46.6981 102.787 46.3801 102.674 46.4459 102.458Z" fill="#60859F"/>
    <path d="M43.4042 104.297C43.0678 103.708 42.695 103.12 42.2636 102.619C41.8286 102.136 41.2546 101.669 40.8562 101.007C40.4431 100.378 40.3992 99.5191 40.4541 98.8209C40.476 98.1227 40.5016 97.4245 40.5601 96.7263C40.5674 96.6239 40.6551 96.5508 40.7575 96.5581C40.8489 96.5654 40.9183 96.6385 40.9256 96.7263C40.9841 97.4245 41.0097 98.1227 41.0317 98.8209C41.0463 99.5081 41.0353 100.104 41.4045 100.678C41.7226 101.23 42.2453 101.669 42.7059 102.253C43.1483 102.846 43.4809 103.474 43.7332 104.151C43.7697 104.246 43.7222 104.352 43.6272 104.385C43.5431 104.417 43.448 104.377 43.4042 104.297Z" fill="#60859F"/>
    <path d="M70.9829 31.5564C64.3735 34.5173 62.2166 42.3876 62.2093 49.0808C62.213 49.2636 62.213 49.5268 62.2239 49.7059C62.2385 49.801 62.1874 49.9472 62.1471 50.0386L61.9644 50.1153C61.4233 50.2616 61.0102 50.8355 60.7434 51.4496C60.5021 52.0381 60.2864 52.7656 60.2937 53.4016C60.4107 54.1218 60.7726 54.8785 61.0468 55.5767C61.2332 56.3078 60.8823 57.0242 60.6483 57.6932L60.6045 57.8028C60.2352 57.8942 59.9318 58.2086 59.654 58.5815C59.2226 59.1919 58.8205 59.8828 58.6633 60.6212C58.6779 60.9539 58.5354 61.25 58.433 61.473C57.987 62.4124 57.3326 63.3592 57.1828 64.3498C57.0585 65.2162 57.1133 66.1337 57.234 67.0074L57.1023 67.0951C53.2931 69.431 53.9768 74.333 53.7647 78.2078C53.7428 78.5734 53.7282 79.3703 53.7428 79.7395C53.7757 81.2565 54.2217 82.7407 54.8797 84.1042C55.7644 85.9246 57.0658 87.5732 58.5719 88.9075L58.3233 88.8636C59.0983 88.5639 59.866 88.1983 60.5862 87.7779C61.3319 87.3576 61.9278 86.8202 62.5749 86.2499L62.9112 86.6338C61.6244 87.873 60.0378 88.7795 58.3635 89.3973C57.5154 88.6699 56.7697 87.8693 56.0934 86.9993C54.4959 84.9266 53.3078 82.408 53.2383 79.7541C53.2237 79.3557 53.2383 78.5807 53.2602 78.1859C53.443 74.2124 52.8472 69.0654 56.8282 66.6674L56.7148 66.9197C56.5577 65.622 56.4918 64.1817 57.0987 62.979C57.4058 62.2406 57.9614 61.5022 58.1588 60.7492C58.1552 60.3105 58.3782 59.8207 58.5646 59.4186C59.0216 58.5998 59.3981 57.759 60.3522 57.3496L60.1841 57.4994C60.3595 57.0242 60.535 56.5088 60.5643 56.008C60.5679 55.8398 60.5496 55.8069 60.4582 55.6059C60.1585 54.9004 59.8368 54.268 59.6869 53.4821C59.6686 52.2904 60.0086 51.1243 60.7178 50.1592C60.9737 49.8412 61.3283 49.5487 61.767 49.4171L61.5367 49.6182C61.5111 49.6547 61.5001 49.7169 61.5038 49.7461L61.5001 49.7023L61.4964 49.6145C61.438 47.8343 61.5659 46.0395 61.8547 44.2848C62.6699 38.882 65.4592 33.2269 70.7928 31.0958C70.7782 31.0885 70.9829 31.5564 70.9829 31.5564Z" fill="#60859F"/>
    <path d="M66.161 80.4854C66.161 80.4854 66.161 80.288 66.161 80.0796C66.1646 79.619 66.1573 79.6373 66.1646 79.3485C66.1939 78.7966 66.607 78.325 66.7313 77.8315C66.8556 76.9652 67.0018 76.033 67.2065 75.2069C67.4368 72.3629 67.1115 69.1424 65.0826 66.982L64.9802 66.8723C65.3165 65.7172 65.6163 64.4999 65.6784 63.2972C65.7406 62.2079 65.2946 60.8663 64.9034 59.9963C64.4904 59.3054 63.9457 58.6475 63.7154 57.8469C63.6057 57.3095 63.5911 56.7758 63.5874 56.2348C64.7901 55.5183 66.2378 54.4436 65.9782 52.89C65.8795 51.7824 65.6784 50.2142 64.4721 49.8523C64.2089 49.7609 64.0041 49.4831 63.9201 49.2784C63.6495 48.6314 63.6569 48.01 63.6496 47.3483C63.6715 46.1018 63.847 44.8918 64.07 43.6818C64.8486 40.0885 66.384 36.4622 69.2683 34.0569C70.2334 33.2637 71.363 32.6642 72.5621 32.3206L72.7119 32.7921C70.471 33.589 68.6797 35.2559 67.4076 37.2372C65.4883 40.2274 64.4721 43.8171 64.3295 47.3593C64.3222 47.893 64.3222 48.529 64.5196 49.0042C64.6183 49.2857 64.8376 49.2711 65.0533 49.3771C66.5266 50.1082 67.0639 53.5151 66.1135 54.7653C65.5505 55.5512 64.7755 56.1105 63.953 56.5894L64.0809 56.3737C64.0882 56.827 64.1065 57.2986 64.1979 57.7409C64.4209 58.4537 64.9254 59.0751 65.3129 59.7258C65.8283 60.859 66.2049 62.058 66.1683 63.3192C66.1025 64.5438 65.821 65.7099 65.4883 66.8833L65.4262 66.6384C67.5903 68.9413 67.9413 72.3007 67.6891 75.3019C67.5721 75.9087 67.4112 76.479 67.3345 77.0492C67.3198 77.6597 67.1371 78.325 66.83 78.8368C66.6947 79.0927 66.6326 79.3083 66.6508 79.5898V80.4891L66.161 80.4854Z" fill="#60859F"/>
    <path d="M96.7997 31.7242C96.7997 31.7242 97.4248 32.9634 96.7997 33.5081C96.1745 34.0528 93.7508 34.3672 93.601 34.4439C93.4511 34.5207 92.8771 36.2351 91.8133 36.1583C90.7495 36.0816 89.7735 37.7156 89.7406 38.5746C89.7077 39.4337 88.4611 43.3304 90.1756 46.3681C90.1756 46.3681 91.0201 46.9969 91.4807 47.2893C91.9413 47.5818 92.9941 48.3604 93.1733 48.9197C93.3524 49.479 94.1859 51.7892 93.1733 53.4415C93.1733 53.4415 93.6668 54.0812 94.3248 54.1617C94.9828 54.2421 97.1031 54.5126 97.0775 57.5028C97.0775 57.5028 97.0738 59.8606 95.6628 60.8841C95.6628 60.8841 96.562 65.8922 97.7063 66.7439C98.8505 67.5956 99.9691 73.9671 99.9691 73.9671C99.9691 73.9671 104.272 83.3874 95.9991 88.3917C95.9991 88.3917 93.6887 89.4811 92.2155 88.3917C90.7422 87.3024 88.9436 82.6855 88.9436 82.6855C88.9436 82.6855 88.7499 77.4252 89.3933 74.8627C90.033 72.2966 91.5099 69.2187 91.5099 68.1951C91.5099 67.1716 91.9596 62.551 91.5099 61.7834C91.5099 61.7834 89.2105 62.2769 88.9181 60.716C88.6256 59.1551 88.6256 58.4751 89.3311 57.437C90.0367 56.3988 90.0367 56.1137 90.0367 55.4228C90.0367 54.7319 89.3969 54.1726 89.3969 54.1726C89.3969 54.1726 87.7921 53.383 87.7921 51.8441C87.7921 50.3051 87.3754 49.7275 88.1613 47.2272C88.1613 47.2272 85.9972 39.7225 88.4319 36.7761C90.8665 33.8298 92.0546 33.1426 92.0546 33.1426C92.0546 33.1426 94.9718 31.366 96.7997 31.7242Z" stroke="#60859F" stroke-width="0.582938" stroke-miterlimit="10"/>
    <path d="M80.1726 42.3838C80.1726 42.3838 78.9151 41.5759 77.5332 43.4768C77.5332 43.4768 75.5153 44.8513 74.5356 45.9223C73.5559 46.9934 70.4449 49.1465 70.0428 52.908C69.6407 56.6695 69.6407 58.457 69.6407 58.457C69.6407 58.457 68.2588 58.68 68.4891 60.2117C68.7194 61.7397 69.0813 62.2258 69.0813 62.511C69.0813 62.7961 68.6938 63.8562 68.544 64.0426C68.3941 64.2291 67.418 66.4479 67.6703 68.5206C67.9225 70.5896 68.31 73.0132 69.0667 74.0404C69.0667 74.0404 68.9168 77.1695 68.4928 77.6411C68.0724 78.1126 66.6832 79.5858 66.2775 80.7556C66.2775 80.7556 63.4662 83.2084 63.0605 84.963C62.6547 86.7177 62.8813 89.5763 63.6015 91.1481C64.3217 92.7237 65.1076 93.9665 65.5025 94.2772C65.5025 94.2772 64.7969 96.5363 64.2376 96.4925C64.2376 96.4925 66.1312 96.6789 67.429 96.4925C68.7267 96.306 70.2694 96.5729 70.2694 96.5729C70.2694 96.5729 71.0664 96.6021 71.2638 95.999C71.2638 95.999 69.5675 94.7671 69.637 94.2736C69.637 94.2736 71.2491 93.5717 71.951 92.1022C72.6529 90.6291 73.8081 86.2132 73.4389 84.6706C73.0697 83.128 71.6696 78.7121 70.2658 77.9408L70.5984 75.2065C70.5984 75.2065 73.7716 73.6639 73.841 72.4027C73.9105 71.1416 75.7346 63.2201 72.2289 61.3266C72.2289 61.3266 73.6619 59.8754 71.6147 58.2596C71.6147 58.2596 71.5964 54.1399 72.0278 52.7581C72.4628 51.38 75.7639 46.4304 78.7396 45.9187" stroke="#60859F" stroke-width="0.582938" stroke-miterlimit="10"/>
    <path d="M87.2685 26.8484C87.2685 26.8484 79.8329 23.7266 78.7509 23.5657C77.6688 23.4049 74.7881 21.2445 74.7881 21.2445C74.7881 21.2445 69.8859 15.5602 66.5921 15.6406C63.2984 15.721 61.7374 15.0813 61.7374 15.0813C61.7374 15.0813 60.2167 15.0009 58.0525 16.0829C58.0525 16.0829 49.9662 17.0041 47.802 19.8444C47.802 19.8444 45.3601 19.3911 42.4757 22.2205C39.5951 25.0498 39.9534 25.6055 39.3538 25.887C38.7543 26.1684 25.8608 35.256 25.8608 37.3762C25.8608 39.4964 25.3015 40.5382 25.3015 40.5382C25.3015 40.5382 23.0605 43.9817 23.3786 47.2643C23.3786 47.2643 18.0523 52.9084 17.8147 56.7138C17.5771 60.5191 16.919 58.1102 19.0284 65.4577C19.0284 65.4577 18.4946 66.6384 18.6153 68.521C18.6153 68.521 18.6957 68.6672 18.1766 69.3947C17.6575 70.1221 17.9792 71.8621 19.032 71.694C19.032 71.694 20.3517 69.6323 20.9147 67.9617C21.474 66.2912 24.4314 62.7746 24.4314 62.7746C24.4314 62.7746 25.7182 61.3343 26.4896 60.2121C27.2609 59.0898 28.5477 58.5781 28.5477 57.4229C28.5477 56.2678 32.1156 52.0676 33.8374 51.3475C35.5592 50.6274 36.2136 49.4393 36.2136 49.4393C36.2136 49.4393 37.0581 49.1505 37.6284 48.3463C38.1986 47.5421 39.5622 46.7818 40.2019 46.7416C40.8417 46.7014 42.6037 45.9885 42.7645 45.4439C42.9254 44.8992 54.3749 39.0943 55.6544 37.1751C56.9339 35.2523 60.0997 34.8941 60.6809 33.0919C60.6809 33.0919 66.424 30.0506 66.9833 28.369C66.9833 28.369 69.5459 28.0876 73.6695 25.6457C73.6695 25.6457 78.9154 25.123 80.6737 25.8833C82.4321 26.6436 85.1007 28.6688 85.1007 28.6688" stroke="#60859F" stroke-width="0.582938" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M97.3547 6.44667C97.3547 6.44667 91.9078 7.84307 91.63 10.9137C91.3521 13.9843 97.3547 20.2681 97.3547 20.2681C97.3547 20.2681 102.323 27.0381 106.552 27.5279C106.552 27.5279 110.515 29.8163 111.524 30.3975C112.533 30.9787 113.831 41.4883 133.378 48.7481C133.378 48.7481 135.132 50.2542 135.165 50.9926C135.198 51.7273 137.728 57.62 139.362 58.5814C140.996 59.5428 142.531 62.6463 142.531 62.6463C142.531 62.6463 143.365 63.2239 143.493 61.5899C143.621 59.9559 143.782 53.7123 143.493 52.3999C143.204 51.0876 141.603 48.558 141.603 48.558C141.603 48.558 135.71 35.9392 135.71 34.594C135.71 33.2524 133.403 25.9488 132.285 23.9638C131.162 21.9789 124.922 10.8881 122.378 9.04573C119.833 7.20336 110.193 0.192117 102.655 3.08362C102.655 3.09093 99.8552 3.05437 97.3547 6.44667Z" stroke="#60859F" stroke-width="0.582938" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M121.873 42.9141C121.873 42.9141 128.099 49.388 129.122 50.0898C130.146 50.7917 136.028 53.8623 138.36 62.2078C138.36 62.2078 142.294 67.0659 144.275 70.4253C146.256 73.7847 147.313 72.4066 148.143 77.2684C148.143 77.2684 143.284 77.7473 142.038 77.2684C140.791 76.7896 139.095 75.8611 137.819 74.8375C136.54 73.814 129.985 71.8949 128.581 70.4582C127.174 69.018 118.448 65.728 118.799 64.2256C118.799 64.2256 116.017 67.6143 115.315 67.5814C114.613 67.5485 113.937 67.161 113.004 66.6236C112.072 66.0863 105.379 62.6903 105.013 61.2244C104.648 59.7586 101.087 58.3512 101.087 58.3512C101.087 58.3512 94.3789 52.1954 93.6733 50.2616" stroke="#60859F" stroke-width="0.582938" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M56.4474 87.2585C56.0855 86.4507 55.8296 85.6026 55.7126 84.7143C55.5517 83.4166 55.9209 82.097 55.899 80.7919C55.9063 79.8927 55.8369 79.019 56.0781 78.1344C56.4949 76.939 57.2077 75.7729 57.4563 74.5264C57.5148 73.6418 57.8914 72.8193 58.1107 71.9822C58.3739 71.0866 58.1399 70.2751 58.5969 69.3758C58.776 68.9628 59.1489 68.78 59.2549 68.334C59.3646 67.9758 59.4158 67.5517 59.4231 67.1606L59.4487 67.2922C59.1928 66.4514 58.9405 65.5266 59.1379 64.6456L59.3244 63.8231C59.4414 63.3369 59.5291 62.869 59.6095 62.3792C59.7996 61.7468 60.1359 61.1839 60.4503 60.6282C60.4942 60.5515 60.5929 60.5259 60.6697 60.5697C60.7428 60.6136 60.772 60.705 60.7318 60.7818C60.4869 61.2752 60.2419 61.787 60.0811 62.2951C60.0007 62.5474 60.0153 62.8288 59.9714 63.092C59.9093 63.5672 59.7886 64.2728 59.7119 64.7553C59.5949 65.5558 59.891 66.3491 60.1688 67.0911C60.1615 67.5919 60.1103 68.0635 59.9605 68.5606C59.8837 68.7946 59.785 69.0359 59.5912 69.2662C59.4011 69.4709 59.3646 69.4928 59.2549 69.7048C59.0356 70.1508 58.9771 70.7613 58.9734 71.2876C58.9259 72.0407 58.6042 72.7316 58.3995 73.4298C58.2569 73.8319 58.1619 74.2413 58.1217 74.6544C57.906 75.5609 57.5514 76.3322 57.1785 77.162C56.7727 77.9004 56.4949 78.6571 56.4876 79.5198C56.462 79.9439 56.4949 80.3935 56.4693 80.8175C56.473 82.1152 56.1037 83.3727 56.1878 84.6704C56.2573 85.5075 56.4474 86.3519 56.696 87.1635C56.7215 87.2329 56.6813 87.3133 56.6082 87.3353C56.5424 87.3536 56.4766 87.3207 56.4474 87.2585Z" fill="#60859F"/>
    <path d="M58.7548 88.6077C59.3178 87.7925 59.7784 86.8348 59.8845 85.8515C59.932 85.3616 59.8844 84.806 59.9466 84.2979C59.9868 83.2451 60.5827 82.3531 60.5534 81.2711C60.462 80.6862 60.7764 80.0721 60.9811 79.6006C61.1164 79.2898 61.2297 78.9718 61.1968 78.7488C61.0872 78.0689 60.9921 77.3159 61.0469 76.5592C61.0908 75.8317 61.4344 75.1774 61.5258 74.4463C61.7635 73.0682 61.8695 71.6645 61.9243 70.2388C61.9718 69.4858 61.9938 68.7693 62.3995 68.0894C62.6518 67.1865 62.2277 66.1154 62.2643 65.1869C62.1729 63.7576 62.6372 62.4709 62.7066 61.0781C62.7651 60.5773 62.904 60.146 63.0393 59.6744C63.0685 59.5831 63.1672 59.5282 63.2586 59.5575C63.35 59.583 63.4012 59.6781 63.3793 59.7695C63.2696 60.2557 63.1234 60.7784 63.127 61.2719C63.127 61.3742 63.1014 61.7032 63.0941 61.8129C62.9662 62.9278 62.7395 64.0354 62.8236 65.1687C62.8272 66.0387 63.1161 66.7734 63.0832 67.68C63.0758 67.9212 63.0466 68.2173 62.9186 68.4586C62.8638 68.5646 62.8053 68.656 62.7688 68.7766C62.6225 69.2482 62.6189 69.7636 62.5896 70.2717C62.5421 71.6937 62.4398 73.1376 62.2021 74.5633C62.1254 75.2542 61.7964 75.9085 61.7415 76.6067C61.694 77.2757 61.789 77.9446 61.8841 78.6684C61.9353 79.5274 61.3906 80.0867 61.2188 80.7959C61.1932 80.8983 61.1859 80.986 61.1895 81.0883C61.237 81.7354 61.0872 82.3605 60.8642 82.96C60.4438 83.8409 60.4511 84.9266 60.3524 85.9099C60.2683 86.4546 60.0709 86.9627 59.826 87.4379C59.581 87.9131 59.2922 88.3518 58.9413 88.7576C58.8974 88.8088 58.8206 88.8124 58.7695 88.7685C58.7183 88.732 58.7146 88.6589 58.7548 88.6077Z" fill="#60859F"/>
    <path d="M63.2984 83.2375C63.5616 82.3382 63.9162 81.4061 63.8065 80.4666C63.6566 79.5052 63.8504 78.5329 63.8102 77.5824C63.8577 77.3046 64.066 77.078 64.194 76.8952C64.6217 76.3469 64.9032 75.7437 64.9909 75.0784C65.181 74.1353 64.6619 73.0862 64.8922 72.0955C65.0421 71.7117 65.0823 71.295 65.1043 70.86C65.1262 69.5806 65.0531 68.0745 64.1428 67.1021L63.9344 66.8682L64.0843 66.6342C64.6656 65.5997 64.6144 64.2618 64.2342 63.1432C63.9747 62.562 64.0734 61.8236 63.9929 61.1766C63.9198 60.5405 63.8102 59.9008 63.6859 59.2684C63.6712 59.1843 63.7261 59.1039 63.8102 59.0856C63.8906 59.071 63.971 59.1222 63.9893 59.1989C64.1465 59.8313 64.2927 60.4674 64.406 61.1144C64.5376 61.7505 64.428 62.3427 64.7314 62.9678C65.2322 64.2435 65.3601 65.7569 64.7058 67.029L64.6473 66.5611C64.9507 66.8645 65.1554 67.2155 65.3163 67.5737C65.9012 69.1273 65.9268 70.9075 65.4442 72.4721C65.4223 73.3384 65.7221 74.2121 65.5466 75.1515C65.4369 75.9082 65.0677 76.632 64.6071 77.195C64.4791 77.3704 64.3329 77.513 64.3073 77.6738C64.3439 78.5036 64.1611 79.3152 64.2196 80.134C64.2671 80.4959 64.3037 80.887 64.2306 81.2453C64.1136 81.98 63.9015 82.6636 63.6603 83.3508C63.6274 83.4495 63.5177 83.5007 63.419 83.4678C63.313 83.4495 63.2618 83.3362 63.2984 83.2375Z" fill="#60859F"/>
    <path d="M82.9623 65.3328C83.0244 64.8576 83.1122 64.3934 83.401 63.9876C83.6861 63.6257 84.0224 63.2309 84.0298 62.774C83.9128 62.1928 83.7336 61.5969 83.4595 61.0705C83.3608 60.8914 83.2255 60.7086 83.1012 60.6611C82.7356 60.6026 82.2312 60.6172 81.8693 60.7232C81.1418 60.9206 81.2112 61.8199 80.6336 62.211C80.3704 62.317 80.0195 62.2257 79.8404 61.988C79.6905 61.7541 79.7344 61.553 79.5991 61.341C79.5077 61.1656 79.3322 60.9901 79.2079 61.0486C79.1568 61.0705 79.0983 61.0486 79.0763 60.9974C79.0544 60.9462 79.0763 60.8841 79.1275 60.8622C79.4273 60.7415 79.6612 61.0047 79.8002 61.2204C79.9464 61.4214 79.9427 61.6554 80.0561 61.8272C80.1145 61.9405 80.4253 61.988 80.4947 61.9515C80.7652 61.7102 80.9188 61.1838 81.1345 60.8475C81.4342 60.3833 81.9972 60.3065 82.4834 60.2809C82.7649 60.2846 82.9221 60.2517 83.2292 60.3284C83.5289 60.46 83.6496 60.694 83.7775 60.8951C84.0882 61.5311 84.3661 62.2366 84.3734 62.9458C84.2783 63.4429 83.9493 63.8195 83.6423 64.1704C83.3973 64.4884 83.2511 64.9344 83.167 65.3584C83.1414 65.4827 82.944 65.4608 82.9623 65.3328Z" fill="#60859F"/>
    <path d="M81.7226 59.3782C81.9346 58.3766 82.9289 58.0512 83.8319 58.3364C84.329 58.4606 84.1024 59.2027 83.6199 59.0273C83.2141 58.8372 82.6877 58.7312 82.3367 59.0857C82.2197 59.1917 82.1247 59.3489 82.0662 59.4915C81.9894 59.6926 81.6677 59.5865 81.7226 59.3782Z" fill="#60859F"/>
    <path d="M93.918 56.9399C94.4846 57.4151 94.7149 58.223 94.4554 58.9248L94.3969 59.1186C94.1849 59.8277 94.0679 60.6795 93.3916 61.1474C92.8944 61.4581 92.3717 61.7067 91.816 61.9077C91.604 61.9662 90.7266 62.244 90.7339 61.82C90.7449 61.7432 90.7997 61.6884 90.8692 61.6701C91.3371 61.5604 91.8014 61.2314 92.2912 60.9646C92.5106 60.833 92.7665 60.7124 92.9822 60.5735C93.3843 60.2956 93.4976 59.7948 93.6621 59.3233C93.7096 59.188 93.8412 58.8407 93.8961 58.7165C94.13 58.2303 94.0057 57.6308 93.6767 57.2141C93.5086 57.0422 93.7425 56.7937 93.918 56.9399Z" fill="#60859F"/>
    <path d="M94.5434 60.1969C94.9821 59.6522 95.3476 59.0052 95.2928 58.3765C95.2599 58.1425 95.2745 57.8793 95.5048 57.7221C96.2908 57.3383 96.7989 58.4423 96.8282 59.0125C96.8391 59.1149 96.766 59.2063 96.6637 59.2172C96.5869 59.2245 96.5174 59.188 96.4845 59.1258C96.2762 58.7822 96.1117 58.4386 95.8119 58.2741C95.7753 58.2558 95.7826 58.2887 95.8594 58.2449C95.9289 58.1864 95.9033 58.1608 95.9069 58.201C95.9691 58.7018 95.8119 59.2318 95.556 59.6303C95.3476 59.9593 95.0954 60.2334 94.7883 60.4711C94.7079 60.5332 94.5946 60.5186 94.5324 60.4382C94.4739 60.3614 94.4849 60.259 94.5434 60.1969Z" fill="#60859F"/>
    <path d="M70.3543 75.2651C69.9997 74.4097 69.7621 73.536 69.5537 72.6404C69.5318 72.5417 69.5939 72.443 69.6926 72.4211C69.784 72.3992 69.8754 72.454 69.9047 72.5381C70.1971 73.3642 70.5334 74.194 70.9575 74.958C71.1878 75.3711 70.5554 75.6964 70.3543 75.2651Z" fill="#60859F"/>
    <path d="M25.8793 162.244C26.7713 161.33 27.9082 160.35 28.2701 159.097C28.3615 158.365 28.7929 157.729 29.2023 157.141C29.5496 156.651 30.2296 155.818 30.5037 155.562C30.862 154.926 31.202 154.257 31.4506 153.584C31.5785 153.255 31.6553 152.904 31.6699 152.557C31.8929 151.394 32.3718 150.382 32.7958 149.303C33.0846 148.602 33.3881 147.907 33.7024 147.216C34.6164 145.787 35.6144 144.263 36.159 142.669C36.2029 142.526 36.2285 142.391 36.2468 142.252C36.4881 140.611 37.4202 139.196 38.2391 137.792C38.93 137.003 39.6539 136.235 40.4581 135.544C40.5349 135.478 40.6518 135.486 40.7177 135.566C40.7725 135.632 40.7761 135.723 40.7286 135.789C40.1985 136.571 39.3066 137.617 38.7984 138.151C37.9978 139.503 37.0657 140.91 36.8609 142.493C36.8463 142.625 36.8061 142.731 36.7732 142.848C36.1737 144.577 35.2013 146.024 34.2325 147.538C33.7427 148.653 32.9932 150.287 32.5253 151.603C32.3937 151.987 32.2585 152.363 32.2182 152.725C32.2036 153.087 32.094 153.441 31.9697 153.77C31.6882 154.505 31.3299 155.182 30.9424 155.85C30.4708 156.348 30.0139 156.878 29.6008 157.426C29.2608 157.89 28.9245 158.369 28.7637 158.917C28.7088 159.385 28.4968 159.831 28.2555 160.179C27.6487 161.049 26.9066 161.798 26.1279 162.507C25.9524 162.668 25.7002 162.416 25.8793 162.244Z" fill="#60859F"/>
    <path d="M34.4004 149.274C35.0548 148.503 35.7128 147.717 36.1734 146.821C36.433 146.266 36.411 145.699 36.539 145.074C36.634 144.423 37.0142 143.827 37.4456 143.334C38.0305 142.504 38.2937 141.572 38.736 140.614C38.8274 140.417 39.1381 140.523 39.0797 140.738C38.9005 141.74 38.5679 142.833 37.9245 143.692C37.5406 144.134 37.197 144.584 37.1019 145.191C36.9813 145.995 36.846 146.938 36.3014 147.614C35.8152 148.316 35.2558 148.934 34.66 149.526C34.5064 149.691 34.2396 149.446 34.4004 149.274Z" fill="#60859F"/>
    <path d="M54.6163 141.937C52.5875 141.009 50.3721 139.949 49.1731 137.971C48.6357 136.955 47.868 136.056 47.7729 134.835C47.8314 133.702 48.1531 132.63 48.1678 131.497C48.1824 131.271 48.526 131.271 48.5333 131.501C48.6137 132.605 48.3652 133.72 48.3176 134.809C48.3505 135.69 49.2571 136.907 49.6593 137.697C50.1601 138.622 51.0265 139.313 51.9148 139.945C52.8141 140.567 53.7792 141.122 54.7626 141.605C54.9746 141.696 54.8357 142.032 54.6163 141.937Z" fill="#60859F"/>
    <path d="M49.7944 131.567C49.3265 130.382 48.822 128.993 49.3009 127.739C49.5275 127.122 49.8638 126.577 49.7432 126.069C49.6043 125.473 49.4105 124.874 49.1802 124.3C49.0925 124.088 49.4178 123.941 49.5165 124.153C49.8748 125.027 50.478 126.073 50.1051 127.019C49.8967 127.612 49.6006 128.134 49.5933 128.748C49.6116 129.651 49.8675 130.573 50.138 131.439C50.2075 131.651 49.8858 131.779 49.7944 131.567Z" fill="#60859F"/>
    <path d="M57.1977 113.18C59.1498 112.577 61.2664 112.112 63.0906 111.217C64.4103 110.361 65.7263 109.462 67.0936 108.669C69.6269 107.327 71.8934 105.562 74.0905 103.719C74.9605 102.805 75.6734 101.712 76.2656 100.601C76.4996 100.144 76.686 99.6691 76.781 99.1792C76.8139 98.9672 77.1576 98.9964 77.143 99.2194C76.9821 101 75.9366 102.539 74.9093 103.939C73.904 105.024 72.6575 105.861 71.4913 106.753C70.6322 107.375 69.7731 107.985 68.8446 108.537C66.9619 109.517 65.2255 110.628 63.4196 111.74C61.4565 112.664 59.3655 113.008 57.2854 113.531C57.0734 113.578 56.9637 113.253 57.1977 113.18Z" fill="#60859F"/>
    <path d="M102.045 118.627C100.74 117.227 99.4454 115.794 97.9576 114.602C97.6505 114.35 97.3617 114.244 96.9925 114.05C96.2723 113.648 95.6801 113.118 95.0989 112.606C94.5359 112.065 93.9181 111.667 93.2893 111.213C92.0647 110.329 90.829 109.334 89.524 108.618C89.3704 108.53 89.1913 108.472 89.1255 108.457C88.8221 108.442 88.5662 108.285 88.398 108.128C87.5243 107.229 87.1368 106.107 86.5227 105.072C86.1535 104.396 85.7404 103.701 85.4552 102.977C84.3439 100.064 83.4958 96.9532 83.7042 93.8168C83.7371 92.3034 83.536 90.7498 83.3276 89.2474C83.313 89.1487 83.3825 89.0536 83.4812 89.039C83.5799 89.0244 83.6713 89.0902 83.6859 89.1889C83.9528 90.7315 84.205 92.2559 84.2233 93.835C84.1319 95.374 84.3439 96.8618 84.7058 98.3569C85.2395 100.598 85.9158 102.784 87.1222 104.743C87.4987 105.419 87.857 106.114 88.2408 106.761C88.5004 107.152 88.7417 107.653 89.1621 107.755C90.1893 108 90.9862 108.749 91.8234 109.298C92.6057 109.864 93.3734 110.457 94.1484 111.023C95.278 111.729 96.1517 112.887 97.2813 113.538C97.592 113.703 98.0197 113.904 98.3049 114.16C99.8073 115.413 101.069 116.916 102.322 118.396C102.388 118.473 102.377 118.59 102.297 118.652C102.224 118.707 102.11 118.7 102.045 118.627Z" fill="#60859F"/>
    <path d="M92.4525 106.446C90.537 104.735 88.8371 102.794 87.3675 100.682C86.5377 99.3948 85.8138 98.0203 85.0827 96.6751C84.9767 96.4814 85.2618 96.2986 85.3934 96.485C86.1282 97.5195 86.852 98.5906 87.543 99.6543C88.5263 101.12 89.7108 102.433 90.8258 103.8C91.4728 104.582 92.1125 105.379 92.7194 106.201C92.851 106.366 92.6097 106.592 92.4525 106.446Z" fill="#60859F"/>
    <path d="M88.815 110.764C87.3199 109.791 86.1428 108.435 85.1448 106.984L82.1288 102.762C82.1252 102.758 82.2056 102.842 82.202 102.842C81.6097 102.235 81.4343 101.358 81.2954 100.565C80.9517 98.3422 81.3465 96.0649 81.5805 93.8935C81.8437 91.002 81.8218 88.0411 81.3356 85.1751C81.3136 85.0728 81.3794 84.9741 81.4818 84.9558C81.5805 84.9375 81.6719 85.0033 81.6938 85.0984C82.2641 88.0155 82.3628 90.9837 82.1691 93.941C82.0594 95.4288 81.8547 96.8654 81.8693 98.3093C81.9132 99.5961 81.9461 100.971 82.6187 102.089C82.6772 102.177 82.7284 102.224 82.7905 102.29L85.6383 106.662C86.5339 108.102 87.627 109.47 89.0051 110.453C89.2099 110.566 89.0271 110.899 88.815 110.764Z" fill="#60859F"/>
    <path d="M93.9551 88.7754C94.0867 87.1268 94.4596 85.4782 94.2768 83.8332C94.1087 82.7109 93.6883 81.6106 93.8382 80.4189C93.9442 79.7865 94.1928 79.2748 94.4158 78.752C94.7192 77.9625 95.2748 77.1144 95.0592 76.2663C94.5218 75.4036 93.6517 71.3423 92.2662 73.3638C91.6228 74.2192 90.7309 75.0088 90.464 76.0543C90.3324 76.5587 90.3799 77.118 90.336 77.6919C90.252 78.8251 90.0911 79.9401 89.8974 81.055C89.8718 81.2341 89.5866 81.1903 89.6159 81.0111C89.7694 79.9035 89.89 78.7849 89.9303 77.6737C89.9595 77.129 89.8974 76.5514 90.0326 75.9556C90.3031 74.7822 91.1878 73.9633 91.8714 73.0458C92.0323 72.8411 92.237 72.5596 92.5514 72.3951C93.5859 71.8797 94.3207 73.1518 94.6863 73.9231C96.2107 76.9682 95.0665 77.6517 94.5291 80.4994C94.4487 81.8556 95.0738 83.2446 94.9897 84.641C94.88 86.0667 94.5729 87.4521 94.2403 88.8303C94.1964 88.9911 93.9369 88.9582 93.9551 88.7754Z" fill="#60859F"/>
    <path d="M100.32 81.2673C99.4459 81.5232 99.5811 80.2986 99.7091 79.8197C99.8991 79.0411 99.8114 78.4051 99.5884 77.6667C99.413 76.9356 99.5775 76.0619 99.4093 75.3929C99.3581 75.1919 99.274 75.053 99.0949 74.9689C97.8703 74.0696 97.2854 73.6017 96.1777 75.0201C95.9035 75.3491 95.6038 75.9084 95.3917 76.2922C95.3479 76.3872 95.2309 76.4274 95.1358 76.3726C95.0518 76.3251 95.0189 76.2191 95.0591 76.1313L95.4063 75.4331C95.8267 74.7313 96.2325 74.0148 97.0843 73.6931C98.0348 73.3678 98.8134 74.2012 99.5336 74.6253C100.155 75.1736 99.9832 76.1642 100.034 76.8405C100.034 77.3669 100.177 77.78 100.287 78.3064C100.4 78.8657 100.301 79.4286 100.17 79.9294C100.1 80.2511 99.9759 80.602 100.071 80.8908C100.1 80.9603 100.177 80.9274 100.235 80.931C100.444 80.9018 100.51 81.2052 100.32 81.2673Z" fill="#60859F"/>
    <path d="M94.7736 64.0499C94.96 65.5706 95.0514 67.1205 95.4023 68.6119C95.4535 68.9775 95.0404 69.2261 94.8759 69.4088C94.7224 69.5587 94.5981 69.7232 94.5286 69.906C94.3056 71.4011 94.3824 73.0607 94.8649 74.501C94.9563 74.7605 94.5542 74.9067 94.4665 74.6399C93.9876 73.1046 93.9218 71.3536 94.2179 69.7854C94.3532 69.3467 94.8759 69.0506 95.0879 68.7399C95.1245 68.6668 95.0806 68.5827 95.066 68.5096C94.7882 67.0437 94.7078 65.556 94.5615 64.0718C94.5469 63.9366 94.7589 63.911 94.7736 64.0499Z" fill="#60859F"/>
    <path d="M97.0728 73.8756C96.7913 73.1555 96.8644 72.2014 96.1589 71.7408C96.009 71.5544 95.9834 71.357 95.9651 71.1779C95.9249 70.5564 96.0638 69.9862 96.2247 69.405C96.3965 68.3814 95.9615 67.3396 95.563 66.4038C95.4972 66.2722 95.7056 66.1698 95.7677 66.3051C96.2283 67.2811 96.7291 68.3814 96.5391 69.4963C96.4074 70.0264 96.2795 70.6149 96.3161 71.1486C96.3343 71.4191 96.4075 71.5215 96.6085 71.6458C96.9485 71.9638 97.0728 72.439 97.1861 72.8448C97.2629 73.1774 97.3616 73.5028 97.3031 73.8537C97.2848 73.967 97.1093 73.9853 97.0728 73.8756Z" fill="#60859F"/>
    <path d="M94.5652 69.5038C94.5652 69.5038 94.1741 69.0067 93.7866 69.2041C93.7866 69.2041 92.6679 69.778 92.5181 72.5306" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M118.795 64.2215C118.795 64.2215 119.914 61.3885 122.429 60.5697C124.944 59.7509 130.599 58.4641 134.814 59.2391" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M125.562 68.1586C125.562 68.1586 127.762 67.9539 130.482 67.0291C133.202 66.1042 133.428 65.9763 133.428 65.9763C133.428 65.9763 135.666 65.183 139 65.5413" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M130.599 71.6786C130.599 71.6786 135.099 71.1852 136.535 70.2091C137.972 69.2331 141.971 69.7485 141.971 69.7485" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M136.671 74.2012C136.671 74.2012 137.815 74.1061 140.071 73.185C142.326 72.2601 145.13 73.7296 145.13 73.7296" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M142.878 62.4491C142.878 62.4491 141.968 56.4102 140.739 53.8441C139.515 51.2816 135.472 42.6765 134.342 41.5908C133.212 40.5052 127.542 29.7726 122.264 25.2106C122.264 25.2106 120.158 22.8199 119.069 21.4746C117.979 20.1294 109.337 13.4362 109.337 13.4362C109.337 13.4362 101.273 7.75191 97.354 6.44324" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M148.146 77.2644C148.146 77.2644 145.017 73.671 143.496 71.708C141.976 69.7487 135.319 60.2151 135.319 60.2151C135.319 60.2151 130.862 51.2445 128 49.3619" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M145.134 73.7294C145.134 73.7294 145.006 72.2745 145.134 72.0588" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M141.972 69.7487C141.972 69.7487 142.159 68.0781 142.557 67.994" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M138.996 65.5413C138.996 65.5413 139.245 63.3188 139.486 63.6295" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M134.814 59.2393C134.814 59.2393 135.622 56.1285 136.013 56.7901" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M134.503 49.8704C134.145 47.4066 134.814 42.2085 134.814 42.2085C134.814 42.2085 139.077 43.0602 140.027 45.1402" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M129.688 34.4478C130.281 33.596 137.109 38.5127 137.109 38.5127" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M128.271 46.5987C128.271 46.5987 127.134 44.8002 127.265 43.6853C127.397 42.5703 127.671 42.4424 127.763 41.6565C127.854 40.8705 128.271 37.661 128.271 37.661C128.271 37.661 128.845 34.3418 129.455 34.3491" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M135.71 34.5977C135.71 34.5977 133.835 31.3443 131.875 30.127C129.916 28.9098 127.254 27.6376 127.254 27.6376C127.254 27.6376 124.911 26.5812 124.213 27.1368" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M122.992 25.8754C122.992 25.8754 123.584 28.1162 122.992 29.5967C122.399 31.0808 121.657 31.874 121.657 34.0966C121.657 36.3191 121.021 39.0096 121.657 40.4718C122.293 41.934 122.315 42.91 122.315 42.91" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M118.093 20.5206C118.093 20.5206 121.445 21.0141 122.681 20.5206C123.917 20.0271 126.662 20.1221 127.996 21.1676C129.331 22.2131 132.16 23.4413 132.796 25.0498" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M117.326 19.8514C117.326 19.8514 114.675 22.2568 114.544 24.4866C114.409 26.7165 114.01 26.7603 114.01 27.963C114.01 29.1656 113.736 33.534 114.496 35.7236" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M124.721 11.718C124.721 11.718 122.966 11.1112 121.95 11.1806C120.934 11.2501 117.201 11.6449 116.116 11.177C115.03 10.7091 113.469 10.3581 112.182 11.177C110.892 11.9958 108.731 12.1713 109.338 13.4397C109.338 13.4397 107.298 14.5071 106.892 15.9291C106.487 17.3548 106.011 18.6817 105.467 19.8515C104.922 21.0212 104.111 22.2385 104.926 24.4099C105.741 26.5812 106.552 27.5317 106.552 27.5317" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M115.944 5.22584C115.944 5.22584 109.652 3.90255 108.567 4.77255C107.484 5.64622 105.412 5.50365 104.582 7.32044C103.752 9.13722 103.756 9.76962 103.756 9.76962C101.16 9.64899 100.926 12.1969 99.23 14.5729C97.5337 16.949 99.23 22.2202 99.23 22.2202" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M18.7904 71.6644C19.1231 69.6868 19.2693 67.6141 20.1577 65.7827C20.6219 64.5472 20.6183 63.1617 20.6439 61.8494C20.6439 61.7617 20.6366 61.7726 20.6841 61.6995C21.4042 60.6102 22.2048 59.5867 23.2101 58.7459C23.5172 58.4827 23.9303 58.2926 24.2118 58.0148C24.475 57.7553 24.5993 57.4043 24.7053 56.9949C24.932 55.657 25.9811 54.6773 27.034 53.9243C28.3646 52.8605 29.8488 51.9905 31.3294 51.1644L33.5374 49.9069C33.9029 49.6912 34.3087 49.4609 34.7255 49.3695C35.1459 49.2599 35.5663 49.1868 35.9976 49.1429C36.0671 49.1356 36.1329 49.1868 36.1402 49.2562C36.1475 49.3257 36.1 49.3842 36.0342 49.3951C35.431 49.5048 34.7949 49.6072 34.2429 49.874C33.9176 50.0495 31.8777 51.2594 31.5085 51.4715C30.0389 52.3159 28.6022 53.1786 27.2935 54.2533C26.6428 54.7906 25.9446 55.2841 25.568 55.9787C25.1147 56.7134 25.2171 57.6785 24.5591 58.3511C23.9047 58.9031 23.1882 59.3162 22.6435 59.9961C22.0915 60.6102 21.5907 61.2938 21.1484 61.9883C21.1484 61.9883 21.1886 61.8384 21.1886 61.8421C21.1703 63.1325 21.1337 64.4229 20.7572 65.6767C20.1942 66.8209 19.9091 67.9907 19.6824 69.2555C19.5252 70.0779 19.39 70.9077 19.273 71.7339C19.2364 72.0373 18.7502 71.9934 18.7904 71.6644Z" fill="#60859F"/>
    <path d="M77.3068 22.9296C77.3068 22.9296 72.134 22.3118 70.8801 22.2204C69.6262 22.1291 65.9048 21.1055 63.2069 22.2204C60.509 23.3354 57.3651 25.6712 55.9138 25.8759C54.4662 26.0806 49.7065 27.711 48.2954 29.6411C46.8844 31.5712 43.0386 35.3546 41.3899 36.4294C39.7412 37.5041 32.0497 42.45 30.624 44.7566C29.1946 47.0632 24.5812 53.1423 24.5812 53.1423C24.5812 53.1423 20.9548 60.4643 20.9146 61.8424" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M53.4648 38.5127C53.4648 38.5127 56.3345 33.8447 56.5539 30.785C56.7732 27.7254 57.7639 26.6616 57.7639 26.6616C57.7639 26.6616 58.5023 25.0861 58.5535 24.6949" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M66.9835 28.3723C66.9835 28.3723 67.5209 25.6416 68.2374 24.3842C68.9539 23.1267 68.9539 22.2201 68.9539 22.2201C68.9539 22.2201 67.3637 20.8749 66.9798 20.2352C66.596 19.5955 62.2823 17.0805 61.0248 16.7661C59.7672 16.4517 58.0527 16.0825 58.0527 16.0825" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M40.48 24.4245C40.48 24.4245 46.5484 23.4887 49.1146 22.6406C51.6809 21.7926 54.9783 21.6902 56.3931 21.7012C57.8078 21.7121 61.4671 22.6589 63.2072 22.2203" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M54.6894 26.1682C54.6894 26.1682 46.4971 26.033 44.6912 26.6617C42.8853 27.2905 38.7215 29.4107 37.5444 29.5679C36.3672 29.725 34.1885 29.5679 34.1885 29.5679" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M25.3091 40.5416C25.3091 40.5416 30.7524 36.3049 34.1887 35.7236C37.6213 35.1424 40.4801 33.8849 40.4801 33.8849L45.7333 32.5543" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M44.7681 43.8427C44.7681 43.8427 46.34 41.4264 46.34 39.4634C46.34 37.5004 46.8628 34.1775 49.1146 31.3226C51.3665 28.4676 53.4649 26.5668 53.4649 26.5668" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M38.923 38.0592C38.923 38.0592 37.4059 40.4536 37.3547 42.8369C37.3036 45.2203 36.5432 44.8657 36.5432 46.2366C36.5432 47.6074 36.0862 49.3108 35.8267 49.6837" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M129.96 51.3434C129.96 51.3434 117.483 50.9047 112.862 54.1618C108.242 57.4188 105.006 57.9196 105.006 61.2205" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M97.7422 54.553C97.7422 54.553 100.4 47.549 102.739 46.9897C105.083 46.4304 111.729 43.4476 112.405 43.305C113.081 43.1624 118.349 41.4699 121.464 42.5958" stroke="#60859F" stroke-width="0.291469" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M47.4293 136.776C45.3346 134.923 45.2724 131.772 46.0621 129.304C46.753 126.884 46.6177 124.249 46.0548 121.81C45.989 121.591 46.3253 121.47 46.4021 121.701C47.2063 124.205 47.3671 126.924 46.6726 129.48C45.9232 131.793 45.9085 134.641 47.6632 136.498C47.8497 136.644 47.6157 136.937 47.4293 136.776Z" fill="#60859F"/>
    </g>
    <defs>
    <clipPath id="clip0_3137_11438">
    <rect width="153.919" height="186.159" fill="white" transform="translate(0.0405273)"/>
    </clipPath>
    </defs>
    </svg>

</template>

