<template>
    <BreezeAuthenticatedLayout>
        <div class="flex flex-wrap mt-[15px] mb-[8px] px-[24px]">
            <TagText color="text-t_warms-400 w-full mb-[16px]">person</TagText>
            <Heading_H1 :type="true">{{ $page.props.auth.user.name }}</Heading_H1>
            <div class="mb-[16px] mt-[8px] w-full flex flex-row font-fira text-[18px] text-t_black-800 dark:text-t_white-200 items-center">
                <h1 v-if="user_sub_plan === 'none'">Contributor</h1>
                <h1 v-else>Project Manager</h1>

                <span class="ml-[10px] gap-x-2 bg-t_warms-50 rounded-[100px] px-[8px] py-[4px] font-bold justify-center items-center text-t_black-800 font-fira text-[13px] flex flex-row" v-if="user_sub_plan === 'supporter_plan'">
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.45505 13.6106C7.81965 13.9797 8.41151 13.9815 8.77431 13.6119C8.77431 13.6119 10.8342 11.5874 12.8027 9.12466C14.6777 6.77889 15.1465 3.96639 12.8027 2.56014C10.4619 1.15568 8.1152 3.49764 8.1152 3.49764C8.1152 3.49764 5.76933 1.15517 3.4277 2.56014C1.08395 3.96639 1.5527 6.77889 3.4277 9.12466C5.39085 11.5807 7.45505 13.6106 7.45505 13.6106Z" fill="#D44944"/>
                   </svg>
                    Paid supporter
                </span>
                <span class="ml-[10px] gap-x-2 bg-t_green-200 rounded-[100px] px-[8px] py-[4px] font-bold justify-center items-center text-t_black-800 font-fira text-[13px] flex flex-row" v-else-if="user_sub_plan === 'standard_plan'">
                  <svg width="16" height="16" viewBox="0 0 150 144" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M69.0714 4.56231C70.8675 -0.965557 78.688 -0.965561 80.4841 4.5623L93.9961 46.1481C94.7994 48.6203 97.1031 50.294 99.7025 50.294L143.428 50.294C149.241 50.294 151.657 57.7317 146.955 61.1481L111.58 86.8496C109.477 88.3775 108.597 91.0857 109.4 93.5578L122.913 135.144C124.709 140.672 118.382 145.268 113.679 141.852L78.3044 116.15C76.2015 114.623 73.3539 114.623 71.251 116.15L35.876 141.852C31.1737 145.268 24.8468 140.672 26.6429 135.144L40.155 93.5578C40.9582 91.0857 40.0783 88.3775 37.9754 86.8496L2.60035 61.1481C-2.10193 57.7317 0.314715 50.294 6.12706 50.294L49.853 50.294C52.4524 50.294 54.7561 48.6203 55.5593 46.1481L69.0714 4.56231Z" fill="#00BF8C"/>
                  </svg>
                    Paid member
                </span>
                <span class="ml-[10px] bg-t_blue_gray-100 rounded-[100px] px-[8px] py-[4px] font-bold justify-center items-center text-t_black-800 font-fira text-[13px] flex flex-row" v-else>
                    Free member
                </span>
            </div>

            <div class="w-full mt-[16px] mb-[28px]">
                <button @click="visiblemenu = !visiblemenu"
                        class="py-[8px] px-[16px] gap-x-[8px] flex  flex-wrap content-center items-center justify-between rounded-[4px] bg-t_white-400  font-fira text-t_blue-700 dark:bg-t_black-800 dark:text-t_blue-200">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.7344 8.62324C13.7666 8.3823 13.7826 8.13332 13.7826 7.86828C13.7826 7.61127 13.7666 7.35425 13.7264 7.11331L15.3568 5.84432C15.5014 5.73188 15.5415 5.51502 15.4532 5.35439L13.9111 2.68791C13.8148 2.51121 13.614 2.45499 13.4373 2.51121L11.5177 3.28224C11.1161 2.97704 10.6905 2.72003 10.2166 2.52728L9.92747 0.487254C9.89534 0.294496 9.73471 0.157959 9.54195 0.157959L6.45783 0.157959C6.26507 0.157959 6.11247 0.294496 6.08034 0.487254L5.79121 2.52728C5.31734 2.72003 4.88364 2.98508 4.49009 3.28224L2.57054 2.51121C2.39385 2.44696 2.19306 2.51121 2.09668 2.68791L0.562647 5.35439C0.466268 5.52305 0.498394 5.73188 0.659025 5.84432L2.28944 7.11331C2.24928 7.35425 2.21715 7.6193 2.21715 7.86828C2.21715 8.11726 2.23322 8.3823 2.27337 8.62324L0.642962 9.89223C0.498394 10.0047 0.458236 10.2215 0.546583 10.3822L2.08865 13.0486C2.18503 13.2253 2.38582 13.2816 2.56251 13.2253L4.48206 12.4543C4.88364 12.7595 5.30931 13.0165 5.78317 13.2093L6.07231 15.2493C6.11247 15.4421 6.26507 15.5786 6.45783 15.5786H9.54195C9.73471 15.5786 9.89534 15.4421 9.91944 15.2493L10.2086 13.2093C10.6824 13.0165 11.1161 12.7595 11.5097 12.4543L13.4292 13.2253C13.6059 13.2896 13.8067 13.2253 13.9031 13.0486L15.4452 10.3822C15.5415 10.2055 15.5014 10.0047 15.3488 9.89223L13.7344 8.62324ZM7.99989 10.7596C6.40964 10.7596 5.10852 9.45853 5.10852 7.86828C5.10852 6.27802 6.40964 4.97691 7.99989 4.97691C9.59014 4.97691 10.8913 6.27802 10.8913 7.86828C10.8913 9.45853 9.59014 10.7596 7.99989 10.7596Z"
                            class="fill-t_blue-700 dark:fill-t_blue-200"/>
                    </svg>
                    Account settings
                </button>
            </div>

            <div class="my-[16px] flex w-full flex-wrap" v-if="inspection_count > 0">
                <span
                    class="montserrat text-[14px] tracking-widest font-extrabold uppercase text-t_purple-700 dark:text-t_blue-300">Catches</span>
                <hr class="h-[1px] w-full border-0 bg-t_blue_gray-300 dark:bg-gray-700"/>
                <div class="my-[8px] w-full grid grid-cols-4 gap-4 items-stretch">
                    <Link v-for="(data, index) in inspection" :key="index" :href="route('user.settings.view_inspection', data.id)" class="h-[72px] w-[71.5px]">
                        <div class="flex h-full w-full flex-wrap justify-center rounded-[4px] bg-t_white-100 p-[8px] dark:bg-t_black-800">
                            <span class="w-full font-fira text-[11px] font-medium text-t_black-800 dark:text-t_white-200">{{ data.species_caught  }}</span>
                            <RatSmall v-if="data.species_caught === 'Rat'"/>
                            <HedgehogSmall v-else-if="data.species_caught === 'Hedgehog'"/>
                            <MouseSmall v-else-if="data.species_caught === 'Mouse'"/>
                            <StoatSmall v-else-if="data.species_caught === 'Stoat'"/>
                            <WeaselSmall v-else-if="data.species_caught === 'Weasel'"/>
                            <svg v-else width="32" height="46" viewBox="0 0 32 19" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.7804 6.27318C6.04569 8.61538 9.27522 11.0108 12.5135 13.3985C12.8565 13.6515 13.1365 13.85 13.4207 14.1697M11.1301 3.44704C8.41998 7.86231 5.5865 12.2428 2.5341 16.3678C2.33773 16.6332 1.6179 17.3917 2.26317 16.7465M20.2529 2.40738C22.4142 5.61268 24.8628 8.56763 27.4187 11.3782C28.0338 12.0545 28.7302 12.6044 29.1104 13.4771M30 2C26.3576 5.47644 22.5096 8.80894 19.0434 12.5135C18.7045 12.8757 19.3301 12.5058 19.4453 12.4526"
                                    stroke="#D44944" stroke-width="2.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                        <span class="ml-1 font-fira text-[11px] text-t_blue_gray-700 dark:text-t_blue_gray-300">
                            {{data.formatted_date }}
                        </span>
                    </Link>

                    <Link v-if="inspection_count > 3" :href="route('user.settings.view_inspections')"
                          class="flex w-[71.5px] h-full flex-col place-content-center content-center items-center rounded-[4px] bg-t_blue_gray-100 p-[8px] font-medium text-t_black-800 dark:bg-t_black-800 dark:text-t_blue_gray-300">
                        <div class="w-full font-fira text-[20px]">
                            {{ inspection_count - 3 }}
                        </div>
                        <div class="w-full font-fira text-[13px] flex flex-wrap items-center gap-x-1">
                            more
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
                            </svg>
                        </div>
                    </Link>
                </div>
            </div>

            <div class="my-[16px] flex w-full flex-wrap" v-if="traps_count > 0">
                <span
                    class="montserrat text-[14px] tracking-widest font-extrabold uppercase text-t_purple-700 dark:text-t_blue-300">Traps</span>
                <hr class="h-[1px] w-full border-0 bg-t_blue_gray-300 dark:bg-gray-700"/>

                <div class="my-[8px] w-full grid grid-cols-4 gap-4 items-stretch">
                    <Link v-for="(data, index) in traps" :key="index" :href="route('user.settings.trap.view', data.id)"
                          class="w-[71.5px]">
                        <div
                            class="flex h-full items-stretch w-full flex-wrap justify-start rounded-[4px] bg-t_white-100 p-[8px] dark:bg-t_black-800">
                            <span
                                class="w-full font-fira text-[11px] font-medium text-t_black-800 dark:text-t_white-200">{{
                                    trimmedText(data.name)
                                }}</span>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M11.7396 20.3612C12.049 20.5399 12.4302 20.5399 12.7396 20.3612L19.2051 16.6284C19.5145 16.4498 19.7051 16.1197 19.7051 15.7624V8.29675C19.7051 7.93948 19.5145 7.60936 19.2051 7.43072L8.26037 1.11179C7.95096 0.933162 7.56977 0.933162 7.26037 1.1118L0.794922 4.84462C0.485521 5.02325 0.294922 5.35338 0.294922 5.71065V13.1763C0.294922 13.5336 0.485521 13.8637 0.794922 14.0423L11.7396 20.3612ZM17.4654 9.01245L7.76037 3.40922L2.53456 6.42635V12.4606L11.8664 17.8483V11.8141L17.0922 8.79692L17.4654 9.01245V9.87449L12.9862 12.4606L17.4654 15.0467V9.01245Z"
                                      fill="#7A9EB8"/>
                            </svg>
                        </div>
                        <span class="ml-1 font-fira text-[11px] text-t_warms-500 dark:text-t_warms-500">{{
                                data.inspections_count
                            }} catches</span>
                    </Link>

                    <Link v-if="traps_count > 3" :href="route('user.settings.traps')"
                          class="flex h-full w-[71.5px] flex-col rounded-[4px] bg-t_blue_gray-100 p-[8px] font-medium text-t_black-800 dark:bg-t_black-800 dark:text-t_blue_gray-300">
                        <div class="w-full font-fira text-[20px]">
                            {{ traps_count - 3 }}
                        </div>
                        <div class="w-full font-fira text-[13px] flex flex-wrap items-center gap-x-1">
                            more
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
                            </svg>
                        </div>
                    </Link>
                </div>
            </div>

            <div class="w-full mt-[36px]">
                <Link class="w-full" :href="route('donation')">
                    <button
                        class="py-[8px] px-[16px] gap-x-[8px] flex  flex-wrap content-center items-center justify-between rounded-[4px] bg-t_white-400  font-fira text-t_blue-700 dark:bg-t_black-800 dark:text-t_blue-200">
                        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.44701 11.9087L0.487615 3.00011C0.305232 2.58975 0.394414 2.10944 0.711951 1.7919L0.727535 1.77632L6.00664 0.720499C6.10308 0.701211 6.20295 0.708495 6.29557 0.741575L13.4254 3.28793C13.6373 3.36361 13.8204 3.50329 13.9495 3.68763L17.1978 8.32819C17.4162 8.64005 17.4551 9.04348 17.3005 9.39135L15.3791 13.7146L15.1207 12.9396C14.9387 12.3935 14.5471 11.942 14.0322 11.6846L12.6658 11.0014L13.0848 9.74445C13.1586 9.52287 13.0824 9.27888 12.8956 9.13873L11.1826 7.85399C11.0886 7.78354 10.9744 7.74546 10.857 7.74546H7.78197L9.32862 10.3232C9.38171 10.4117 9.45895 10.4832 9.55125 10.5294L13.2416 12.3746C13.5723 12.5399 13.8405 12.8081 14.0058 13.1388C14.4529 14.033 14.0403 15.1183 13.1121 15.4896L12.9165 15.5679C12.4119 15.7697 11.845 15.7461 11.3589 15.5031L4.70021 12.1737C4.58763 12.1174 4.49813 12.0238 4.44701 11.9087Z" fill="#2E526B"/>
                            <path d="M14.5965 17.0497C14.5965 19.4473 12.6529 21.3909 10.2553 21.3909C7.85772 21.3909 5.9141 19.4473 5.9141 17.0497C5.9141 16.1411 6.19327 15.2976 6.67053 14.6004C6.67053 14.6004 10.7979 17.0487 11.8832 17.0487C13.5112 17.0487 14.4081 15.7809 14.4081 15.7809C14.5306 16.1822 14.5965 16.6083 14.5965 17.0497Z" fill="#FFB947"/>
                        </svg>

                        Donate to Trapscan
                    </button>
                </Link>

            </div>

            <div class="w-full mt-[36px] flex flex-wrap justify-start">
                <Link :href="route('logout')" method="post" type="button"
                      class="font-fira font-bold  text-t_purple-200 text-md">
                    <span class="underline">Log out</span>
                </Link>
            </div>
        </div>
        <div v-show="visiblemenu" @click="visiblemenu = !visiblemenu"
             class="fixed inset-0 z-50 flex flex-wrap items-end w-full h-screen overflow-x-hidden overflow-y-auto bg-t_blue_gray-300 bg-opacity-80 dark:bg-t_blue-800 dark:bg-opacity-80">
            <div
                class="flex  py-[21px] w-full flex-wrap rounded-t-[5px] bg-t_white-150 px-[24px] dark:bg-t_black-900 dark:text-gray-300">
                <div class=" flex w-full flex-wrap items-center justify-center mb-[21px]">
                    <svg width="64" height="4" viewBox="0 0 64 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2H62" stroke="#7A9EB8" stroke-width="3" stroke-linecap="round"/>
                    </svg>
                </div>
                <TagText class="w-full">Account</TagText>
                <div class="w-full grid grid-cols-2 mt-[16px] gap-y-[16px] gap-x-[16px]">
                    <body-text>User name</body-text>
                    <div>
                        <Link :href="route('user.settings.edit')" method="get" type="button"
                              class="flex flex-wrap gap-x-3 justify-between">
                            {{ $page.props.auth.user.name }}
                            <arrow-next/>
                        </Link>
                    </div>
                    <body-text>Account type</body-text>
                    <div>
                        <h1 v-if="user_sub_plan === 'none'">Contributor</h1>
                        <h1 v-else>Project Manager</h1>
                    </div>
                    <body-text>Membership</body-text>
                    <div>
                        <Link :href="route('billing_select_plan')" method="get" type="button" class="flex flex-wrap gap-x-3 justify-between">
                        <span v-if="user_sub_plan === 'supporter_plan'">Paid supporter</span>
                        <span v-else-if="user_sub_plan === 'standard_plan'">Paid member</span>
                        <span v-else>Free member</span>
                            <arrow-next/>
                        </Link>
                    </div>
                </div>
                <div class="mt-[16px]" v-if="user_sub_plan !== 'supporter_plan'">
                    <Link :href="route('billing_select_plan')"
                          class="py-[8px] gap-x-[8px] px-[16px] flex  flex-wrap content-center items-center justify-between rounded-[4px] bg-t_white-400  font-fira text-t_blue-700 dark:bg-t_black-800 dark:text-t_blue-200">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.1543 10H18.1543M15.1543 14H17.1543M21.1543 7V16C21.1543 17.1046 20.2589 18 19.1543 18H5.1543C4.04973 18 3.1543 17.1046 3.1543 16V7C3.1543 5.89543 4.04973 5 5.1543 5H10.1543H19.1543C20.2589 5 21.1543 5.89543 21.1543 7Z"
                                class="stroke-t_blue-700 dark:stroke-t_blue-200" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                            <g clip-path="url(#clip0_3870_8382)">
                                <path
                                    d="M8.49694 8.08382C8.62676 7.68428 9.19201 7.68428 9.32183 8.08383L9.90065 9.86526C9.95871 10.0439 10.1252 10.1649 10.3131 10.1649H12.1862C12.6063 10.1649 12.781 10.7025 12.4411 10.9494L10.9257 12.0504C10.7737 12.1609 10.7101 12.3566 10.7682 12.5353L11.347 14.3167C11.4768 14.7163 11.0195 15.0485 10.6797 14.8016L9.16429 13.7006C9.01229 13.5901 8.80648 13.5901 8.65448 13.7006L7.1391 14.8016C6.79923 15.0485 6.34193 14.7163 6.47175 14.3167L7.05058 12.5353C7.10863 12.3566 7.04503 12.1609 6.89304 12.0504L5.37766 10.9494C5.03779 10.7025 5.21246 10.1649 5.63256 10.1649H7.50567C7.69355 10.1649 7.86006 10.0439 7.91812 9.86526L8.49694 8.08382Z"
                                    class="fill-t_blue-700 dark:fill-t_blue-200"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_3870_8382">
                                    <rect width="9.69767" height="9.69767" class="fill-t_blue-700 dark:fill-t_blue-200"
                                          transform="translate(4.06055 6.81445)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        Upgrade membership
                    </Link>
                </div>
                <TagText class="mt-[48px] w-full">preferences</TagText>
                <div class="w-full justify-between flex flex-wrap items-center mt-[16px] mb-[30px]">
                    <body-text>Dark mode</body-text>
                    <CheckBox v-model="darkModeEnabled"/>
                </div>
            </div>
        </div>
    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import {Link} from '@inertiajs/vue3'
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import BodyText from "@/UI/Text/BodyText.vue";
import CheckBox from "@/UI/CheckBox.vue";
import ArrowNext from "@/Components/SVG/ArrowNext.vue";
import TagText from "@/UI/Text/TagText.vue";
import RatSmall from "@/Components/SVG/AnimalsSmall/Rat.vue";
import HedgehogSmall from "@/Components/SVG/AnimalsSmall/Hedgehog.vue";
import MouseSmall from "@/Components/SVG/AnimalsSmall/Mouse.vue";
import StoatSmall from "@/Components/SVG/AnimalsSmall/Stoat.vue";
import WeaselSmall from "@/Components/SVG/AnimalsSmall/Weasel.vue";

export default {
    components: {
        Link,
        BreezeAuthenticatedLayout,
        Heading_H1,
        BodyText,
        CheckBox,
        ArrowNext,
        TagText,
        RatSmall,
        HedgehogSmall,
        MouseSmall,
        StoatSmall,
        WeaselSmall
    },
    name: "UserIndex",
    props: {
        inspection: Object,
        traps: Object,
        user_sub_plan: String,
        inspection_count: Number,
        traps_count: Number

    },
    data() {
        return {
            usr_s: null,
            darkModeEnabled: localStorage.getItem('color-theme') ?? 'dark',
            visiblemenu: false
        }
    },

    watch: {
        darkModeEnabled() {
            this.toggleDarkMode()
        }
    },
    methods: {
        trimmedText(edit_text) {
            let text = '';
            if (edit_text.length > 8) {
                text = edit_text.substring(0, 8) + "...";
            } else {
                return text = edit_text;
            }

            return text
        },
        toggleDarkMode() {
            if (localStorage.getItem('color-theme')) {
                if (localStorage.getItem('color-theme') === 'light') {
                    document.documentElement.classList.add('dark');
                    localStorage.setItem('color-theme', 'dark');
                    this.darkModeEnabled = true;
                } else {
                    document.documentElement.classList.remove('dark');
                    localStorage.setItem('color-theme', 'light');
                    this.darkModeEnabled = false;
                }
            } else {
                if (document.documentElement.classList.contains('dark')) {
                    document.documentElement.classList.remove('dark');
                    localStorage.setItem('color-theme', 'light');
                    this.darkModeEnabled = false;
                } else {
                    document.documentElement.classList.add('dark');
                    localStorage.setItem('color-theme', 'dark');
                    this.darkModeEnabled = true;
                }
            }
        }
    }
}
</script>
<style scoped>

</style>
