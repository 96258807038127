<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import { Link } from '@inertiajs/vue3'
import Camera from "@/Components/Scan/Camera.vue";
import Location from "@/Components/Scan/Location.vue";

export default {
    components:{
        Location,
        Camera,
        Link,
        BreezeAuthenticatedLayout
    },
    data() {
        return {
            openTab:1
        }
    },
    watch: {

    },
    mounted() {

    },
    methods: {
        toggleTabs: function(tabNumber){
            this.openTab = tabNumber
        }
    }
}
</script>

<template>
    <BreezeAuthenticatedLayout>
        <div v-if="$page.props.flash.message" class="alert mb-3">
            <el-alert :title="$page.props.flash.message" effect="dark"  type="error" />
        </div>
        <Camera/>

    </BreezeAuthenticatedLayout>

</template>

