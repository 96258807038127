<template>
    <svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.8 17.8592L2.0625 16.5703" stroke="#8A8A8A" stroke-opacity="0.75" stroke-width="2" stroke-linecap="round"/>
        <path d="M1.2479 12.2554C1.27873 11.7343 1.30392 11.2122 1.50443 10.7539" stroke="#8A8A8A" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round"/>
        <path d="M3.64164 6.55562C3.91362 6.13556 4.19257 5.73202 4.47867 5.329" stroke="#8A8A8A" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"/>
        <path d="M8.6712 3.98398C9.20911 3.87221 9.71565 3.77335 10.2557 3.79472" stroke="#8A8A8A" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"/>
        <path d="M14.5306 3.07947C15.9557 2.53785 15.8347 2.42184 16.3771 2.27188" stroke="#8A8A8A" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"/>
        <path d="M21.0293 1.90625C21.8848 1.95837 22.63 1.98675 23.3712 2.50281" stroke="#8A8A8A" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"/>
        <path d="M25.7607 4.68066C26.2043 5.29622 26.2043 5.29622 26.7349 6.17083" stroke="#8A8A8A" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"/>
        <path d="M28.1499 10.2588C28.2145 10.8528 28.2489 11.4309 28.277 12.0275" stroke="#8A8A8A" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round"/>
        <path d="M6.13184 18.709L7.50954 18.7635" stroke="#8A8A8A" stroke-opacity="0.85" stroke-width="2" stroke-linecap="round"/>
        <path d="M10.6586 18.8877C11.2641 18.8736 11.8211 18.8701 12.4325 18.8943" stroke="#8A8A8A" stroke-opacity="0.85" stroke-width="2" stroke-linecap="round"/>
        <path d="M15.8613 18.9209C16.2536 18.9823 17.3495 19.1183 17.7445 19.1677" stroke="#8A8A8A" stroke-opacity="0.85" stroke-width="2" stroke-linecap="round"/>
        <path d="M20.8521 19.1885L22.8613 19.3717" stroke="#8A8A8A" stroke-opacity="0.85" stroke-width="2" stroke-linecap="round"/>
        <path d="M26.0599 18.2377C26.455 17.8732 26.0599 18.2377 27.2426 17.3657" stroke="#8A8A8A" stroke-opacity="0.75" stroke-width="2" stroke-linecap="round"/>
    </svg>

</template>

