<template>
    <svg width="45" height="25" viewBox="0 0 45 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_0_1)">
            <rect x="5.36914" y="1.5" width="15" height="22" rx="0.819393" fill="white" stroke="#7A9EB8" stroke-width="1.5"/>
            <path d="M12.7625 6.68623V7.26799M16.2531 13.6674H17.4166M13.9261 13.6674H12.7625V15.9945M12.7625 9.59505V11.3403M12.7625 11.3403H12.7683M12.7625 11.3403H15.0954M15.0896 15.9945H17.4166M8.1084 11.3403H10.4355M17.4166 11.3403H17.4225M15.6713 9.01329H17.4166V6.6861L15.0954 6.68623C15.0896 6.68616 15.0954 6.68587 15.0896 6.68616V8.43152C15.0896 8.75282 15.35 9.01329 15.6713 9.01329ZM9.85395 9.01342H8.10865V6.68623L10.4299 6.68636C10.4357 6.68629 10.4299 6.686 10.4357 6.68629V8.43165C10.4357 8.75295 10.1752 9.01342 9.85395 9.01342ZM9.85977 13.6672H8.11447V15.9944L10.4357 15.9943C10.4415 15.9943 10.4357 15.9946 10.4415 15.9943V14.249C10.4415 13.9277 10.1811 13.6672 9.85977 13.6672Z" stroke="#1A2B3D" stroke-width="1.16426" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3622 6.79289C26.7528 6.40237 27.3859 6.40237 27.7764 6.79289L32.0693 11.0858L36.3622 6.79289C36.7528 6.40237 37.3859 6.40237 37.7764 6.79289C38.167 7.18342 38.167 7.81658 37.7764 8.20711L33.4835 12.5L37.7764 16.7929C38.167 17.1834 38.167 17.8166 37.7764 18.2071C37.3859 18.5976 36.7528 18.5976 36.3622 18.2071L32.0693 13.9142L27.7764 18.2071C27.3859 18.5976 26.7528 18.5976 26.3622 18.2071C25.9717 17.8166 25.9717 17.1834 26.3622 16.7929L30.6551 12.5L26.3622 8.20711C25.9717 7.81658 25.9717 7.18342 26.3622 6.79289Z" fill="#D44944"/>
        <defs>
            <clipPath id="clip0_0_1">
                <rect width="24" height="24" fill="white" transform="translate(0.869141 0.5)"/>
            </clipPath>
        </defs>
    </svg>
</template>
