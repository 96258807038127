<script >
import {Link} from "@inertiajs/vue3";
export default {
    components:{
        Link
    },
    data() {
        return {
            them:localStorage.getItem('color-theme')
        }
    },
    watch: {

    },
    mounted() {
        if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark')
        }
    },
    methods: {
        toggleDarkMode() {
            if (localStorage.getItem('color-theme')) {
                if (localStorage.getItem('color-theme') === 'light') {
                    document.documentElement.classList.add('dark');
                    localStorage.setItem('color-theme', 'dark');
                    this.them = 'dark';
                } else {
                    document.documentElement.classList.remove('dark');
                    localStorage.setItem('color-theme', 'light');
                    this.them = 'light';
                }

            } else {
                if (document.documentElement.classList.contains('dark')) {
                    document.documentElement.classList.remove('dark');
                    localStorage.setItem('color-theme', 'light');
                    this.them = 'light';
                } else {
                    document.documentElement.classList.add('dark');
                    localStorage.setItem('color-theme', 'dark');
                    this.them = 'dark';

                }
            }
        }
    }
}


</script>

<template>
    <div :class="{'bg-gradient-to-b from-t_black-900 to-t_purple-700':route().current('billing'), 'bg-t_blue_gray-100 dark:bg-t_black-900 dark:text-gray-300':!route().current('billing')}"  class="min-h-screen ">
        <nav class="bg-t_green-700 bg-opacity-90 dark:bg-opacity-100 h-[44px] flex items-center justify-between flex-wrap w-full shadow-2xl px-4 mb-[24px]">
            <!-- Primary Navigation Menu -->
            <div class="flex ">
                <!-- Logo -->
                <div class="flex items-center shrink-0">
                    <Link :href="route('login')">
                        <div class="flex flex-wrap justify-between">
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2433_1099)">
                                    <path d="M8.52121 22.6856L8.52122 24.4172L2.54443 24.4172L2.54443 18.5298L4.30229 18.5298L4.3023 22.6856L8.52121 22.6856Z" fill="black"/>
                                    <path d="M16.2559 2.14857L16.2559 0.416992L22.2326 0.416991L22.2326 6.3044L20.4748 6.3044L20.4748 2.14857L16.2559 2.14857Z" fill="black"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.498 22.4399L22.2326 18.0411L22.2326 9.2435L10.2791 2.44531L2.54443 6.84413L2.54444 15.6418L14.498 22.4399ZM19.42 10.8284L10.2791 5.62983L5.35702 8.42913L5.35702 14.0276L14.498 19.2262L14.498 13.6423L19.42 16.427L19.42 10.8284Z" fill="black"/>
                                    <path d="M14.499 13.6416L19.4211 10.8423L19.4211 16.4408L14.499 13.6416Z" fill="#ABC2D2"/>
                                    <path d="M10.2775 5.62891L5.35547 8.42821L5.35547 14.0267L14.4964 19.2253L14.4964 13.6414L19.4185 10.8275L10.2775 5.62891Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_2433_1099">
                                        <rect width="24" height="24" fill="white" transform="translate(0.544434 0.416992)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span class="text-black font-bold ml-1 text-[16px] font-fira">
                                TrapScan
                            </span>
                        </div>

                    </Link>
                </div>
            </div>
            <Link v-if="!$page.props.auth.user" class="text-t_black text-[16px] font-fira font-bold underline" :href="route('login')">Log in</Link>
            <Link v-else class="text-t_black text-[16px] font-fira font-bold underline" :href="route('scan')">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 6.29289C6.68342 5.90237 7.31658 5.90237 7.70711 6.29289L12 10.5858L16.2929 6.29289C16.6834 5.90237 17.3166 5.90237 17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.7071L13.4142 12L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L12 13.4142L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.2929 17.7071C5.90237 17.3166 5.90237 16.6834 6.2929 16.2929L10.5858 12L6.29289 7.70711C5.90237 7.31658 5.90237 6.68342 6.29289 6.29289Z" fill="black"/>
                </svg>
            </Link>

        </nav>
        <!-- Page Content -->
        <main class="container max-w-lg mx-auto">
            <slot />
        </main>

    </div>
</template>
