<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4.5" y="1" width="15" height="22" rx="0.819393" fill="white" stroke="#7A9EB8" stroke-width="1.5"/>
        <path d="M11.8939 6.18623V6.76799M15.3845 13.1674H16.548M13.0574 13.1674H11.8939V15.4945M11.8939 9.09505V10.8403M11.8939 10.8403H11.8997M11.8939 10.8403H14.2267M14.2209 15.4945H16.548M7.23975 10.8403H9.56681M16.548 10.8403H16.5538M14.8027 8.51329H16.548V6.1861L14.2267 6.18623C14.2209 6.18616 14.2267 6.18587 14.2209 6.18616V7.93152C14.2209 8.25282 14.4814 8.51329 14.8027 8.51329ZM8.9853 8.51342H7.24V6.18623L9.56124 6.18636C9.56706 6.18629 9.56124 6.186 9.56706 6.18629V7.93165C9.56706 8.25295 9.3066 8.51342 8.9853 8.51342ZM8.99111 13.1672H7.24582V15.4944L9.56706 15.4943C9.57288 15.4943 9.56706 15.4946 9.57288 15.4943V13.749C9.57288 13.4277 9.31241 13.1672 8.99111 13.1672Z" stroke="#1A2B3D" stroke-width="1.16426" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "QrCode"
}
</script>

<style scoped>

</style>
