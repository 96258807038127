<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3392_9821)">
            <path d="M19.5859 16.2339L17.0942 18.7256L24.1373 25.7687L26.629 23.277L19.5859 16.2339Z" fill="#60859F"/>
            <path d="M21.4607 12.6509C23.7277 12.6509 25.5718 10.8068 25.5718 8.53984C25.5718 7.85858 25.3838 7.2243 25.0902 6.6605L21.9188 9.83188L20.1687 8.08175L23.34 4.91037C22.7762 4.61672 22.142 4.42879 21.4607 4.42879C19.1938 4.42879 17.3496 6.27289 17.3496 8.53984C17.3496 9.02142 17.4436 9.47951 17.5963 9.90236L15.4233 12.0753L13.3326 9.98458L14.1665 9.15062L12.5104 7.49446L15.0005 5.00433C13.6262 3.63007 11.3945 3.63007 10.0202 5.00433L5.86219 9.16237L7.51836 10.8185H4.20603L3.37207 11.6525L7.53011 15.8105L8.36406 14.9766V11.6525L10.0202 13.3087L10.8542 12.4747L12.945 14.5655L4.24126 23.2692L6.73139 25.7593L20.0982 12.4042C20.521 12.5569 20.9791 12.6509 21.4607 12.6509Z" fill="#60859F"/>
        </g>
        <defs>
            <clipPath id="clip0_3392_9821">
                <rect width="28.1901" height="28.1901" fill="white" transform="translate(0.905273 0.905273)"/>
            </clipPath>
        </defs>
    </svg>

</template>

