<script>
import { Link } from '@inertiajs/vue3'
import Primary from "@/UI/Buttons/Primary.vue";

export default {
    props:{
        trap_data:Object
    },
    components:{
        Link,
        Primary
    },
    name: "FinishCrateTrap",
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>
<template>
<div class="min-h-screen h-full bg-t_blue_gray-100  dark:bg-t_black-900 pb-[30px]">
        <nav class="bg-t_blue-600 h-[44px] flex items-center justify-between flex-wrap w-full shadow-2xl px-4">
            <div></div>
            <div>
                <h1 class="text-white font-bold ml-1 text-[16px] font-fira tracking-wide">Create Trap</h1>
            </div>
            <div>
                <Link :href="route('scan')">
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.837327 0.709641C1.22785 0.319117 1.86102 0.319117 2.25154 0.709641L6.54443 5.00253L10.8373 0.709639C11.2279 0.319115 11.861 0.319115 12.2515 0.709639C12.6421 1.10016 12.6421 1.73333 12.2515 2.12385L7.95865 6.41675L12.2515 10.7096C12.6421 11.1002 12.6421 11.7333 12.2515 12.1239C11.861 12.5144 11.2279 12.5144 10.8373 12.1239L6.54443 7.83096L2.25154 12.1239C1.86102 12.5144 1.22785 12.5144 0.837329 12.1239C0.446804 11.7333 0.446804 11.1002 0.837329 10.7096L5.13022 6.41675L0.837327 2.12385C0.446803 1.73333 0.446803 1.10017 0.837327 0.709641Z" fill="white"/>
                    </svg>
                </Link>
            </div>
        </nav>
        <main class="h-full w-full flex flex-wrap  mx-auto justify-center overflow-auto">
            <div class="h-[116px] justify-center flex items-center w-full bg-t_blue-800">
                <svg width="75" height="54" viewBox="0 0 75 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.55371 26.8481L27.6313 46.9258L67.4828 7.07432" stroke="#BBD6FB" stroke-width="10" stroke-linecap="square"/>
                </svg>
            </div>
            <div class="flex justify-center flex-col w-full bg-light_menu_bar_bg dark:bg-t_black-800 h-[50px]">
                <h1 class="text-[18px] font-fira font-semibold text-black  text-center dark:text-t_white-150">Trap Created</h1>
            </div>
            <div class="flex justify-center flex-col w-full mt-6">
                <div class="flex flex-wrap mt-[32px] ml-2 px-4 text-t_black-800 dark:text-t_white-150 font-fira text-[18px]">
                    <span class="w-full">Trap name</span>
                    <span class="w-full font-bold capitalize">{{ trap_data.name }}</span>
                </div>
                <div class="flex flex-wrap mt-3 ml-2 px-4 text-t_black-800 dark:text-t_white-150 font-fira text-[18px]">
                    <span class="w-full">QR ID  </span>
                    <span class="w-full font-bold capitalize">{{ trap_data.qr_id }}</span>
                </div>
                <div class="flex flex-wrap mt-3 ml-2 px-4 text-t_black-800 dark:text-t_white-150 font-fira text-[18px]">
                    <span class="w-full">Trap number </span>
                    <span class="w-full font-bold capitalize">{{ trap_data.id }}</span>
                </div>
                <div class="flex flex-wrap mt-3 ml-2 px-4 text-t_black-800 dark:text-t_white-150 font-fira text-[18px]">
                    <span class="w-full">Owner</span>
                    <span class="w-full font-bold capitalize">{{ trap_data?.owner?.name ?? 'None'}}</span>
                </div>
                <div class="flex flex-wrap mt-3 ml-2 px-4 text-t_black-800 dark:text-t_white-150 font-fira text-[18px]">
                    <span class="w-full">Project</span>
                    <span class="w-full font-bold capitalize">{{ trap_data?.project?.name ?? 'None'}}</span>
                </div>
                <div class="flex flex-wrap mt-3 ml-2 px-4 text-t_black-800 dark:text-t_white-150 font-fira text-[18px]">
                    <span class="w-full">Latitude</span>
                    <span class="w-full font-bold capitalize">{{ trap_data?.coordinates?.coordinates[1]  ?? ''}}</span>
                </div>
                <div class="flex flex-wrap mt-3 ml-2 px-4 text-t_black-800 dark:text-t_white-150 font-fira text-[18px]">
                    <span class="w-full">Longitude</span>
                    <span class="w-full font-bold capitalize">{{ trap_data?.coordinates?.coordinates[0] ?? ''}}</span>
                </div>
                <div class="flex flex-wrap mt-3 ml-2 px-4 text-t_black-800 dark:text-t_white-150 font-fira text-[18px]">
                    <span class="w-full">Altitude</span>
                    <span class="w-full font-bold capitalize">{{ trap_data?.altitude ?? ''}}</span>
                </div>
            </div>
            <div class="flex mt-[32px] justify-center flex-wrap w-full">
                <Link :href="route('scan')" class="w-10/12">
                    <primary>next</primary>
                </Link>
            </div>
        </main>

    </div>
</template>
