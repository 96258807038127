<script setup>
defineProps({errors: Object})
</script>

<template>
    <div v-if="Object.keys(errors).length > 0">
        <div class="font-medium text-t_warms-500">Whoops! Something went wrong.</div>

        <ul class="mt-3 list-disc list-inside text-sm text-t_warms-500">
            <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
        </ul>
    </div>
</template>
