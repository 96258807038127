<template>
    <BreezeAuthenticatedLayout>
        <div class="flex flex-wrap justify-end ">
            <img src="../../assets/pr_bg.png" class="h-[177px]  object-right absolute top-[100px]">
        </div>
        <div class="flex flex-wrap mt-[15px] mb-[8px] px-[24px] z-10">
            <Link :href="route('user.projects.view',project.id)" class="flex flex-wrap items-center justify-start w-full ">
                <BlueCircle/>
                <span class="font-bold montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">Back - </span>
                <span  class=" montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white ">
                {{project.name ?? ''}}
              </span>
            </Link>
            <div class="w-full mt-[24px] flex flex-wrap">
                <TitleText class="w-full">Import new Trap.NZ traps</TitleText>

                <p class="text-t_black-800 font-bold font-fira text-[18px] mt-[24px] dark:text-t_white-200">How this button works</p>
                <p class="text-t_blue_gray-700 font-fira text-[18px] mt-[24px] dark:text-t_white-200">
                    If new traps are added to the above Trap.NZ project, pressing this will import them into TrapScan so that QR cards can be mapped to them. This button will also update any traps that have changed in Trap.NZ such as name and location changes.
                </p>
                <p class="text-t_blue_gray-700 font-fira text-[18px] mt-[24px] dark:text-t_white-200">
                    ⚠️ This can only be used once per hour
                </p>

                <primary @click="syncPr()" class="mt-[28px]" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    sync trap.nz traps
                </primary>
            </div>

            <modal-window :showing="visible_sync">
                <h1 class="mb-3 text-xl font-bold text-gray-800 montserrat">Synchronising     ......</h1>
                <div class="flex w-full mx-auto">
                    <button  @click="visible_sync = false" class="flex items-center justify-center w-full py-2 mt-2 text-sm font-bold transition duration-150 ease-in border-2 rounded-full focus:outline-none text-bay-of-many-500 dark:text-mystic-100 dark:border-mystic-100 sm:text-base border-bay-of-many-500 hover:bg-bay-of-many-500 hover:text-white">
                        <span class="mr-2 uppercase">Close</span>
                    </button>
                </div>
            </modal-window>
            <modal-window :showing="visible_sync_done">
                <h1 class="mb-3 text-xl font-bold text-green-500 montserrat">Synchronisation complete</h1>
                <p>
                    {{msg}}
                    <g-emoji class="g-emoji" alias="heavy_check_mark" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/2714.png"><img class="emoji" alt="heavy_check_mark" height="20" width="20" src="https://github.githubassets.com/images/icons/emoji/unicode/2714.png"></g-emoji>
                </p>
                <div class="flex w-full mx-auto">
                    <button  @click="visible_sync_done = false" class="flex items-center justify-center w-full py-2 mt-2 text-sm font-bold transition duration-150 ease-in border-2 rounded-full focus:outline-none text-bay-of-many-500 dark:text-mystic-100 dark:border-mystic-100 sm:text-base border-bay-of-many-500 hover:bg-bay-of-many-500 hover:text-white">
                        <span class="mr-2 uppercase">Finish</span>
                    </button>
                </div>
            </modal-window>
        </div>

    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import {Link, useForm} from '@inertiajs/vue3'
import BlueCircle from "@/Components/SVG/BlueCircle.vue";
import ModalWindow from "@/Components/ModalWindow.vue";
import TitleText from "@/UI/Text/TitleText.vue";
import Primary from "@/UI/Buttons/Primary.vue";

export default {
    components:{
        Link,
        BreezeAuthenticatedLayout,
        BlueCircle,
        ModalWindow,
        TitleText,
        Primary
    },
    name: "SyncTrapNZ",
    props:{
        project:Object,
    },
    data() {
        return {
            visible_sync:false,
            visible_sync_done:false,
            msg:null,
            form:useForm({
                projectId:this.project.id
            }),
        }
    },
    mounted() {
        if (this.$page.props.auth.user !== null){
            Echo.private('App.Models.User.' + this.$page.props.auth.user.id ?? 0)
                .notification((notification) => {
                    this.msg = notification.message
                    this.visible_sync = false;
                    this.visible_sync_done = false;
                    this.visible_sync_done = true;
                });
        }
    },
    methods: {
        syncPr(){
            this.visible_sync_done = false
            this.visible_sync = true
            this.form.projectId = this.project.id;
            this.form.post(route('user.projects.sync'))
        },
    }
}
</script>
<style scoped>

</style>
