<template>
    <span :class="`
    font-fira
    tracking-[2%]
    `+ text + `
    leading-[120%] `+ color + ` `+ font_style">
       <slot/>
    </span>
</template>

<script>
export default {
    props:{
        color:{
            type:String,
            default:"text-t_purple-700 dark:text-t_blue-200"
        },
        font_style:{
            type:String,
            default:"font-semibold"
        },
        text:{
            type:String,
            default:"text-[24px]"
        },
    },
    name: "Tag"
}
</script>

<style scoped>

</style>
