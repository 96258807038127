<template>
    <BreezeAuthenticatedLayout>
        <div class="flex flex-wrap mt-[15px] mb-[8px] px-[24px]">
            <Link :href="route('user.settings.trap.view', trap.id)" class="flex flex-wrap items-center justify-start w-full ">
            <svg width="32" height="32" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2440_1962)">
                    <rect class="fill-[#232F80] dark:fill-[#2F45C5]" x="40.1875" y="40.6123" width="40" height="40" rx="20"
                        transform="rotate(180 40.1875 40.6123)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M23.0362 26.2608C23.5049 25.7922 23.5049 25.0324 23.0362 24.5638L19.0848 20.6123L23.0362 16.6608C23.5049 16.1922 23.5049 15.4324 23.0362 14.9638C22.5676 14.4951 21.8078 14.4951 21.3392 14.9638L16.5392 19.7638C16.0705 20.2324 16.0705 20.9922 16.5392 21.4608L21.3392 26.2608C21.8078 26.7295 22.5676 26.7295 23.0362 26.2608Z"
                        fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_2440_1962">
                        <rect width="40" height="40" fill="white" transform="translate(0.1875 0.612305)" />
                    </clipPath>
                </defs>
            </svg>
            <span class="font-bold montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">Back - </span>
            <span class=" montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">
                {{ trap.name }}
            </span>
            </Link>
            <div class="w-full mt-[16px]">
                <span
                    class="text-[24px] mb-[40px] mfont-bold text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                    Edit Trap
                </span>
            </div>
            <div class="mt-[16px] w-full">
                <ts-input v-model="form.trap_name" label="Trap name" />
                <div class="flex flex-col">
                    <BreezeValidationErrors :errors="$page.props.errors" class="mb-4" />
                </div>
                <primary @click="submit" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    save changes
                </primary>
            </div>
        </div>
    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import { Link, useForm } from '@inertiajs/vue3'
import Primary from "@/UI/Buttons/Primary.vue";
import TsInput from "@/Components/Input.vue";
import BreezeValidationErrors from '@/Components/ValidationErrors.vue';

export default {
    components: {
        Link,
        BreezeAuthenticatedLayout,
        Primary,
        TsInput,
        BreezeValidationErrors
    },
    name: "EditTrap",
    props: {
        trap: Object,
    },
    data() {
        return {
            form: useForm({
                trap_name: this.trap.name
            })
        }
    },
    mounted() {
    },
    methods: {
        submit() {
            this.form.put(route('user.settings.trap.update_trap', this.trap))
        }
    }
}
</script>
<style scoped></style>
