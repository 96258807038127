<template>
    <h1 v-if="type" class="text-t_purple-700 text-[36px] dark:text-t_blue-200 font-fira tracking-wide text font-bold text-3xl  mb-2">
       <slot/>
    </h1>
    <h2 v-else class="text-[24px] font-semibold text-t_purple-700 dark:text-t_white-200 font-fira">
        <slot/>
    </h2>
</template>

<script>
export default {
    props:{
      type:{
          type:Boolean,
          default:false
      }
    },
    name: "Heading_H1"
}
</script>

<style scoped>

</style>
