<template>
    <div class="line-chart">
        <svg :width="width" :height="height">
            <!-- Draw axis -->
            <line
                x1="0"
                :x2="width"
                :y1="height - paddingBottom"
                :y2="height - paddingBottom"
                class="axis stroke-t_blue_gray-300 dark:stroke-t_blue_gray-300"
            />
            <line
                :x1="0"
                :x2="0"
                y1="0"
                :y2="height - paddingBottom"
                class="axis stroke-t_blue_gray-300 dark:stroke-t_blue_gray-300 "
            />

            <!-- Draw lines -->
            <path :d="linePath(dataSet1)" :class='lineColor1+"  stroke-2 fill-none"' v-if="dataSet1.length > 0" />
            <path :d="linePath(dataSet2)" :class='lineColor2+"  stroke-2 fill-none"' v-if="dataSet2.length > 0" />

            <!-- Draw labels on X axis for the first and last elements -->
            <text :x="getXPosition(0)" :y="height - 5" class="font-fira text-[12px] font-normal fill-t_blue_gray-300" v-if="shouldShowXLabel(0)">
                {{ xLabels[0] }}
            </text>
            <text :x="getXPosition(xLabels.length - 1) - 50" :y="height - 5" class="font-fira text-[12px] font-normal fill-t_blue_gray-300" v-if="shouldShowXLabel(xLabels.length - 1)">
                {{ xLabels[xLabels.length - 1] }}
            </text>

            <!-- Draw labels on Y axis -->
            <text :x="width - paddingLeft " :y="(isNaN(getYPosition(maxValue)) ? 20 : getYPosition(maxValue) + 15)" class="font-fira text-[12px] font-normal fill-t_blue_gray-300">
                {{ maxValue }}
            </text>
            <text :x="width - paddingLeft " :y="(isNaN(getYPosition(minValue)) ? height - 25 : getYPosition(minValue) - 5)" class="font-fira text-[12px] font-normal fill-t_blue_gray-300">
                {{ minValue }}
            </text>
        </svg>
    </div>
</template>

<script>
import { ref, computed } from 'vue';

export default {
    props: {
        dataSet1: {
            type: Array,
            default: () => [],
        },
        dataSet2: {
            type: Array,
            default: () => [],
        },
        xLabels: {
            type: Array,
            default: () => [],
        },
        lineColor1: {
            type: String,
            default: 't_blue-600'
        },
        lineColor2: {
            type: String,
            default: 't_blue-600'
        },
    },
    setup(props) {
        const width = (window.innerWidth > 400 ? 400 : window.innerWidth - 50);
        const height = 163;
        const paddingLeft = 20;
        const paddingBottom = 20;

        const maxValue = computed(() => {
            const allData = [...props.dataSet1, ...props.dataSet2];
            return Math.max(...allData);
        });

        const minValue = computed(() => {
            const allData = [...props.dataSet1, ...props.dataSet2];
            return Math.min(...allData);
        });

        const getXPosition = (index) => {
            const totalWidth = width - paddingLeft - 40;
            const stepSize = totalWidth / (props.xLabels.length - 1);
            return paddingLeft + stepSize * index;
        };

        const shouldShowXLabel = (index) => {
            if (index === 0 || index === props.xLabels.length - 1) {
                return true;
            }
            return false;
        };

        const getYPosition = (value) => {
            const totalHeight = height - paddingBottom;
            const valueRange = maxValue.value - minValue.value;
            return totalHeight - ((value - minValue.value) / valueRange) * totalHeight;
        };

        const linePath = (dataSet) => {
            return `M${getXPosition(0)},${(isNaN(getYPosition(dataSet[0])) ? height - 25 : getYPosition(dataSet[0]))}${dataSet
                .map((value, index) => `L${getXPosition(index)},${(isNaN(getYPosition(value)) ? height - 25 : getYPosition(value))}`)
                .join('')}`;
        };

        return {
            width,
            height,
            paddingLeft,
            paddingBottom,
            maxValue,
            minValue,
            getXPosition,
            getYPosition,
            linePath,
            shouldShowXLabel
        };
    },
};
</script>
