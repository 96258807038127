<script setup>
import Primary from "@/UI/Buttons/Primary.vue";
import { reactive, watch } from 'vue'
import Heading_H1 from "@/UI/Text/Heading_H1.vue";

const emit = defineEmits(['update-name']);

const state = reactive({
    name: localStorage.getItem("qr_id")+'-trap',
    error: false,
    lengthError: false
})

watch(state, (newCount, oldCount) => {
    if (newCount.name.length > 25) {
        state.lengthError = true;
    } else {
        state.lengthError = false;
    }
})

function setName() {
    if (state.name.length > 25) {
        state.lengthError = true;
    } else {
            axios.get('/api/get_trapname?trap_name=' + state.name)
            .then(response => {
                if (response.data > 0) {
                    state.error = true;
                } else {
                    state.error = false;
                    state.lengthError = false;
                    emit('update-name', state.name)
                }
            })
    }
}
</script>
<template>
    <div class="flex flex-wrap w-full px-[14px] mt-[32px]">
        <Heading_H1 class="mb-[32px]">
            Name your trap
        </Heading_H1>
        <div class="flex flex-wrap w-full mb-5">
            <label class="w-full mb-2 font-fira text-[18px] text-t_black-800 dark:text-t_white-200">
                Trap name
            </label>
            <input type="text"
            maxlength="25"
                :class="{'border-t_blue_gray-700 dark:border-t_blue_gray-300 ':!state.error, 'border-t_warms-600':state.error}"
                class="
                    border-[3.5px]
                    dark:bg-dark_input_bg w-full
                    px-[12px] py-[8px]
                    dark:text-t_white-200
                    font-fira
                    focus:border-dark_button
                    dark:focus:border-dark_button
                    rounded-[0.225em]
                    shadow-sm"
                    v-model="state.name"
                />
            <span v-if="state.error" class="text-t_warms-600 mt-[8px] font-fira text-[18px]">
                This name is taken
            </span>
            <span v-if="state.lengthError" class="text-t_warms-600 mt-[8px] font-fira text-[18px]">
                    Too many characters (max 25 symbols)
            </span>
        </div>
        <primary @click="setName">
            Save
        </primary>
    </div>
</template>
