<template >
    <BreezeAuthenticatedLayout >
        <div class="flex flex-wrap mt-[15px] mb-[8px] px-[24px]">
            <div @click="back()" class="flex flex-wrap items-center justify-start w-full ">
               <BlueCircle/>
                <span class="font-bold montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">Back - </span>
                <span  class=" montserrat ml-3 uppercase text-t_purple-700 text-[13px] dark:text-white">
                    <template v-if="step === 0 ">
                        {{ trap?.project?.name ?? '' }}
                    </template>
                    <template v-else>
                        {{ trap.name }}
                    </template>
              </span>
            </div>

            <template v-if="step === 0">
                <div class="w-full mt-[16px]">
                <span class="text-[24px] mb-[40px] mfont-bold text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                {{ trap.name }}
                </span>
            </div>
                <div class="mt-[16px] w-full flex flex-wrap gap-y-[12px] text-[18px] font-fira dark:text-t_white-300 text-t_black-800">
                    <div class="w-1/2">
                        Installed
                    </div>
                    <div class="w-1/2 ">
                        {{ formattedDate}}
                    </div>
                    <div class="w-1/2">
                        Created by
                    </div>
                    <div class="w-1/2 ">
                        {{ trap?.owner?.name ?? ''}}
                    </div>
                    <div class="w-1/2" @click="coordinator || trap_creator ? step = 2 : ''">
                        Project
                    </div>
                    <div class="flex flex-wrap justify-between w-1/2 " @click="(coordinator || trap_creator ? step = 2 : '')">
                        {{ trap?.project?.name ?? 'None'}}
                        <svg v-if="coordinator || trap_creator" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75187 18.1485C8.28324 17.6799 8.28324 16.9201 8.75186 16.4515L12.7033 12.5L8.75186 8.54853C8.28323 8.0799 8.28323 7.3201 8.75186 6.85147C9.22049 6.38284 9.98029 6.38284 10.4489 6.85147L15.2489 11.6515C15.7176 12.1201 15.7176 12.8799 15.2489 13.3485L10.4489 18.1485C9.98029 18.6172 9.22049 18.6172 8.75187 18.1485Z" fill="#7A9EB8"/>
                        </svg>
                    </div>
                    <div class="w-1/2"  @click="coordinator || trap_creator ? step = 1 : ''">
                        Trap name
                    </div>
                    <div class="flex flex-wrap justify-between w-1/2 " @click="coordinator || trap_creator ? step = 1 : ''">
                        {{ trap.name }}
                        <svg v-if="coordinator || trap_creator" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75187 18.1485C8.28324 17.6799 8.28324 16.9201 8.75186 16.4515L12.7033 12.5L8.75186 8.54853C8.28323 8.0799 8.28323 7.3201 8.75186 6.85147C9.22049 6.38284 9.98029 6.38284 10.4489 6.85147L15.2489 11.6515C15.7176 12.1201 15.7176 12.8799 15.2489 13.3485L10.4489 18.1485C9.98029 18.6172 9.22049 18.6172 8.75187 18.1485Z" fill="#7A9EB8"/>
                        </svg>
                    </div>
                    <div class="w-1/2">
                        QR ID
                    </div>
                    <div class="w-1/2 ">
                        {{ trap?.qr_id ?? 'Not set'}}
                    </div>
                    <div class="w-1/2">
                        Owner
                    </div>
                    <div class="w-1/2 ">
                        {{ trap?.owner?.name ?? "None"}}
                    </div>

                    <div class="w-1/2">
                        Latitude
                    </div>
                    <div class="w-1/2 ">
                        {{ trap?.coordinates?.coordinates[1] ?? 'Not set'}}
                    </div>
                    <div class="w-1/2">
                        Longitude
                    </div>
                    <div class="w-1/2 ">
                        {{ trap?.coordinates?.coordinates[0] ?? 'Not set' }}
                    </div>
                    <div class="w-1/2">
                        Altitude
                    </div>
                    <div class="w-1/2 ">
                        {{ trap.altitude ?? 'Not set' }}
                    </div>
                </div>

                <div class="w-full mt-[32px] justify-center flex flex-wrap" v-if="coordinator || trap_creator">
                    <div  @click="step = 3" class="w-full">
                        <primary>
                            update trap location
                        </primary>
                    </div>
                </div>
                <span class="w-full mt-[42px] font-fira font-medium text-[16px] text-t_warms-500 underline" @click="visible = !visible" v-if="trap_creator"  >
                    Delete trap
                </span>
            </template>
            <template v-if="step === 1">
                <div class="w-full mt-[16px]">
                <span
                    class="text-[24px] mb-[40px] mfont-bold text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                    Edit Trap
                </span>
            </div>
            <div class="mt-[16px] w-full">
                <ts-input v-model="form.trap_name" label="Trap name" />
                <div class="flex flex-col">
                    <BreezeValidationErrors :errors="form.errors" class="mb-4" />
                </div>
                <primary @click="update" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    save changes
                </primary>
            </div>
            </template>
            <template v-if="step === 2">
                <div class="flex flex-wrap w-full">
                    <Heading_H1 class="my-[32px]">
                        Which Project would you like to add this trap to?
                    </Heading_H1>

                    <panel v-for="(pr, index) in projects" :key="index" @click='updatePr(pr.id)' :text="pr?.name ?? ''" />

                    <panel @click='updatePr(null)' text="None for now">
                        <template #svg>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.9673 2.43188L2.03271 18.3665M2.03271 2.43188L17.9673 18.3665" stroke="#D44944"
                                stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </template>
                    </panel>
                </div>
            </template>
            <template v-if="step == 3">
                <div class="w-full mt-[16px]">
                <span class="text-[24px] mb-[40px] mfont-bold text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                Update trap location
                </span>
            </div>
            <p class="w-full my-[24px] font-fira dark:text-t_white-300 text-t_black-80 text-[18px]">
                Hold your phone against the trap for 5 seconds before pressing the UPDATE LOCATION button for the most accurate location result.
                <br>
                <br>
                Altitude is saved in addition to Latitude and Longitude.
            </p>
            <div class="mt-[16px] w-full">
                <primary  @click="getLocation">
                    UPDATE LOCATION
                </primary>
            </div>
            </template>
        </div>

        <modal-window :showing="visible">
                <h1 class="mb-3 text-xl font-bold montserrat text-bay-of-many-500">Are you sure you want delete? </h1>
                    <div class="flex flex-wrap">
                        <div class="flex w-full mx-auto">
                            <button  @click="delete_trap" class="flex items-center justify-center w-full py-2 mt-2 text-sm font-bold text-white transition duration-150 ease-in rounded-full focus:outline-none sm:text-base bg-valencia">
                                <span class="mr-2 uppercase">yes</span>
                            </button>
                        </div>
                        <div class="flex w-full mx-auto">
                            <button  @click="visible = false" class="flex items-center justify-center w-full py-2 mt-2 text-sm font-bold transition duration-150 ease-in border-2 rounded-full focus:outline-none text-t_purple-700 dark:text-t_purple-700 dark:border-t_purple-700 sm:text-base border-t_purple-700">
                                <span class="mr-2 uppercase">No</span>
                            </button>
                        </div>
                    </div>
            </modal-window>

    </BreezeAuthenticatedLayout>
</template>

<script>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import {Link, useForm, router } from '@inertiajs/vue3';
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import QrCode_Historie from "@/Components/SVG/QrCode_Historie.vue";
import Primary from "@/UI/Buttons/Primary.vue";
import BlueCircle from "@/Components/SVG/BlueCircle.vue";
import ModalWindow from "@/Components/ModalWindow.vue";
import { Inertia } from "@inertiajs/inertia";
import TsInput from "@/Components/Input.vue";
import BreezeValidationErrors from '@/Components/ValidationErrors.vue';
import moment from 'moment';
import Panel from "@/Components/Panel.vue"

export default {
    name: "UserTrapView",
    props: ['trap','coordinator', 'trap_creator', 'projects'],
    components:{
        BreezeAuthenticatedLayout,Link,Heading_H1,QrCode_Historie,Primary,BlueCircle, ModalWindow, TsInput,BreezeValidationErrors, Panel
    },
    data() {
        return {
            visible: false,
            step: 0,
            formattedDate: '',
            form: useForm({
                trap_name: this.trap.name,
                project_id: this.trap.project_id,
                latitude: '',
                longitude: '',
                altitude: '',
                update_location:false
            })
        }
    },
    mounted() {
    // Parse the original date string using moment
    const parsedDate = moment(this.trap.created_at);

    // Format the date according to your desired format
    this.formattedDate = parsedDate.format('DD MMM YYYY');
  },
    methods: {
        delete_trap() {
            Inertia.delete(route('user.settings.trap.delete', this.trap.id))
            this.visible = false;
        },
        back(){
            if(this.step == 0){
                router.visit(route('user.projects.view_traps', localStorage.getItem('pr_id_back')));
            }else{
                this.step = 0;
            }
        },
        update(){
            this.form.put(route('user.projects.view_trap.update', this.trap), {
                preserveScroll: true,
                onSuccess: () => this.step = 0,
            })
        },
        updatePr(data){
            this.form.project_id = data;
            this.update();
        },
        getLocation() {
            let app = this
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        app.form.latitude = position.coords.latitude
                        app.form.longitude = position.coords.longitude
                        app.form.altitude = position.coords.altitude
                        app.form.update_location = true
                        app.update()
                    },
                    error => {
                        console.log(error.message)
                    }
                )
            } else {
                console.log("Geolocation is not supported by this browser.")
            }
        }

    }
}
</script>

<style scoped>

</style>
