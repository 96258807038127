<script >
import BreezeGuestLayout from '@/Layouts/Guest.vue';
import { Link } from '@inertiajs/vue3'
import AnonScanHead from "@/Components/SVG/AnonScanHead.vue"
import TitleText from '@/UI/Text/TitleText.vue';
import Predator from "@/Components/SVG/Sponsors/2050.vue"
import Apvcc from "@/Components/SVG/Sponsors/APVCC.vue"
import Clare from "@/Components/SVG/Sponsors/Clare.vue"
import Primary from '@/UI/Buttons/Primary.vue';

export default {
    components:{
        Link,
        BreezeGuestLayout,
        AnonScanHead,
        TitleText,
        Clare,
        Predator,
        Apvcc,
        Primary
    },
    name: "AnonIndex",
    props:{
        qr_id: String,
        trap: Boolean
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<template>
    <BreezeGuestLayout>
            <div class="flex flex-wrap px-4 overflow-hidden">
                <div class="w-full overflow-hidden">
                    <div class="flex justify-center mb-[24px]">
                        <anon-scan-head/>
                    </div>
                    <div class="w-full">
                        <title-text color="text-t_black-800 dark:text-t_white">Record trap checks.</title-text>
                        <p class="mt-[8px] dark:text-t_green-600 text-t_green-800 font-fira font-medium text-[20px]">
                            Contribute to the Predator Free 2050 mission.
                        </p>
                        <p class="mt-[8px] dark:text-t_blue_gray-300 text-t_blue_gray-800 font-fira font-normal text-[13px]">
                            TrapScan is a simple and user-friendly QR scanning app for trappers. Quickly and easily collect data from anyone who wants to help, manage trapping projects, get real time insights and connect with Aotearoa’s vibrant trapping community.
                        </p>
                        <div class="w-full flex flex-wrap  py-[32px] px-[8px] gap-[10px] text-white">
                            <div class="w-full" >
                                <Link v-if="qr_id !== null" :href="route('inspection.index', [qr_id, 1])" class="flex w-full h-[50px] items-center justify-center bg-t_green-800 rounded-[40px]">
                                    <span class="font-bold uppercase montserrat ">Record a trap check</span>
                                </Link>
                                <Link v-else :href="route('scan')" class="flex w-full h-[50px] items-center justify-center bg-t_green-800 rounded-[40px]">
                                    <span class="font-bold uppercase montserrat ">Record a trap check</span>
                                </Link>
                            </div>
                            <div class="w-full flex flex-row gap-[10px]">
                                <a href="https://trapscan.notion.site/TrapScan-269167c91d3a48cb8fcd69271fe2d67b" class="flex w-1/2 h-[50px] items-center justify-center bg-t_blue_gray-700 dark:bg-t_blue_gray-800 rounded-[40px]">
                                    <span class="font-bold uppercase montserrat ">About</span>
                                </a>
                                <Link :href="route('login')" class="flex w-1/2 h-[50px] items-center justify-center bg-t_blue_gray-700 dark:bg-t_blue_gray-800 rounded-[40px]">
                                    <span class="font-bold uppercase montserrat ">log in</span>
                                </Link>
                            </div>

                        </div>
                        <p class="w-full font-fira text-[12px] pt-[24px] font-normal dark:text-t_blue_gray-300 text-t_blue_gray-800">Supported by</p>
                        <div class="w-full grid grid-cols-2 gap-[10px] gap-y-[20px ] py-[24px] px-[30px]">
                            <Predator />
                            <Apvcc />
                            <Clare />
                        </div>

                    </div>


                </div>
            </div>

    </BreezeGuestLayout>
</template>
