<template>
    <svg class="dark:text-gray-300 mt-1" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.3166 4.21806C11.3199 8.22064 2.49006 16.428 3.00984 16.3966C3.00984 16.3966 3.76318 16.4205 5.22029 18.4412C5.72551 19.1418 8.67665 19.6846 9.34557 18.739C9.67143 18.2784 11.8677 15.8612 12.4329 15.2584C14.9913 12.5304 17.1301 9.39572 19.8067 6.77893C20.2564 6.33923 21.5921 5.57401 20.5803 4.98891C20.1624 4.74725 18.8863 4.10578 18.4355 4.02953C17.6631 3.89892 17.6024 3.84363 16.8433 3.58109C15.8331 3.23175 16.1152 3.41828 15.3166 4.21806Z" fill="#FFC700"/>
        <path d="M12.4329 15.2584C14.9913 12.5304 17.1301 9.39572 19.8067 6.77893C20.2564 6.33923 21.5921 5.57401 20.5803 4.98891C20.1624 4.74725 18.8863 4.10578 18.4355 4.02953C17.6631 3.89892 17.6024 3.84363 16.8433 3.58109C15.8331 3.23175 16.1152 3.41828 15.3166 4.21806C11.3199 8.22064 2.49006 16.428 3.00984 16.3966C3.00984 16.3966 3.76318 16.4205 5.22029 18.4412C5.72551 19.1418 8.67665 19.6846 9.34557 18.739C9.67143 18.2784 11.8677 15.8612 12.4329 15.2584ZM12.4329 15.2584C11.7732 15.9619 13.1758 14.6543 12.4329 15.2584Z" stroke="#5C5C5C" stroke-width="1.09343" stroke-linecap="round"/>
        <path d="M2.05268 20.0563C2.44222 19.0128 2.55589 17.6852 2.8791 16.6131C2.85804 16.383 5.01088 17.3406 6.00932 17.0261C6.51777 16.866 6.46615 16.7504 6.57295 17.4858C6.6781 18.2098 7.01747 18.8576 7.66695 19.1087C8.39077 19.3252 8.66106 19.153 8.71552 19.1047C8.73084 19.0911 8.72907 19.0873 8.71552 19.1047C8.71456 19.1059 8.71355 19.1073 8.71247 19.1087C8.5525 19.3241 7.84126 19.7716 7.61989 19.8919C6.53545 20.4811 5.49072 21.1523 4.37801 21.6763C3.91578 21.9069 1.00037 23.4043 1.00037 23.4043L2.05268 20.0563Z" fill="#FFF6D8"/>
        <path d="M4.37801 21.6763C3.91578 21.9069 1.00037 23.4043 1.00037 23.4043L2.05268 20.0563C2.44222 19.0128 2.55589 17.6852 2.8791 16.6131C2.85804 16.383 5.01088 17.3406 6.00932 17.0261C6.51777 16.866 6.46615 16.7504 6.57295 17.4858C6.6781 18.2098 7.01747 18.8576 7.66695 19.1087C8.60876 19.3904 8.78274 19.0141 8.71247 19.1087C8.5525 19.3241 7.84126 19.7716 7.61989 19.8919C6.53545 20.4811 5.49072 21.1523 4.37801 21.6763ZM4.37801 21.6763C5.0199 21.356 3.72956 21.9816 4.37801 21.6763Z" stroke="#5C5C5C" stroke-width="1.09343" stroke-linecap="round"/>
        <path d="M20.1807 1.55614C19.5408 1.32686 18.9053 1.0476 18.2209 1.04273C17.8955 1.39956 17.007 2.11194 16.7241 2.50161C16.4417 2.89053 16.0329 3.11923 15.8044 3.54672C15.7325 3.68124 15.3877 3.95449 15.3813 4.08365C15.3786 4.13798 15.8774 4.13988 15.9353 4.15753C16.2653 4.25822 16.614 4.28821 16.9471 4.37879C17.2083 4.44982 17.4442 4.61638 17.6932 4.72508C17.9738 4.84755 18.2573 4.97509 18.5239 5.12949C18.9656 5.38527 19.3096 5.81922 19.7029 6.14709C19.8017 6.22947 19.9066 6.27686 20.0084 6.34811C20.0774 6.39638 20.0983 6.47241 20.1906 6.48914C20.3008 6.5091 20.42 6.30918 20.481 6.2437C20.6401 6.07273 20.7932 5.88555 20.9711 5.73558C21.2472 5.50274 21.5944 5.33176 21.8428 5.06499C22.0781 4.81226 22.1604 4.44069 22.3678 4.16206C22.4888 3.99938 22.6544 3.91811 22.5481 3.67287C22.5027 3.56823 22.4138 3.4976 22.3346 3.42333C22.1234 3.22504 21.9659 2.96394 21.7811 2.73961C21.3843 2.2581 20.7592 1.76346 20.1807 1.55614Z" fill="#E86969" stroke="#5C5C5C" stroke-width="1.09343" stroke-linecap="round"/>
        <path d="M2.05151 20.4206C2.28182 20.424 2.50357 20.4824 2.72718 20.5318C2.89618 20.5691 3.06371 20.603 3.22626 20.6643C3.43689 20.7439 3.63832 20.8352 3.81803 20.9722C3.95123 21.0737 4.08377 21.1711 4.22335 21.2639C4.24099 21.2756 4.35814 21.3338 4.35363 21.3693C4.34753 21.4172 4.17547 21.4932 4.15138 21.5069C3.97179 21.6096 3.78855 21.7055 3.6034 21.7977C3.46719 21.8656 3.32781 21.941 3.17964 21.9807C2.97346 22.036 2.7577 22.0526 2.56512 22.1517C2.35628 22.2591 2.1648 22.3967 1.95288 22.4988C1.86496 22.5412 1.8501 22.5183 1.88856 22.4282C1.95722 22.2673 2.07267 22.1206 2.154 21.9652C2.24099 21.799 2.30852 21.6259 2.35821 21.445C2.3928 21.3192 2.45275 21.2055 2.49134 21.082C2.5177 20.9976 2.5099 20.8667 2.57465 20.8003C2.61525 20.7586 2.87418 21.0635 2.90792 21.0948C3.03042 21.2084 3.18999 21.3227 3.26838 21.4744C3.31945 21.5732 3.32161 21.5815 3.21716 21.5953" stroke="#5C5C5C" stroke-width="1.09343" stroke-linecap="round"/>
    </svg>

</template>
