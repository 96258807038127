<template>
    <Show :type="3">
        <template #header>
            <div @click="back" class="flex items-center w-auto h-full gap-x-1">
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M7.39316 12.0653C7.86179 11.5966 7.86179 10.8368 7.39316 10.3682L3.44168 6.41675L7.39316 2.46528C7.86179 1.99665 7.86179 1.23685 7.39316 0.76822C6.92453 0.299591 6.16473 0.299591 5.6961 0.76822L0.896099 5.56822C0.427469 6.03685 0.427469 6.79665 0.896099 7.26528L5.6961 12.0653C6.16473 12.5339 6.92453 12.5339 7.39316 12.0653Z"
                          fill="white" />
                </svg>
                <h1 class="text-white font-bold ml-1 text-[16px] montserrat  tracking-[.05em]"> Back - {{trap.name}}</h1>
            </div>
            <div >

            </div>
            <div></div>
        </template>
        <div class="w-full px-[14px] mt-[32px]" v-if="step === 3">
            <Heading_H1 class="mb-[32px]">
                How would you like to set the Trap’s location?
            </Heading_H1>
            <panel @click='nStep(0)' text="Use my phone’s current location">
                <template #svg>
                    <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="dark:fill-t_white-400" d="M14.8086 9.22727C11.8954 9.22727 9.53587 11.5868 9.53587 14.5C9.53587 17.4132 11.8954 19.7727 14.8086 19.7727C17.7218 19.7727 20.0813 17.4132 20.0813 14.5C20.0813 11.5868 17.7218 9.22727 14.8086 9.22727ZM26.5931 13.1818C25.9868 7.685 21.6236 3.32182 16.1268 2.71545V0H13.4904V2.71545C7.99359 3.32182 3.63041 7.685 3.02405 13.1818H0.308594V15.8182H3.02405C3.63041 21.315 7.99359 25.6782 13.4904 26.2845V29H16.1268V26.2845C21.6236 25.6782 25.9868 21.315 26.5931 15.8182H29.3086V13.1818H26.5931ZM14.8086 23.7273C9.70723 23.7273 5.58132 19.6014 5.58132 14.5C5.58132 9.39864 9.70723 5.27273 14.8086 5.27273C19.91 5.27273 24.0359 9.39864 24.0359 14.5C24.0359 19.6014 19.91 23.7273 14.8086 23.7273Z" fill="black"/>
                    </svg>
                </template>
            </panel>
            <panel @click='nStep(1)' text="Choose location on map">
                <template #svg>
                    <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.05859 0C4.22109 0 0.308594 3.9125 0.308594 8.75C0.308594 10.925 0.933594 12.9625 2.07109 14.8C3.25859 16.725 4.82109 18.375 6.02109 20.3C6.60859 21.2375 7.03359 22.1125 7.48359 23.125C7.80859 23.8125 8.07109 25 9.05859 25C10.0461 25 10.3086 23.8125 10.6211 23.125C11.0836 22.1125 11.4961 21.2375 12.0836 20.3C13.2836 18.3875 14.8461 16.7375 16.0336 14.8C17.1836 12.9625 17.8086 10.925 17.8086 8.75C17.8086 3.9125 13.8961 0 9.05859 0ZM9.05859 12.1875C7.33359 12.1875 5.93359 10.7875 5.93359 9.0625C5.93359 7.3375 7.33359 5.9375 9.05859 5.9375C10.7836 5.9375 12.1836 7.3375 12.1836 9.0625C12.1836 10.7875 10.7836 12.1875 9.05859 12.1875Z" fill="#D44944"/>
                    </svg>
                </template>
            </panel>
        </div>
        <div class="w-full" v-else>
            <component :is="steps[currentTab]?.component" v-bind="steps[currentTab]?.props"  />
        </div>
    </Show>


</template>

<script setup>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue';
import { Link, useForm } from '@inertiajs/vue3'
import BlueCircle from "@/Components/SVG/BlueCircle.vue";
import Panel from "@/Components/Panel.vue";
import Heading_H1 from "@/UI/Text/Heading_H1.vue";
import {ref} from 'vue'
import SelectLocationPhone from "@/Components/EditTrap/SelectLocationPhone.vue";
import SelectLocationMap from "@/Components/EditTrap/SelectLocationMap.vue";
import Show from "@/Layouts/Show.vue";
import { router } from '@inertiajs/vue3'


const prop = defineProps({
    trap:Object
})

const back = () => {
    if (step.value === 3){
        router.get(route('user.settings.trap.view',prop.trap.id))
    }else {
        step.value = 3;
    }
}
console.log(prop.trap?.coordinates?.coordinates[1])
const form = useForm({
    latitude: '',
    longitude: '',
    altitude: '',
});
const updateLocation = (updateLocation) => {
    form.latitude = updateLocation.lat;
    form.longitude = updateLocation.lon;
    form.altitude = updateLocation.altitude;
    submit()
};

const step = ref(3);
const currentTab = ref(null)
const steps = [
    { component: SelectLocationPhone, props: { onUpdateLocation: updateLocation } },
    { component: SelectLocationMap, props: { onUpdateLocation: updateLocation, lat:prop.trap?.coordinates?.coordinates[1], lon:prop.trap?.coordinates?.coordinates[0] } },
];

const nStep = (option) => {
    currentTab.value = option;
    step.value = option
};

function submit(){
    form.put(route('user.settings.trap.update_location', prop.trap.id))
}

</script>
<style scoped>

</style>
