<template>
    <Show>
        <template #header>
            <Link :href="route('inspection.index',trap.qr_id)" class="w-2/12 h-full flex items-center ">
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.39316 12.0653C7.86179 11.5966 7.86179 10.8368 7.39316 10.3682L3.44168 6.41675L7.39316 2.46528C7.86179 1.99665 7.86179 1.23685 7.39316 0.76822C6.92453 0.299591 6.16473 0.299591 5.6961 0.76822L0.896099 5.56822C0.427469 6.03685 0.427469 6.79665 0.896099 7.26528L5.6961 12.0653C6.16473 12.5339 6.92453 12.5339 7.39316 12.0653Z" fill="white"/>
                </svg>
            </Link>
            <div class="w-8/12 h-full flex justify-center items-center">
                <h1 class="text-white font-bold ml-1 text-[16px] montserrat capitalize tracking-wide tracking-[.05em]">{{trap.qr_id}}</h1>
            </div>
            <div @click="back" class="w-2/12 h-full flex justify-end items-center">
                <Close/>
            </div>
        </template>
        <template #last>
            <div class="bg-t_warms-300 w-full flex justify-center items-end py-[6px]">
                <h6 class="italic text-t_black-800  montserrat text-[12px] " >
                    Currently linked to trap <span class="font-bold montserrat capitalize">{{trap.name}}</span>
                </h6>
            </div>
        </template>
        <div class="px-[24px] w-full flex flex-wrap">
            <div class="w-full">
                <span class="w-full text-[24px] font-bold text-t_black-800 dark:text-t_white-200 font-fira font-semibold">
                    Are you sure you want to uninstall this QR?
                </span>
                <div class="flex flex-wrap overflow-hidden  mt-[24px] ">
                    <Link :href="route('remove_qr_confirmed',trap.id)" class="w-full">
                    <panel  text="Yes" small_text="Remove QR from trap">
                        <template #svg>
                            <good/>
                        </template>
                    </panel>
                    </Link>

                    <Link :href="route('inspection.index',trap.qr_id)" class="w-full">
                        <panel text="No" small_text="Go back">
                            <template #svg>
                                <no/>
                            </template>
                        </panel>
                    </Link>
                </div>
            </div>

        </div>
    </Show>
</template>

<script>
import Show from "@/Layouts/Show.vue";
import BreezeGuestLayout from '@/Layouts/Guest.vue';
import {Link} from '@inertiajs/vue3'
import SelectComponent from '@/Components/SelectComponent.vue'
import Close from '@/Components/SVG/Close.vue'

import Panel from "@/Components/Panel.vue"
import No from  "@/Components/SVG/No.vue";
import Good from "@/Components/SVG/Good.vue";
export default {
    components:{
        Link,
        BreezeGuestLayout,
        Show,
        SelectComponent,
        Panel,
        No,
        Good,
        Close
    },
    name: "RemoveQR",
    props: {
        trap: Object,
    },
    data() {
        return {
        }
    },
    mounted() {

    },
    watch: {

    },
    methods: {
        back(){
            this.$inertia.visit(this.route('scan'));
        }
    }
}
</script>

<style scoped>

</style>
