<template>
    <div class="flex flex-wrap overflow-hidden mx-[24px]">
        <panel  @click='selected(0)' text="It was empty">
            <template #svg>
                <empty-svg/>
            </template>
        </panel>
        <panel @click='selected(1)' text="Something was in it">
            <template #svg>
                <in-it/>
            </template>
        </panel>
        <panel  @click='selected(2)' text="Something's wrong">
            <template #svg>
               <wrong/>
            </template>
        </panel>
        <panel  v-if="coordinator || $page.props.admin || trap?.user_id === $page.props.auth.user?.id" @click='remap()' text="Uninstall QR" small_text="Remove the trap linked to this QR ID">
            <template #svg>
                <svg width="44" height="24" viewBox="0 0 44 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4.5" y="1" width="15" height="22" rx="0.819393" fill="white" stroke="#7A9EB8" stroke-width="1.5"/>
                    <path d="M11.8939 6.18623V6.76799M15.3845 13.1674H16.548M13.0574 13.1674H11.8939V15.4945M11.8939 9.09505V10.8403M11.8939 10.8403H11.8997M11.8939 10.8403H14.2267M14.2209 15.4945H16.548M7.23975 10.8403H9.56681M16.548 10.8403H16.5538M14.8027 8.51329H16.548V6.1861L14.2267 6.18623C14.2209 6.18616 14.2267 6.18587 14.2209 6.18616V7.93152C14.2209 8.25282 14.4814 8.51329 14.8027 8.51329ZM8.9853 8.51342H7.24V6.18623L9.56124 6.18636C9.56706 6.18629 9.56124 6.186 9.56706 6.18629V7.93165C9.56706 8.25295 9.3066 8.51342 8.9853 8.51342ZM8.99111 13.1672H7.24582V15.4944L9.56706 15.4943C9.57288 15.4943 9.56706 15.4946 9.57288 15.4943V13.749C9.57288 13.4277 9.31241 13.1672 8.99111 13.1672Z" stroke="#1A2B3D" stroke-width="1.16426" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.4931 6.29289C25.8836 5.90237 26.5168 5.90237 26.9073 6.29289L31.2002 10.5858L35.4931 6.29289C35.8836 5.90237 36.5168 5.90237 36.9073 6.29289C37.2978 6.68342 37.2978 7.31658 36.9073 7.70711L32.6144 12L36.9073 16.2929C37.2978 16.6834 37.2978 17.3166 36.9073 17.7071C36.5168 18.0976 35.8836 18.0976 35.4931 17.7071L31.2002 13.4142L26.9073 17.7071C26.5168 18.0976 25.8836 18.0976 25.4931 17.7071C25.1026 17.3166 25.1026 16.6834 25.4931 16.2929L29.786 12L25.4931 7.70711C25.1026 7.31658 25.1026 6.68342 25.4931 6.29289Z" fill="#D44944"/>
                </svg>

            </template>
        </panel>

    </div>

</template>

<script>
import EmptySvg from "@/Components/SVG/EmptySvg.vue";
import InIt from "@/Components/SVG/InIt.vue";
import Wrong from "@/Components/SVG/Wrong.vue";
import Remap from "@/Components/SVG/Remap.vue";
import Panel from "@/Components/Panel.vue"

export default {
    props:{
        qrs:Object,
        coordinator:Boolean,
        trap:Object
    },
    name: "StepOne",
    components:{
        Panel,
        Wrong,
        InIt,
        EmptySvg,
        Remap,
    },
    data() {
        return {
            dialogVisible: false,
            val:null,
            values:[
                {strikes:0,species_caught:"None",trap_condition: "Ok",words: "The trap caught nothing, ", step:2},
                {
                    strikes: 0,
                    status: 'Sprung',
                    trap_condition: 'Ok',
                    words: 'The trap caught a ',
                    step:3
                },
                {
                    strikes: 0,
                    species_caught: 'None',
                    rebaited: 'No',
                    bait_type: 'None',
                    words: "Something is wrong with it…",
                    step:4
                }
            ]
        }
    },
    methods:{
        selectedData(value) {
            this.val = value.id;
        },
        selected (val){
            this.$emit('selected',this.values[val])
        },
        remap(){
            this.$inertia.get(route('remove_qr',this.trap.id))
        }
    }
}
</script>

<style scoped>

</style>
