<template>
    <span :class="`
    montserrat
    uppercase
    font-semibold
    tracking-[10.5%]
    text-[12px]
    leading-[120%] `+ color">
       <slot/>
    </span>
</template>

<script>
export default {
    props:{
        color:{
            type:String,
            default:"text-t_blue_gray-500"
        }
    },
    name: "Tag"
}
</script>

<style scoped>

</style>
